import { GET_NEWS_SUCCESS } from '../actions/NewsUpdates';

const initialState = {
    news_updates : [],
}

const News_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_NEWS_SUCCESS :
            return Object.assign({}, state, { news_updates : payload.news_updates });

        default :  
            return state;
    }
}

export default News_reducer;