import React from 'react';
import './Footer.css';
import Logo from '../../assets/images/icons/logo.png';
import { HashRouter as Router, NavLink } from 'react-router-dom';

const Footer = () => {
	return (
		<div className="row footer_container">
			<div className="col text-center">
				<div className="d-flex justify-space-between row">
					<div className="classic ml-3 text-center">
						<h6 className="heading"> KARNATAKA CLASSIC EDUCATION Pvt Ltd </h6>
						<p className="address">Classic Campus, Sharada School Road,<br/>
							Saptapur, Dharwad - 580001 <br/>
						</p>
					</div>

					<div className="">
                        <div>
                            <h5 className="heading"> FOLLOW US</h5>
                            <ul className="social text-center">
                            <li> <a target="_blank" rel="noopener noreferrer" title="Facebook" href="https://www.facebook.com/classicedudwd"><i className="social_icon fa fa-facebook"></i></a> </li>
                            {/* <li> <a target="_blank" rel="noopener noreferrer" title="LinkedIn" href=""><i className="social_icon fa fa-linkedin"></i></a> </li> */}
                            <li> <a target="_blank" rel="noopener noreferrer" title="Instagram" href="https://www.instagram.com/classiceducationlive/"><i className="social_icon fa fa-instagram"></i></a> </li>
                            <li> <a target="_blank" rel="noopener noreferrer" title="Youtube" href="https://www.youtube.com/c/ClassicEducationDharwad/featured"><i className="social_icon fa fa-youtube"></i></a> </li>
                            <li> <a target="_blank" rel="noopener noreferrer" title="Telegram" href="https://t.me/classiceducationlive"><i className="social_icon fa fa-telegram"></i></a> </li>
                            </ul>
                        </div>
                    </div>

					<div className="connect_with text-center">
						<div className="heading">Contact Us</div>
						<div><h6 className="address m-0"> classicappdwd@gmail.com </h6></div>
						<div className="row d-flex justify-space-around contactNumber">
							<div>
								{/* <div> Number</div> */}
								<div className="address m-0">+91 9980552080, +91 9980552081</div>
							</div>
						</div>
					</div>

				</div>

				<p className="copyright" id="dmVlcmVzaGtvdHJhdnZhbGE="> copyright &copy; <span> Karnataka Classic Educations Pvt Ltd. </span> @ 2020 </p>
			</div>
		</div>
	)
}

export default Footer;