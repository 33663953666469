import axios from 'axios';

export const BaseUrl = 'https://api.classicedu.org/public/';
export const BooksUrl = BaseUrl + 'book_files/';

export const ApiPaths = {
    LOGIN: 'Login',
    REGISTER: 'Register',
    USER_EXISTS: 'UserExists',
    FORGOT_PASSWORD: 'ForgotPasswordOTP',
    RESET_PASSWORD: 'ResetPassword',
    GET_HOME: "GetWebHome",
    GET_SCROLLING_TEXT : 'GetScrollingNews',
    GET_IMAGES: 'GetImages',
    GET_COURSES: 'GetCourses/',
    GET_BATCHES: 'GetBatches/',
    GET_ONLINE_CLASSES: 'GetOnlineClasses/',
    GET_NOTIFICATIONS: 'GetNotifications',
    GET_BOOKS: 'GetBooks',
    GET_ACHIEVERS: 'GetAchievers',
    GET_NEWS: 'GetNews',
    GET_TESTS: 'GetTests',
    GET_WRITTEN_EXAM: 'getMyWrittenExam',
    GET_QUESTIONS: 'GetQuestions/',
    SUBMIT_RESULT: 'SubmitTestResult',
    GET_TEST_RESULT: 'GetResult/',
    UPDATE_PROFILE: 'ProfileUpdate',
    UPLOAD_PROFILE_PIC: 'UploadProfilePic',
    CHANGE_PASSWORD: 'UpdatePassword',
    GET_ORDERS: 'GetUserOrders',
    GET_CART_DETAILS: 'GetUserCartDetails',
    GET_CART_STATUS: 'GetCartStatus',
    ADD_TO_CART: 'updateToCart',
    REMOVE_FROM_CART: 'RemoveItemFromCart',
    SAVE_CHECKOUT: 'SaveCheckout',
    PAYMENT_SUCCESS: 'PaymentSuccess',
    DELIVERY_ADDRESS: 'getDeliveryAddress',
    ADD_ADDRESS : 'addDeliveryAddress',
    UPDATE_ADDRESS : 'updateDeliveryAddress',
    SEND_ENQUIRY_EMAIL : 'sendEnquiryEmail',
    GET_ALL_IMAGES : 'getGalleryImage',
    WRITTEN_EXAM_SUBMISSION : 'addWrittenExamSubmission'
};

export const ApiService = axios.create({
    baseURL : BaseUrl + 'api/',
    headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/x-www-form-urlencoded',
    }
});

export const ApiService2 = axios.create({
    baseURL : BaseUrl + 'api/',
    headers : {}
});

export const SetAuthToken = (token) => {
    if(token)
    {
        ApiService.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        ApiService2.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    else
    {
        delete ApiService.defaults.headers.common['Authorization'];
        delete ApiService2.defaults.headers.common['Authorization'];
    }
}

export const ApiStatus = {
    OTP_SENT: 'OTP sent',
}