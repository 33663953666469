import React from 'react';
import './Routes.css';
import { NavLink, Link, HashRouter as Router, Route, withRouter } from 'react-router-dom';
import Home from '../Home';
import AboutUs from '../AboutUs';
import Register from '../Register';
import Contact from '../Contact';
import TermsConditions from '../TermsConditions';
import PrivacyPolicy from '../PrivacyPolicy';
import ReturnPolicy from '../ReturnPolicy';

import EditProfile from '../EditProfile';
import ChangePassword from '../EditProfile/ChangePassword';
import ForgotPassword from '../EditProfile/ForgotPassword';
import NewsUpdates from '../NewsUpdates/NewsUpdates';
import NewsDetails from '../NewsUpdates/NewsDetails';
import Achievers from '../Achievers/Achievers';
import Courses from '../Courses/Courses';
import {Batches, BatchDetails} from '../Batches';
import Books from '../Books/';
import BookCart from '../BookCart';
import OnlineClasses from '../OnlineClasses/OnlineClasses';
import {compose} from "redux"; 
import { connect } from 'react-redux';
import Logo from '../../assets/images/icons/logo.png';
import VideoPlayer from '../Common/VideoPlayer';
import PDFReader from '../Common/PDFReader';
import Examination from '../Examination/Examination';
import Examination1 from '../Examination/Examination1';
import Result from '../Examination/Result';
import ExportExample from '../PDF';
import Cart from '../Cart'
import MyOrder from '../MyOrder'
import ImageCollection from '../ImageCollection'
import $ from 'jquery';
import { userLogout } from '../../actions/Home';


class Routes extends React.Component {

	constructor(props) {
		super(props);
		this.state = { show_courses: 0, show_states: 0 };
		this.handleStateNavigate = this.handleStateNavigate.bind(this);
	}

	componentDidMount() {
		// Show side login screen
		$('.show_profile').on('click', function () {
			$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
		});

	}

	handleStateNavigate = (state_id) => {
		window.location.href = '/#/college_filters?c_state=' + state_id;
	}

	handleLogOut = () => {
		this.props.onClickLogOut();
	}

	render() {

		const { is_auth, auth_user, cart_count } = this.props;
		const path = this.props.location.hash;
		let showHeaderFooter = true;
		if(['/about-us-sm','/contact-sm'].includes(path.substring(1))){
			showHeaderFooter = false;
		}
		
		return (
			<Router>
				{showHeaderFooter && 
				<div className="p-0 nav-sticky">
					{/* <div className="row mb-1">
						<div className="col" />
						<div className="col-md-3 col-12 pt-1">
							<a href="#/about-us" className="small_text text-dark"> About Us </a>
							<span> | </span>
							<a href="#/contact" className="small_text text-dark"> Contact Us </a>
						</div>
					</div>
					<hr className="m-0" /> */}

					<div className="row py-2 " style={{marginLeft : "0px", marginRight : "0px"}}>
						<div className="col d-flex align-items-end">
							<Link to="/" className="navbar-brand">
								<img src={Logo} alt="Logo" height="40" className="float-left mr-2" />
								<div className="ml-2">
									<p className="text-green mb-0" style={{ lineHeight: 1 }}><b>CLASSIC KAS & IAS STUDY CIRCLE</b></p>
									<span className="small_text text-green">Karnataka Classic Education Pvt Ltd</span>
								</div>
							</Link>
						</div>
						<div className="col-sm-6 col-12 text-right my-auto">
							<div>
								<div className="d-flex justify-content-between">
									<a href="#/about-us" className="small_text text-dark"> About Us </a>
									<span> | </span>
									<a href="#/contact" className="small_text text-dark"> Contact Us </a>
									<span> | </span>
									<a href="#/privacy-policy" className="small_text text-dark"> Privacy Policy</a>
									<span> | </span>

									<a href="#/terms-conditions" className="small_text text-dark"> Terms & Condition</a>
									<span> | </span>

									<a href="#/return-policy" className="small_text text-dark"> Return Policy</a>
									<span> | </span>

								
									{is_auth && auth_user ?  <a onClick={this.handleLogOut} className="small_text text-dark"> Logout </a>
									:
									<a className="small_text text-dark show_profile" > Register </a>
									}
								</div>
								<div>
								{is_auth && auth_user && <Link to="/Cart" className="navbar-brand">
									{/* <button className="abroad_btn no_shadow show_profile"><i className="fa fa-shopping-cart mr-1"></i> {cart_count}</button> */}
									{<button className="abroad_btn no_shadow "><i className="fa fa-shopping-cart mr-1"></i> <super className="super">{parseInt(cart_count) ? parseInt(cart_count) : 0 }</super></button>}
									</Link>
								}
									<div className="navbar-brand"> 
									{is_auth && auth_user ?
										<button className="abroad_btn no_shadow show_profile ml-2"> <i className="fa fa-user mr-1"></i> {''} </button>
										: <button className="abroad_btn no_shadow show_login ml-2"> <i className="fa fa-sign-in mr-1"></i> {''}</button>
									}
									</div>
								</div>
								
							</div>

						</div>
					</div>
					<hr className="m-0" />

					

					<div className="row p-0" style={{ backgroundColor: '#004053', marginRight : '0px !important' }} id="main_header_navbar">
						<div className="col text-center">
							<NavLink className="nav-link" activeClassName="link_active" exact  to={{pathname:"/courses/1/online",courseData:{typeName: "online", courseType : "1" }}}> Online Courses </NavLink>
						</div>
						<div className="col text-center">
							<NavLink className="nav-link" activeClassName="link_active" exact to={{pathname:"/courses/0/offline",courseData:{typeName: "offline", courseType : "0" }}} > Offline Courses </NavLink>
						</div>
						<div className="col text-center">
							<NavLink className="nav-link" activeClassName="link_active" exact to="/Examination"> Exams </NavLink>
						</div>
						<div className="col text-center">
							<NavLink className="nav-link" activeClassName="link_active" exact to="/books"> Books </NavLink>
						</div>
						<div className="col text-center">
							<NavLink className="nav-link" activeClassName="link_active" exact to="/news-updates"> News </NavLink>
						</div>
						<div className="col text-center">
							<NavLink className="nav-link" activeClassName="link_active" exact to="/achievers"> Achievers </NavLink>
						</div>
						<div className="col text-center">
							<NavLink className="nav-link" activeClassName="link_active" exact to="/ImageCollection"> Gallery </NavLink>
						</div>
					</div>
				</div>}

				{/* Routes */}
				<div className='px-2'>
					<Route exact path="/" component={Home} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/about-us" component={AboutUs} />
					<Route exact path="/about-us-sm" component={AboutUs} />
					<Route exact path="/contact" component={Contact} />
					<Route exact path="/contact-sm" component={Contact} />
					<Route exact path="/news-updates" component={NewsUpdates} />
					<Route exact path="/news-details" component={NewsDetails} />
					<Route exact path="/achievers" component={Achievers} />
					<Route exact path="/courses/:type/:typeName" component={Courses} />
					<Route exact path="/courses/:courseID/batches/:type/:typeName" component={Batches} />
					<Route exact path="/books" component={Books} />
					<Route exact path="/online/classes/:batchID/:batchName" component={OnlineClasses} />
					<Route exact path="/terms-conditions" component={TermsConditions} />
					<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					<Route exact path="/return-policy" component={ReturnPolicy} />

					<Route exact path="/forgot-password" component={ForgotPassword} />
					<Route exact path="/video-player/:videoID" component={VideoPlayer} />
					<Route exact path="/pdf-reader/:filePath" component={PDFReader} />
					<Route exact path="/bookcart" component={BookCart}/>
					<Route exact path="/Examination" component={Examination}/>
					<Route exact path="/Examination1" component={Examination1}/>
					<Route exact path="/Result" component={Result}/>
					<Route exact path="/ExportExample" component={ExportExample}/>
					<Route exact path='/Cart' component={Cart} />
					<Route exact path="/MyOrders"  component={MyOrder}/>
					<Route exact path="/ImageCollection"  component={ImageCollection}/>

					<Route exact path="/courses/:courseID/batches/:type/:typeName/details" component={BatchDetails} />
					

					{/* Auth routes */}
					{
						(is_auth && auth_user) ?
							<div>
								<Route exact path="/edit-profile" component={EditProfile} />
								<Route exact path="/change-password" component={ChangePassword} />
							</div>
							: null
					}
				</div>
			</Router>
		)
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth: state.home.is_auth,
		auth_user: state.home.auth_user,
		cart_count: state.home.cart_count
	}
}

const mapActionsToProps = {
	onClickLogOut: userLogout
}

export default compose(
	withRouter,
	connect(mapStatesToProps, mapActionsToProps)
  )(Routes);