import { GET_BOOKS_SUCCESS } from '../actions/Books';

const initialState = {
    books: [],
    publications: []
}

const Books_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_BOOKS_SUCCESS:
            return Object.assign({}, state, { books: payload.books, publications: payload.publications, subscriptions : payload.subscriptions });
        
        case GET_BOOKS_SUCCESS:
            return Object.assign({}, state, { books: payload.books, publications: payload.publications, subscriptions : payload.subscriptions });

        default:  
            return state;
    }
}

export default Books_reducer;