import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../utils/ApiService';
import {mainLoadingTrue, mainLoadingFalse, displayError} from './Home';

export const GET_ACHIEVERS_SUCCESS = 'GET_ACHIEVERS_SUCCESS';
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';

export const getAchievers = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_ACHIEVERS)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getAchieversSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getAllGalleryImage = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_ALL_IMAGES)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getAllGalleryImageSuccess(data.data.galleryCategory));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getAchieversSuccess = (data) => {
    return {
        type : GET_ACHIEVERS_SUCCESS,
        payload : {
            achievers : data,
        }
    }
}

export const getAllGalleryImageSuccess = (data) => {
    return {
        type : GET_IMAGE_SUCCESS,
        payload : {
            images : data,
        }
    }
}
