import React from 'react';
import Navbar from './Navbar';
import QueHeading from './QueHeading';
import { connect } from 'react-redux';
import './TestDetails.css';
import QuestionButton from './QuestionButton';
import './QuestionButton.css';
import {Link} from 'react-router-dom'
import Questions from './Questions';
import { GetQuestions } from '../../actions/Examination';

import Images from '../../assets/images';

class Examination1 extends React.Component {

   
    constructor (props) {
		super(props);
	}

    componentDidMount () {
        if(this.props.location.state && this.props.location.state.id){
            this.props.GetQuestions(this.props.location.state.id);
        }else{
            this.props.history.push('/Examination')
        }
	}

    render(){
        const {questions} = this.props;
    return (
        <div className="fluid-container">
            <div className="mt-2 mb-3">
            <nav area-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link to="/">Home</Link> </li>
                        <li className="breadcrumb-item"><Link to="/Examination">Exams</Link></li>
                        <li className="breadcrumb-item active" area-current="page">{
                            this.props.location.state ? this.props.location.state.test_name : "Selected Test"
                        }</li>
                    </ol>
                </nav>
            </div>
            {
                this.props.location.state && 
                <div className="row">
                {/* <div className="col-lg-12">
                    <Navbar />
                </div> */}
               { (questions && questions.length >0 ) ? 
               <>
                <div className="col-lg-12">
                    <QueHeading propsData={this.props.location.state}/>
                </div>

                <div className="col-lg-12">
                    <Questions questionData={questions ? questions : []} testId={this.props.location.state.id}/>
                </div>
                </>
                :
                <div className="row m-4 test-detail-container ">
                        <div className="p-5 col-12 d-flex justify-center align-items-center Q-num heading ">
                        <div><img src={Images.noResults} alt="No results" height="70" /> No Test Available</div>
                        </div>
                        </div>
                
               }

            </div>
            }
           
        </div>
    )
    }
}
const mapStatesToProps = (state) => {
	return {
		questions : state.test.questions,
	}
}

const mapActionsToProps = {
	GetQuestions : GetQuestions
}

export default connect(mapStatesToProps, mapActionsToProps) (Examination1);
