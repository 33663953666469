import React, { useState, useCallback, useEffect } from 'react';
import './Courses.css';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import $ from 'jquery';
import Images from '../../assets/images';
import Colors from '../../assets/constants/Colors';
import DateFormat from '../../utils/DateFormat';
import {BaseUrl} from '../../utils/ApiService';
import { getCourses } from '../../actions/Courses';
import { useDispatch, useSelector } from 'react-redux'

export default function Courses(props) {
	const {params} = props.match;
	const [state, setState] = useState({typeName: params.typeName, courseType: params.type})
	// const {typeName} = this.state;

	const dispatch = useDispatch();
	const main_loader = useSelector(state => state.home.main_loader)
	const courses = useSelector(state => state.course.courses)
	const is_auth = useSelector(state => state.home.is_auth)

	const loadProducts= useCallback((type) => {
	  /**
	   * first call returns a function from getAllProducts
	   * second will call it and we pass dispatch as the first argument 
	   */ 
	   getCourses(type)(dispatch);
  }, [dispatch, getCourses]); 
  
	useEffect(()=>{
	  loadProducts(params.type)
	  setState({typeName: params.typeName, courseType: params.type})
	},[params.type])


	const handleLoginClick = (e) => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}

	const renderCourseCard = (course) => {
		var {typeName, courseType} = state;

        let batchStatus = '';
        let admiStatus = '';
        let admiColor = '';

        // Batch status
        if (course.admissionStatus === 1) {
            batchStatus = course.batchStatus + ' Upcoming Batches';
        } else if (course.admissionStatus === 2) {
            batchStatus = course.batchStatus + ' Live Batches';
        } else {
            batchStatus = 'No Live Batches';
        }

        // Admission status
        if (course.admissionStatus === 1) {
            admiStatus = 'Admission Open';
            admiColor = Colors.admiGreen;
        } else if (course.admissionStatus === 2) {
            admiStatus = 'Admission Closed';
            admiColor = Colors.admiRed;
        } else {
            admiStatus = 'Admission not started';
            admiColor = Colors.admiOrange;
        }

		return (
			<div key={course.id} className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mb-4 mt-2 d-flex">
				<div className="card itemCard p-3 w-100">
					<div className="text-center">
						<div>
							{course.course_icon_path !== '' ?
								<img src={BaseUrl + course.course_icon_path} className="courseIcon" alt="" />
								:
								<img src={Images.noResults} className="courseIcon" alt="" />
							}
						</div>

						<h5 className="itemHeader mt-2"><b>{course.course_name}</b></h5>
						<p className="medium_text text-blue mb-0">{course.course_duration} </p>

						<div className="d-inline mt-3">
							<img src={Images.calendar} height={18} alt="" />
							<span className="small_text ml-1">{batchStatus}</span>
						</div>
						<br/>

						<div className="d-inline mt-3">
							<i className="fa fa-circle" style={{fontSize: 14, color: admiColor}} />
							<span className="small_text ml-1">{admiStatus}</span>
						</div>
						<div className="mt-2">
							{course.batch_count > 0 &&
								<Link to={{pathname : '/courses/' + course.id + '/batches/' + courseType + '/' + typeName , params : {admissionStatus : course.admissionStatus }}}>
									<button className="btn green-btn btn-md">{course.batch_count + (course.batch_count === 1 ? ' batch' : ' batches')}</button>
								</Link>
							}
						</div>
						<div className="mt-2">
							{course.batch_count <= 0 &&
								<Link to={{pathname : '/courses/' + course.id + '/batches/' + courseType + '/' + typeName , params : {admissionStatus : course.admissionStatus }}}>
									<button className="btn green-btn btn-md">View Course</button>
								</Link>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

	const renderEmptyView = () => {
		if (props.main_loader) {
			return null;
		} else {
			return (
				<div className="row">
					<div className="col text-center no_results_container">
						<img src={Images.noResults} alt="No results" height="100" /><br/><br/>
						<h6>No courses available.</h6>
					</div>
				</div>
			);
		}
	};

	return (

		<div className="container-fluid data_container mt-2 mb-4">
						
			{/* Breadcrumbs and results counter container */}
			<div className="row sort_container">
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
					<nav area-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
							<li className="breadcrumb-item active" area-current="page">{(state.typeName && state.typeName !== '') && state.typeName} Courses</li>
						</ol>
					</nav>
				</div>
				<div className="col text-center">
					{/* <h4 className="header_title"> {(typeName && typeName !== '') && typeName} Courses </h4> */}
				</div>
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
					{/* <span className="small_text text-blue"> Showing {courses.length} courses.</span> */}
				</div>
			</div>

			{courses && courses.length > 0 ? 
				<div className="row justify-content-start">
					{
						courses.map((course) => (
							renderCourseCard(course)
						))
					}
				</div>
			: 
				renderEmptyView()
			}
		</div>

	);
}