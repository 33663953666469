import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import {BaseUrl} from '../../utils/ApiService';
import Images from '../../assets/images';
import { getMyOrders } from '../../actions/Home';
import MaleDoctor from '../../assets/images/icons/male_doctor.png';
import FemaleDoctor from '../../assets/images/icons/female_doctor.png';
import DateFormat from '../../utils/DateFormat';
import TimeFormat from '../../utils/TimeFormat';


const CourseType = {
	"1" : "Online",
	"2" : "Offline" 
}

class MyOrder extends React.Component {

	constructor (props) {
		super (props);
		this.state = { user : this.props.auth_user };
		this.handleEditProfile = this.handleEditProfile.bind(this);
	}

  	componentWillMount () {
		if(!this.props.is_auth || !this.props.auth_user)
		{
			window.location.hash = "#/";
		}
	}

    componentDidMount () {
        this.props.getMyOrders()
    }

	handleEditProfile = (e) => {
		const { name, value } = e.target;
		const { user } = this.state;
		user[name] = value;
		this.setState({ user : user });
	}

    renderReadButton = (filePath) => {
		if (filePath && filePath !== '') {
			return (
				<Link to={'/pdf-reader/' + filePath.replace('/book_files/','')}>
					<button className="btn btn-primary btn-sm ml-0">READ</button>
				</Link>
			);
		} else {
			return <button className="btn btn-primary btn-sm ml-0" disabled={true}>READ</button>
		}
	};

	handleUpdateProfile = (e) => {
		e.preventDefault();

		swal({
			title: "Are you sure?",
			text: "To update your profile?",
			buttons: ['Cancel', 'Update'],
		})
		.then(willRequest => {
			if (willRequest) {
				var formData = new FormData(document.getElementById('update_profile_form'));
				this.props.onUpdateProfile(formData);
			}
		});
	}

    renderEmptyView = (Text) => {
        Text = Text ? Text : "Data"
		if (this.props.main_loader) {
			return null;
		} else {
			return (
				<div className="row">
					<div className="col text-center no_results_container">
						<img src={Images.noResults} alt="No results" height="100" /><br/><br/>
						<h6>No {Text} available.</h6>
					</div>
				</div>
			);
		}
	};

	render () {

        const {myOrders} = this.props;
		const {batches, tests, books } = myOrders;

		return (
			<div className="container-fluid data_container mt-2 mb-4">          
				{/* Breadcrumbs and results counter container */}
				<div className="row sort_container">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item active" area-current="page">My Cart</li>
							</ol>
						</nav>
					</div>
				</div>

				<ul class="nav nav-pills mb-3 navbar-nav BP-nav" id="pills-tab" role="tablist">
					<li class="nav-item">
						<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Batches</a>
					</li>
					<li class="nav-item" >
						<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Books</a>
					</li>
					<li class="nav-item" >
						<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-exams" role="tab" aria-controls="pills-profile" aria-selected="false">Exams</a>
					</li>
				</ul>
				
				<div class="tab-content" id="pills-tabContent">
					<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
						{batches && batches.length > 0 ? 
							<div className="row d-flex justify-content-start p-2">
								{batches.map((book, index) => (
                                            <div key={index} className="col-xl-4 col-md-6 col-12 mt-2 mb-4 pb-0 d-flex" >
                                                <div className="card w-100 p-2 d-inline">
                                                    <div className=" p-2">
                                                        {/* <h4 className="text-info text-uppercase"><b>{book.batch_name}</b></h4> */}

                                                        <div className="p-2">
                                                            <div className="row my-1">
                                                                <div className="col-6 text-blue">Batch Name :</div>
                                                                <div className="col-6 text-blue text-capitalize">{book.batch_name}</div>
                                                            </div>
                                                            <div className="row my-1">
                                                                <div className="col-6 text-blue">Batch Type :</div>
                                                                <div className="col-6 text-blue text-capitalize">{CourseType[book.batch_type]}</div>
                                                            </div>
                                                            <div className="row my-1">
                                                                <div className="col-6 text-blue">Starts :</div>
                                                                <div className="col-6 text-blue text-capitalize">{book.batch_start_date && DateFormat(book.batch_start_date, 2)}</div>
                                                            </div>
                                                            <div className="row my-1">
                                                                <div className="col-6 text-blue">Time :</div>
                                                                <div className="col-6 text-blue text-capitalize">{book.batch_start_time && TimeFormat(book.batch_start_time.slice(0, 6))}</div>
                                                            </div>
                                                            <div className="row my-1">
                                                                <div className="col-6 text-blue">Ordered :</div>
                                                                <div className="col-6 text-blue text-capitalize">{book.ordered_date && DateFormat(book.ordered_date, 2)}</div>
                                                            </div>

                                                        </div>
                                                        <div>
                                                               <div className="ml-auto">
                                                                    <Link className="text-blue" to={{pathname: `/courses/${book.course_id}/batches/1/online`}}><button className={`abroad_btn mt-3 mr-2 w-100 p-2 `}>VIEW</button></Link>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
								))
								}
							</div>
						:
							this.renderEmptyView("Batches")
						}
					</div>
					<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
						{books && books.length > 0 ? 
							<div className="row d-flex justify-content-start p-2">
								{books.map((book, index) => (
									<div key={index} className="col-lg-4 col-md-4 col-4 px-3 mb-2 text-center" >
                                    <div className="py-1 px-4 imageDivv">
                                        <img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="img-fluid img-thumbnail" alt="" />
                                    </div>
                                    <p className="itemSubHeader my-1">{book.book_name}</p>
                                    <span className="itemDescription">{book.book_discription}</span>
                                    <span className="medium_text">{(book.book_price !== '' && book.book_price > 0) ? ('Rs. ' + book.book_price) : 'FREE'}</span>
                                    <br/>
                                    { book.book_file_path && 
                                        <button className="btn btn-primary btn-sm ml-0" onClick={() => this.renderReadButton(book.book_file_path)}>Read</button>
                                    }
                                </div>
									))
								}
							</div>
						:
							this.renderEmptyView("Books")
						}
					</div>

                    <div class="tab-pane fade" id="pills-exams" role="tabpanel" aria-labelledby="pills-profile-tab">
						{tests && tests.length > 0 ? 
							<div className="row d-flex justify-content-start p-2">
								{tests.map((book, index) => (
									<div key={index} className="col-xl-4 col-md-6 col-12 mt-2 mb-4 pb-0 d-flex" >
                                    <div className="card w-100 p-2 d-inline">
                                        <div className=" p-2">

                                            <div className="p-2">
                                                <div className="row my-1">
                                                    <div className="col-6 text-blue">Exam Name :</div>
                                                    <div className="col-6 text-blue text-capitalize">{book.test_name}</div>
                                                </div>
                                                <div className="row my-1">
                                                    <div className="col-6 text-blue">Exam Type :</div>
                                                    <div className="col-6 text-blue text-capitalize">{"Online"}</div>
                                                </div>
                                                <div className="row my-1">
                                                    <div className="col-6 text-blue">Order Amount :</div>
                                                    <div className="col-6 text-blue text-capitalize">{book.order_price}</div>
                                                </div>
                                            </div>
                                            <div className="ml-auto">
                                                                    <Link className="text-blue" to={{pathname: `/Examination` , state : {type : "online"}}}><button className={`abroad_btn mt-3 mr-2 w-100 p-2 `}>VIEW</button></Link>
                                                                </div>
                                           </div>
                                    </div>
                                </div>
									))
								}
							</div>
						:
							this.renderEmptyView("Exams")
						}
					</div>

				</div>

				
			</div>
		)
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		auth_user : state.home.auth_user,
        myOrders : state.home.myOrders
	}
}

const mapActionsToProps = {
    getMyOrders : getMyOrders,
}

export default connect(mapStatesToProps, mapActionsToProps) (MyOrder);