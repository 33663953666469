import React, { useRef } from 'react';
// import kendoka from './react-kendoka.png';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import "./PDF.css";


export const ExportPDF = (props) =>{
    const pdfExportComponent = useRef(null);
    const contentArea = useRef(null);
    
    const handleExportWithComponent = (event) => {
      pdfExportComponent.current.save();
    }

    return (
        <><PDFExport ref={pdfExportComponent} paperSize="A4">
        <div ref={contentArea}>
            {props.children}
        </div>
        </PDFExport> 
        <div className="button-area">
			<button className="btn btn-primary btn-sm ml-0" onClick={handleExportWithComponent}>PDF</button>
          </div>
        </>
    )
}
function ExportExample () {
 

  return (
    <div className="app-content">
      <ExportPDF>
                                  
<div class="receipt-content">
    <div class="container bootstrap snippets bootdey">
		<div class="row Rowbootdey">
			<div class="col-md-12">
				<div class="invoice-wrapper">
					<div class="payment-info">
						<div class="row">
							<div class="col-sm-5">
								<span>Receipt No.</span>
								<strong>434334343</strong>
							</div>
							<div class="col-sm-5 text-right">
								<span>Receipt Date</span>
								<strong>Jul 09, 2014 - 12:20 pm</strong>
							</div>
						</div>
					</div>

					<div class="payment-details">
						<div class="row d-flex justify-content-between">
							<div class="col-sm-5">
								<span>Client</span>
								<strong>
									Andres felipe posada
								</strong>
								<p>
									989 5th Avenue <br/>
									City of monterrey <br/>
									USA - 99383<br/>
									<a href="#">
										jonnydeff@gmail.com
									</a>
								</p>
							</div>
							<div class="col-sm-6 text-justify">
								<span>Payment To</span>
								<strong>
									Juan fernando arias
								</strong>
								<p>
									344 9th Avenue <br/>
									San Francisco <br/>
									USA - 99383<br/>
									<a href="#">
										juanfer@gmail.com
									</a>
								</p>
							</div>
						</div>
					</div>

					<div className="">
                        <table className="listTable">
						<thead class="">
								<td>Product</td>
								<td>Unit Price</td>
								<td >Quantity</td>
								<td className="amt">Amount</td>
						</thead>
						<tbody className="">
                                <tr>
                                <td>IAS Books</td>
								<td>2000 Rs</td>
								<td >1</td>
                                <td className="text-right">1800 Rs</td>
                                </tr>

                                <tr>
                                <td>IAS Books</td>
								<td>2000 Rs</td>
								<td >1</td>
                                <td className="text-right">1800 Rs</td>
                                </tr>

                                <tr>
                                <td>IAS Books</td>
								<td>2000 Rs</td>
								<td >1</td>
                                <td className="text-right">1800 Rs</td>
                                </tr>
							</tbody>
                            <br/>
                            <tfoot className="mt-1">
                                <tr>
                                    <td className="tdNone"></td>
                                    <td className="tdNone"></td>
                                    <td className="text-left">Sub total</td>
                                    <td className="text-right">1800 Rs</td>
                                </tr>
                                <tr>
                                    <td className="tdNone"></td>
                                    <td className="tdNone"></td>
                                    <td className="text-left">Sales total</td>
                                    <td className="text-right">18%</td>
                                </tr>
                                <tr className="amtfoot">
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className="text-left font-weight-bold">Total</td>
                                    <td className="text-right font-weight-bold">1999 Rs</td>
                                </tr>
                                <br/>
                                <tr>
                                    <td className="tdNone"></td>
                                    <td className="tdNone"></td>
                                    <td className="text-left font-weight-bold">Total Paid</td>
                                    <td className="text-right font-weight-bold">1800 Rs</td>
                                </tr>
                                <tr>
                                    <td className="tdNone"></td>
                                    <td className="tdNone"></td>
                                    <td className="text-left">Outstanding Balence</td>
                                    <td className="text-right">180 Rs</td>
                                </tr>
                            </tfoot>
                        </table>
                        <br/>
                        <br/>
						<div class="total font-italic">
							<p class="extra-notes">
								Please send all items at the same time to address 
                                by next week
							</p>
						</div>

						<div class="print">
							<a href="#">
								<i class="fa fa-print"></i>
								Print this receipt
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>                    
      </ExportPDF>
    </div>
  );
}

export default ExportExample;