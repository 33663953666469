import React from 'react';
import './EditProfile.css';
import { connect } from 'react-redux';
import $ from 'jquery';
import swal from 'sweetalert';
import { updatePassword, displayError } from '../../actions/Home';
class ChangePassword extends React.Component {

  	componentWillMount () {
		if(!this.props.is_auth || !this.props.auth_user)
		{
			window.location.hash = "#/";
		}
	}

	componentDidMount = () => {
		$('.show_hide_pwd').click(function () {
			$('.show_hide_pwd').toggleClass('fa-eye').toggleClass('fa-eye-slash');

			if($('.show_hide_pwd').hasClass('fa-eye'))
			{
				$('.change_password input').removeAttr('type','password').attr('type','text');
			}
			else
			{
				$('.change_password input').removeAttr('type','text').attr('type','password');
			}
		})
	}

	handleUpdatePassword = (e) => {
		e.preventDefault();

		if($('#edit_pwd').val().length < 8)
		{
		  this.props.onDisplayError('Provide current password');
		}
		else if($('#edit_new_pwd').val().length < 8)
		{
		  this.props.onDisplayError('New Password must have atleast 8 characters');
		}
		else if($('#edit_new_pwd').val() !== $('#edit_cfrm_pwd').val())
		{
		  this.props.onDisplayError('Confirm password must be same as new password');
		}
		else
		{
			swal({
				title: "Are you sure?",
				text: "To update your password?",
				buttons: ['Cancel', 'Update'],
			})
			.then(willRequest => {
				if (willRequest) {
					var formData = new FormData(document.getElementById('update_password_form'));
					this.props.onUpdatePassword(formData);
				}
			});
		}
	}

	render () {
        
        const { auth_user } = this.props;

		return (

			<div className="row mb-4">
				<div className="col">
				
                    <div className="edit_profile_container text-center mt-5">
						<h2 className="sub_header mb-4"><i className="fa fa-lock mr-1"></i> CHANGE PASSWORD </h2>

                        <div className="row">
                            <div className="col"></div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="change_password">
                                    <form id="update_password_form" onSubmit={this.handleUpdatePassword} autoComplete="off">

										<i className="show_hide_pwd fa fa-eye"></i>

                                        <div className="form-group">
                                            <label className="small_text"> Current password * : </label>
                                            <input type="text" name="current_password" id="edit_pwd" className="form-control" placeholder="Current Password *" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="small_text"> New password * : </label>
                                            <input type="text" name="new_password" id="edit_new_pwd" className="form-control" placeholder="New Password *" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="small_text"> Confirm password * : </label>
                                            <input type="text" name="confirm_password" id="edit_cfrm_pwd" className="form-control" placeholder="Confirm Password *" required />
                                        </div>
                                        <div className="form-group mt-4">
                                            <button type="submit" className="abroad_btn"> <i className="fa fa-check"></i> UPDATE PASSWORD </button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                            <div className="col"></div>
                        </div>
                    </div>

				</div>
			</div>

		)
	}
}

const mapStatesToProps = (state) => {
	return {
        is_auth : state.home.is_auth,
        auth_user : state.home.auth_user
	}
}

const mapActionsToProps = {
	  onDisplayError : displayError,
	  onUpdatePassword : updatePassword
}

export default connect(mapStatesToProps, mapActionsToProps) (ChangePassword);