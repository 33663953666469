import React from 'react';
import './Batches.css';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import $ from 'jquery';
import Images from '../../assets/images';
import DateFormat from '../../utils/DateFormat';
import TimeFormat from '../../utils/TimeFormat';
import CurrencyFormat from '../../utils/CurrencyFormat';
import { ApiService, ApiPaths, BaseUrl } from '../../utils/ApiService';
import { getBatches } from '../../actions/Courses';
import AppConstants from '../../assets/constants/AppConstants';
import swal from 'sweetalert';
import { CalendarClockOutline } from 'material-design-icons'
import moment from 'moment'


const CourseType = {
	"1": "Online",
	"2": "Offline"
}

class Batches extends React.Component {

	constructor(props) {
		super(props);
		const { params } = props.match;
		this.state = { courseName: params.courseName, courseID: params.courseID, typeName: params.typeName, courseType: params.type, admissionStatus: 3 };
	}

	componentDidMount() {
		this.props.onGetBatches(this.state.courseID, this.state.courseType);
		// const admissionStatus =  this.props.location.params.admissionStatus;
		// this.setState({admissionStatus})
	}

	handleLogin = () => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}



	renderFaculty = (faculties) => {
		if (faculties.length > 0) {
			return (
				<div className="row faculty_container">
					{
						faculties.map((faculty, index) => (
							<div className="col-md-3 col-sm-3 col-6 faculty_view my-1" key={index}>
								<img src={faculty.faculty_image && faculty.faculty_image !== '' ? (BaseUrl + faculty.faculty_image) : Images.men} className="img-thumbnail faculty_image mb-1" alt="" />
								<br />
								<label className="xs_text text-blue">{faculty.faculty_name}</label>
							</div>
						))
					}
				</div>
			);
		} else {
			return (
				<span className="small_text text-blue my-2">{'No faculty available'}</span>
			)
		}
	}

	renderFees = (title, amount) => {
		if (amount > 0) {
			return (
				<div className="row">
					<div className="col">
						{title === 'Total' ? <h5 className="text-blue text-bold">{title + ' fees'}</h5> : <h5 className="text-blue">{title}</h5>}
					</div>
					<div className="col">
						{title === 'Total' ?
							<h5 className="text-blue text-bold">
								<CurrencyFormat
									amount={amount}
								/>
							</h5>
							:
							<h5 className="text-blue">
								<CurrencyFormat
									amount={amount}
								/>
							</h5>
						}
					</div>
					<div className="col" />
				</div>
			)
		}
	};

	renderEmptyView = () => {
		if (this.props.main_loader) {
			return null;
		} else {
			return (
				<div className="col-5">
					<div className="col text-center no_results_container">
						<img src={Images.noResults} alt="No results" height="100" /><br /><br />
						<h6>No batches available.</h6>
					</div>
				</div>
			);
		}
	};

	renderBatchView = (batch, index) => {
		const { batches, course, } = this.props;
		const { params } = this.props.match;
		const { typeName, courseName, courseType, courseID, batchID } = this.state;
		const a = moment(`${batch.batch_start_date} ${batch.batch_start_time}`, 'YYYY-MM-DD hh:mm')
		const b = moment(new Date());
		const diff = a.diff(b, 'hour');
		let canRegister = true;
		if(diff < 0 || diff < 24){
			canRegister = false;
		}
		return <div key={batch.id} className="col-12 mb-4">
			<div className="card w-100 p-3">

				<div className="d-flex">
					<div className="h1" style={{ fontSize: "65px" }}> <i className="fa fa-calendar"></i></div>
					<div className="pl-3">
						<div>
							<p className="m-0"><span className="text-blue medium_text mb-0">Batch Name :</span><span className="pl-1 text-black text-uppercase">{batch.batch_name} </span></p>
							<p className="m-0"><span className="text-blue medium_text mb-0">Batch Type : </span><span className="pl-1 text-uppercase">{CourseType[batch.batch_type]} </span></p>
							<p className="m-0"><span className="text-blue medium_text mb-0">Course Name : </span><span className="pl-1 text-uppercase">{batch.course_name} </span></p>
						</div>
					</div>
					<div className="ml-auto">
						<Link className="text-blue" to={{ pathname: `/courses/${courseID}/batches/${courseType}/${typeName}/details`, batchData: [batch], courseData: course, isPayment: false }}><button className="abroad_btn">VIEW</button></Link>
					</div>
				</div>


				<div className="d-flex justify-content-between">
					<div>
						<div className="text-blue medium_text mb-0">Start Date</div>
						<div className="text-black medium_text mb-0">{batch.batch_start_date && DateFormat(batch.batch_start_date, 2)}</div>
					</div>
					<div>
						<div className="text-blue medium_text mb-0">Start Time</div>
						<div className="text-black medium_text mb-0">{moment(batch.batch_start_date +" "+batch.batch_start_time).format('h:mm:ss a')}</div>

					</div>
					<div>
						<div className="text-blue medium_text mb-0">Duration</div>
						<div className="text-black medium_text mb-0">{course.course_duration}</div>
					</div>
				</div>

				{ canRegister &&
				<div className='scrolling-text'>
					<div>
						<marquee>Only Few Seats Available</marquee>
					</div>
				</div>
				}
				{!canRegister &&
				<div className='scrolling-text'>
					<div>
						<marquee>Opps! Admission Closed Recently</marquee>
					</div>
				</div>
				}

				<div className="">
					<div className="my-2">{'Description'}</div>
					<div className="text-blue medium_text text-justify description-limit">{course.course_discription}</div>
				</div>

				<div className="mt-2" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target={"#faculty_container_" + index}>
					<h6 className="float-left mt-1">{'FACULTY'}</h6>
					<h6 className="float-right mt-1"><i className="fa fa-chevron-down text-right" /></h6>
				</div>

				<div id={"faculty_container_" + index} className="collapse">
					{this.renderFaculty(batch.faculty)}
				</div>
				<hr className="mt-1" />

				{/* <hr className="mt-1 mb-2" /> */}
				{batch.total_fees > 0 ?
					this.renderFees('Total', batch.total_fees)
					: <span className="small_text text-blue my-2">{'No fees available, We will update it soon.'}</span>
				}

				<span className="small_text text-blue"> {'* Fees are subject to terms and conditions'}</span>

				{/* <hr className="mt-2" /> */}
				{
					canRegister ?
				
				<div className="mt-1 d-flex  align-items-center flex-column">
					{(courseType == 1 && batch.isRegistered) && (

						<div style={{ width: "300px" }}>
							<Link to={{ pathname: "/online/classes/" + batch.id + '/' + batch.batch_name, params: params }}>
								<button type="button" className="abroad_btn w-100 p-2 mt-2">{'VIEW CLASSES'}</button>
							</Link>
						</div>
					)}

					{(courseType == 1 && batch.isRegistered) && (
						<div style={{ width: "200px" }}>
							<button type="button" disabled={true} className="abroad_btn2 w-100 p-2 mt-2">{'ALREADY REGISTERED'}</button>
						</div>
					)}
					{
						!this.props.is_auth && (
							<div style={{ width: "200px" }}>
								<button type="button" className="abroad_btn2 w-100 p-2 mt-2"
									onClick={() => this.showRegisterMessage()}
								>{'REGISTER'}</button>
							</div>
						)
					}
					{(this.props.is_auth && batch.isRegistered === false) && (
						<div style={{ width: "200px" }}>
							<Link className="text-blue" to={{ pathname: `/courses/${courseID}/batches/${courseType}/${typeName}/details`, batchData: [batch], courseData: course, isPayment: true }}>
								<button type="button" className="abroad_btn2 w-100 p-2 mt-2"
								// onClick={() => this.handleBatchRegister(batch.id, batch.total_fees)}
								>{'REGISTER'}</button>
							</Link>
						</div>
					)}
				</div>
				:
				<div className="mt-1 d-flex  align-items-center flex-column">
					<div style={{ width: "300px" }}>
						<button type="button" disabled={true} className="abroad_btn2 w-100 p-2 mt-2">{'ADMISSION CLOSED'}</button>
					</div>
				</div>
				}
			</div>
		</div>

	}

	showRegisterMessage = () => {
		swal({
			title: "REGISTER",
			text: "Login Before Registering to Batch",
			// buttons: ['CANCEL', 'REGSITER'],
		}).then(() => {
			$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
		})
	}

	render() {
		const { batches, course, } = this.props;
		const { params } = this.props.match;
		const { typeName, courseName, courseType, courseID, batchID } = this.state;
		if (batches.length) {
			// const a = moment(`${batches[0].batch_start_date} ${batches[0].batch_start_time}`, 'YYYY-MM-DD hh:mm')
			const a = moment('2022-01-26 07:20:00', 'YYYY-MM-DD hh:mm')
			const b = moment(new Date())
		}

		return (

			<div className="container-fluid data_container mt-2 mb-4">

				{/* Breadcrumbs and results counter container */}
				<div className="row sort_container">
					<div className="col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item"><Link to={"/courses/" + courseType + "/" + typeName}> {(typeName && typeName !== '') && typeName} Courses</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Batches</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="row">
					<div className="col text-center">
						<h4 className="header_title mb-3"> {course && course.course_name} {(typeName && typeName !== '') && typeName} Batches </h4>
					</div>
				</div>



				{/* {batches && batches.length > 0 ?  */}
				<div className="row">
					<div className="col-7 my-2">
						<div className="card w-100 p-3">
							<div className="d-flex justify-content-between">
								<div>
									<p className="text-blue medium_text mb-0">Course Name</p>
									<h6 className="text-uppercase">{course.course_name}</h6>
								</div>
								<div>
									<p className="text-blue medium_text mb-0">Duration</p>
									<h6 className="text-uppercase ">{course.course_duration}</h6>
								</div>
							</div>

							<hr className="my-2" />

							<h6 className="my-2">{'Description'}</h6>
							<p className="text-blue medium_text ">{course.course_discription}</p>
							<hr className="mt-0" />

							<h6 className="my-2">{'Syllabus'}</h6>
							<p className="text-blue medium_text">{course.course_syllabus}</p>
							<hr className="mt-0" />

							<h6 className="my-2">{'Instructions'}</h6>
							<p className="text-blue medium_text">{course.course_instructions}</p>
							<hr className="mt-0" />
						</div>
					</div>
					{batches && batches.length > 0 ?
						<div className="col-5">
							<div className="row p-2">
								{
									batches.map((batch, index) => {
										return this.renderBatchView(batch, index)
									})
								}
								<div className="d-flex justify-content-center">
									<Link
										// to={'/courses/' + 'course.id' + '/batches/' + 'batchId' + '/details' }
										to={{ pathname: `/courses/${courseID}/batches/${courseType}/${typeName}/details`, batchData: batches, courseData: course, isPayment: false }}
									>
										{/* courses/1/batches/1/online */}
										<button className="abroad_btn w-100 p-2 mt-2">View All Batches</button>
									</Link>
								</div>
							</div>
						</div>
						:
						this.renderEmptyView()
					}
				</div>

			</div>

		);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth: state.home.is_auth,
		auth_user: state.home.auth_user,
		main_loader: state.home.main_loader,
		batches: state.course.batches,
		course: state.course.course
	}
}

const mapActionsToProps = {
	onGetBatches: getBatches
}

export default connect(mapStatesToProps, mapActionsToProps)(Batches);