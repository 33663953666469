import React from 'react';
import './AboutUs.css';
import { connect } from 'react-redux';
import $ from 'jquery';
import Logo from '../../assets/images/icons/logo.png';

class AboutUs extends React.Component {

  constructor (props) {
    super (props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }
  
  handleLoginClick = (e) => {
    // Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
  }

  render () {

    const { is_auth } = this.props;

    return (

      <div className="row">
        <div className="col about_us_container" style={{margin : "0 5%"}}>

          <div className="row description_card p-0">
            <div className="col">
              <h3 className="service_header"><b>About Us</b></h3>
            </div>
          </div>

          <div className="row description_card">
            <div className=" col-12 p-3 text-center">
              <img src={Logo} alt="Logo" height="120" className="" /> 
            </div>
            <div className=" col-12 p-1">
              <h4 className="header">Karnataka Classic Education Pvt Ltd </h4>
              <p className="para"><b>A pioneer in coaching for competitive exams</b> </p>
              <p className="para"> 
                Dharwad, regarded as cultural capital of Karnataka, is also well known for educational institutions. 25 years ago, the world was not as fast as today. Karnataka, particularly NorthKarnataka, had no infrastructure for competitive examinations, as there was no awareness. Considering the lacuna, Mr Laxman S Uppar, an engineer by qualification, took the initiative of providing coaching for various competitive examinations. 
              </p>
              <p className="para">
                As a result, The Classic KAS & IAS Study Circle was started in 2nd February 1997 in Jayanagar of Dharwad. Started with a few aspirants of various services, now the Classic Study Circle is providing coaching for over two thousand candidates on regular basis. There are at least 10 to 15 batches, each batch of 100 students, preparing for exams like the IAS/IPS, KAS, PSI, Banking, teachers’ appointment, FDA, SDA, PDO, PC and others.
               </p>
            </div>
            <div className="col-12 p-1">
              <p className="para">So far as quality of teaching is concerned, the Classic Study Circle is unique in whole Karnataka as its faculty members are well trained and experienced in different subjects. The focused teaching, based on syllabus and previous questions of respective examinations, has been helping the candidates to gain knowledge required for the competitive examinations in shortest possible time. </p>
            </div>
            <div className="col-12 p-1">
              <p className="para">
              Since 2009, The Classic KAS & IAS Study Circle is being run under the banner of Karnataka Classic Education Pvt. Ltd. The main objective of starting the study circle was to provide quality coaching at affordable fees for all competitive exams, mainly for poor and rural candidates of Karnataka. 
              </p>
              <p className="para">
              The institute has trained hundreds of candidates to secure various top posts in State and Central services. Our teaching faculty includes experienced persons, gold medalists, orientation classes and motivation by KAS & IAS rank holders, guest lecturers from Hyderabad & Bangalore, besides resource persons from various fields of the society. Classic Study Circle is committed to Quality teaching and individual caring. 
              </p>
              <p className="para">
              The Institution has well-furnished classrooms, well equipped library, most experienced faculty, efficient administrative staff. The Study Circle has been providing class notes & updated study material based on the revised syllabus. The Study Circle has been organizing seminars and workshops regularly, in which the civil servants and experts in respective fields take part and guide the students.
              </p>
              <p className="para">
              The 24X7 library in the Study Circle has good collection of books pertaining to all competitive exams in English as well as in Kannada. The students are also provided with over a dozen daily newspapers and monthly magazines for competitive exams, both in English and Kannada languages. Apart from this, the students are provided notes and special study material on related subjects. 
              </p>
              <p className="para">
              The Study circle has branches in Bengaluru & Kalaburagi. The Study Circle has separate hostel facility for ladies and gents with transportation arrangement. With all these facilities, the Study Circle could ensure success of a few thousand candidates in various competitive examinations during the last 25 years.
              </p>
            </div>
          </div>
           <div className="row description_card">
            <div className="col">
              {/* <h2 className="service_header"><b>Our Services</b></h2> */}
            </div>
          </div>

          {/* Our services */}
          {/* <div className="row description_card">
            <div className="col">
              <h2 className="service_header"><b>Our Services</b></h2>
            </div>
          </div>

          <div className="row description_card">
            <div className="row pt-0 mt-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h4 className="header"> Expert Counselling </h4>
                <p className="para">We have an expert team of NEET counsellors who can clarify all your queries and walk you through the entire admission process step-by-step. The more candidates know about the process, the more confident they will be during the process.</p>
                <p className="para">Information about the counselling process also makes candidates and parents vigilant and reduces the chances of them getting swindled or cheated by fake admission agents.</p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h4 className="header"> Documentation </h4>
                <p className="para">One of the most commonly observed mistake candidates make is not having the right documentation during the counselling process. If the counselling authority is not satisfied with your documentation, they can outright reject your application, bringing your dreams of becoming a doctor to a quick & sudden stop.</p>
                <p className="para">We know all about the documentation process, so we keep you ready to sail through the document verification process without so much as a hiccup.</p>
              </div>
            </div>
          </div>

          <div className="row description_card">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <h4 className="header"> Rules &amp; Regulations </h4>
              <p className="para">The rules of the admission process change from state to state and differ when candidates are applying for central universities. It&rsquo;s hard for any candidate to know all these rules, as they keep changing from time to time. Our team keeps track of all these changes, no matter how minute and keep the candidates updated on all these changes.</p>
              <p className="para">&nbsp;We do the information gathering, so you can focus on your preparations and post-exam counselling process. There are also ways to use the rules &amp; guidelines to boost a candidate&rsquo;s chances of securing a seat, that&rsquo;s where our expertise and experience plays a major role.</p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h4 className="header">Application Support </h4>
                <p className="para">The admission cycle starts even before the candidates appear for the exam, one of the most common mistakes candidates make are during the application filling stage. Candidates have a lot of queries and doubts and usually end up messing up the form, and they don&rsquo;t get a second chance at it. This is where we come in, our team walks candidates through the appropriate way to fill the application and make sure all the relevant details are filled in.</p>
                <p className="para">One mistake here and it could very well cost the candidate their medical seat.</p>
              </div>
          </div>
      
          <div className="row description_card">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <h4 className="header"> Choice Entry </h4>
              <p className="para">Choice entry (also called Option Entry) is of one of the most overlooked stages of the entire counselling process, but this is where the candidate&rsquo;s fate is decided. This is the stage where the candidate decides to express their interest in joining a set of medical colleges in the order of their preference. If they input these choices incorrectly, they risk ending up with no seat in hand.</p>
              <p className="para">We have a team of experts working behind the scenes, figuring out the most optimal choice entry strategies that will work for you. This involves a lot of mix &amp; match and using probability and analysis to boost the candidate&rsquo;s chances of securing a medical seat.</p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <h4 className="header"> Notifications &amp; Updates </h4>
              <p className="para">Every year we closely interact with hundreds of NEET aspirants and notice that one of the most common issue plaguing this community is the lack of transparent information that will help these candidates have an easy time with the entire counselling process. Students are often confused and unaware of the counselling dates, the changing rules &amp; guidelines and the updates &amp; notifications published by the various counselling authorities. This information can help them be prepared for the admission process and make sure they don&rsquo;t miss any deadlines.</p>
              <p className="para">Over the past 6 years, thousands of NEET candidates have used our services and have found it to be their secret weapon at tackling the difficult NEET counselling process.</p>
            </div>
          </div>

          <div className="row description_card">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <h4 className="header"> Specialization Selection </h4>
              <p className="para">Are you a NEET PG aspirant trying to find out which specialization to apply for? Worry not, we have an expert panel of well-established doctors from around the world to help you figure it out.</p>
              <p className="para">Candidates can directly apply to interact with the medical professional of their choice and ask them anything about their chosen specialization - the good and the bad.</p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <h4 className="header"> Mock Exams </h4>
              <p className="para">We have tied up with one of the most renowned online mock exam platforms in India - MegaExams. In association with MegaExams, we are providing our candidates with one of the most sought-after mock exams in the state.</p>
              <p className="para">This mock exam platform is powered by a self-learning AI that tracks a student&rsquo;s performance right from the get-go and helps them improve their scores by measuring their strengths &amp; weaknesses.</p>          
            </div>
          </div>

          <div className="row description_card">
            <div className="col">
              <h4 className="header"> FAQs for Notifications &amp; Updates </h4>
              <p className="para"><strong>Q: What sort of notifications will I receive?</strong></p>
              <p className="para">A: There are numerous counselling bodies in India that handle the NEET counselling process and all their rules, guidelines, deadliness, application procedure, documentation vary. You will receive information published by all these counselling authorities the moment they are released.</p><br/>
              <p className="para"><strong>Q: How will I receive this information?</strong></p>
              <p className="para">A: All this important information will be delivered straight to your mobile device, either via SMS and/or WhatsApp.</p><br/>
              <p className="para"><strong>Q: Can I get this information on my own?</strong></p>
              <p className="para">A: You would have to spend countless hours on the internet scouring through the websites of all the counselling authorities every few hours. So yes, it is possible, but that time could be spent elsewhere on more productive things.</p><br/>
              <p className="para"><strong>Q: Will I be reminded about deadlines?</strong></p>
              <p className="para">A: Each counselling authority will publish a list of dates, most candidates tend to miss these dates, for various reasons. We will send you reminders so you never forget a deadline again.</p>
            </div>
          </div> */}
        </div>
      </div>

    )
  }
}

const mapStatesToProps = (state) => {
  return {
    is_auth : state.home.is_auth
  }
}

export default connect(mapStatesToProps, null) (AboutUs);