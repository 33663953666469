import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../utils/ApiService';
import $ from 'jquery';

export const GET_AUTO_COLLEGES_SUCCESS = 'GET_AUTO_COLLEGES_SUCCESS';
export const GET_HOME_SUCCESS = 'GET_HOME_SUCCESS';
export const MAIN_LOAD_TRUE = 'MAIN_LOAD_TRUE';
export const MAIN_LOAD_FALSE = 'MAIN_LOAD_FALSE';
export const SUB_LOAD_TRUE = 'SUB_LOAD_TRUE';
export const SUB_LOAD_FALSE = 'SUB_LOAD_FALSE';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const UPDATE_CART_COUNT = 'UPDATE_CART_COUNT';
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS';
export const GET_SCROLING_SUCCESS = 'GET_SCROLING_SUCCESS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';

export const getAutocompleteColleges = (state_id) => {
    return dispatch => {
        return ApiService.get('/get_autocomplete_colleges' + state_id)
            .then(response => {
                const { data } = response;
                if(data.statuscode === 200)
                    dispatch(getAutocompleteCollegesSuccess(data.colleges));
            })
    }
}

export const getHomeDetails = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        const auth_user = JSON.parse(localStorage.getItem('auth_user'));
        let _login = -1;
        if(auth_user){
            _login = auth_user.id
        }
        return ApiService.get(`${ApiPaths.GET_HOME}/${_login}`)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200 && data.data.userID & data.data.userID !=0) {
                    dispatch(getHomeSuccess(data.data));
                    dispatch(updateCartCount(data.data.cart_count));
                } else {
                    dispatch(displayError(data.message));
                    dispatch(userLogoutSuccess());
                    localStorage.clear();
                    SetAuthToken("");
                    dispatch(setAuthUser({}));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getScrollingText = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_SCROLLING_TEXT)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getScrollingSuccess(data.data[0]['news_title']));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}


export const checkUserExists = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.USER_EXISTS, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(displayMessage(data.message));
                    dispatch(successStatus(ApiStatus.OTP_SENT));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const userRegister = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.REGISTER, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                localStorage.clear()
                if(data.statuscode === 200)
                {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem('auth_user', JSON.stringify(data.data));
                    localStorage.setItem('auth_token', data.data.token);
                    SetAuthToken(data.data.token);
                    dispatch(loginSuccess());
                    dispatch(setAuthUser(data.data));
                    window.location.reload();
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const userLogin = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());    
        return ApiService.post(`${ApiPaths.LOGIN}?mobile_number=${data.login_mobile}&password=${data.login_password}`)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                localStorage.clear();
                if(data.statuscode === 200)
                {
                    debugger;
                    dispatch(displayMessage(data.message));
                    localStorage.setItem('auth_user', JSON.stringify(data.data));
                    localStorage.setItem('auth_token', data.data.token);
                    SetAuthToken(data.data.token);
                    dispatch(loginSuccess());
                    dispatch(setAuthUser(data.data));
                    // window.location.reload();
                }
                else
                {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const userLogout = () => {
    return dispatch => {
        // dispatch(mainLoadingTrue());
        // return ApiService.post('/logout')
        //     .then(response => {
        //         const { data } = response;
        //         dispatch(mainLoadingFalse());
        //         if(data.statuscode === 200)
        //         {
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('auth_user');
                    SetAuthToken('');
                    dispatch(userLogoutSuccess());
                    window.location.href = '/';
            //     }
            // })
    }
}

export const addRemoveFavourite = (college_id) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post('/add_remove_favourite_college/' + college_id)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200)
                    dispatch(displayMessage(data.message));
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const updateProfile = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.UPDATE_PROFILE, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200)
                {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem('auth_user', JSON.stringify(data.data));
                    dispatch(setAuthUser(data.data));
                    window.location.reload();
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const updatePassword = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.CHANGE_PASSWORD, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200)
                {
                    dispatch(displayMessage(data.message));
                    $('#edit_pwd, #edit_new_pwd, #edit_cfrm_pwd').val('');
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const updateSettings = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post('/update_settings', data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200)
                {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem('auth_user', JSON.stringify(data.data));
                    dispatch(setAuthUser(data.data));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

// Send forgot password otp action
export const sendForgotPasswordOTP = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.FORGOT_PASSWORD, data)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statuscode === 200)
                {
                    dispatch(displayMessage(data.message));
                    dispatch(successStatus('OTP sent'));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}

// Update new forgotten password action
export const updateNewPassword = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.RESET_PASSWORD, data)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statuscode === 200)
                {
                    dispatch(displayMessage(data.message));
                    dispatch(successStatus('Password updated'));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}

// Add to cart
export const addRemoveCart = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post('/add_remove_cart', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statuscode === 200)
                {
                    dispatch(displayMessage(data.message));
                    dispatch(updateCartCount(data.cart_count));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}

// Get cart status
export const getCartStatus = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get('/get_cart_status')
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statuscode === 200)
                {
                    dispatch(updateCartCount(data.cart_count));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}


// Get cart details
export const getCartDetails = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_CART_DETAILS)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statuscode === 200) {
                    dispatch(getCartSuccess(data.cart_data, data.cart_amount.total_amount));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}


// Remove from cart
export const removeFromCart = (plan_id) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post('/remove_from_cart/' + plan_id)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statuscode === 200)
                {
                    dispatch(displayMessage(data.message));
                    dispatch(updateCartCount(data.cart_count));
                    dispatch(getCartDetails());
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}

// Get result api
export const getNEETResult = (roll_number) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get('/get_pg_results/' + roll_number)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statuscode === 200)
                    dispatch(getResultSuccess(data.result));
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}


export const getMyOrders = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_ORDERS)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statuscode === 200)
                    dispatch(getOrdersSuccess(data.data));
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}



export const getOrdersSuccess = (data) => {
    return {
        type : GET_ORDERS_SUCCESS,
        payload : data
    }
}

export const getAutocompleteCollegesSuccess = (colleges) => {
    return {
        type : GET_AUTO_COLLEGES_SUCCESS,
        payload : {
            autocomplete_colleges : colleges
        }
    }
}

export const getHomeSuccess = (data) => {
    return {
        type : GET_HOME_SUCCESS,
        payload : {
            home_data : data,
        }
    }
}
export const getScrollingSuccess = (data) => {
    return {
        type : GET_SCROLING_SUCCESS,
        payload : {
            scrollingText : data,
        }
    }
}

export const mainLoadingTrue = () => {
    return {
        type : MAIN_LOAD_TRUE
    }
}

export const mainLoadingFalse = () => {
    return {
        type : MAIN_LOAD_FALSE
    }
}

export const subLoaderTrue = () => {
    return {
        type : SUB_LOAD_TRUE
    }
}

export const subLoaderFalse = () => {
    return {
        type : SUB_LOAD_FALSE
    }
}

export const displayMessage = (message) => {
    return {
        type : DISPLAY_MESSAGE,
        payload : {
            message : message
        }
    }
}

export const displayError = (error) => {
    return {
        type : DISPLAY_ERROR,
        payload : {
            error : error
        }
    }
}

export const hideMessage = () => {
    return {
        type : HIDE_MESSAGE,
    }
}

export const setAuthUser = (auth_user) => {
    return {
        type : SET_AUTH_USER,
        payload : {
            auth_user : auth_user
        }
    }
}

export const loginSuccess = () => {
    return {
        type : LOGIN_SUCCESS
    }
}

export const userLogoutSuccess = () => {
    return {
        type : LOGOUT_SUCCESS
    }
}

export const saveCheckoutSuccess = (order_id) => {
    return {
        type : CHECKOUT_SUCCESS,
        payload : {
            order_id : order_id
        }
    }
}

export const successStatus = (status) => {
    return {
        type : SUCCESS_STATUS,
        payload : {
            status : status
        }
    }
}

export const getCartSuccess = (cartData, cartAmount) => {
    return {
        type : GET_CART_SUCCESS,
        payload : {
            cartData: cartData,
            cartAmount: cartAmount.total_amount
        }
    }
}

export const updateCartCount = (count) => {
    return {
        type: UPDATE_CART_COUNT,
        payload: {
            count: count
        }
    }
}

// Get result success action
export const getResultSuccess = (result) => {
    return {
        type : GET_RESULT_SUCCESS,
        payload : {
            result: result
        }
    }
}