import React, { useEffect, useState, useCallback } from 'react';
import './TestDetails.css';
import { connect,  useDispatch, useSelector  } from 'react-redux';
import {getMyWrittenExam, addWrittenExamSubmission} from '../../actions/Examination';
import {BaseUrl} from '../../utils/ApiService';
import Images from '../../assets/images';
import moment from 'moment'
const WrittenExam = (props) => {

    const [selectedItem, setSelectedItem] = useState({})
    const dispatch = useDispatch();
    const writtenExams = useSelector(state => state.test.writtenExams);

    const loadAPI = useCallback(() => {
        getMyWrittenExam()(dispatch);
    }, [dispatch, getMyWrittenExam]); 
    
    useEffect(()=>{
        loadAPI()
    },[])

    const uploadAnswer = async(item) =>{
        setSelectedItem(item)
        document.getElementById('fileid').click();
    }

    const fileChange = async(e,item) =>{

        const body = new FormData()
        // body.append("batch_id", item.id)
        body.append("written_exam_title", selectedItem.written_exam_title)
        body.append("written_exam_validity", selectedItem.written_exam_validity)
        body.append("written_exam_marks", selectedItem.written_exam_marks)

        body.append("answer_paper", e.target.files[0])

        await addWrittenExamSubmission(selectedItem.id, body)(dispatch);
    }

    const  download = (source) =>{
        const fileName = source.split('/').pop();
        var el = document.createElement("a");
        el.setAttribute("href", source);
        el.setAttribute("target", '_blank');
        el.setAttribute("download", fileName);
        document.body.appendChild(el);
        el.save()
         el.click();
        el.remove();
    }
    
    const downloadFile = (path) => {
        const _path = BaseUrl + path
        // window.location.href = path;
        download(_path);
      }
    const tableRow = (item) =>{
        const status = item.answer_paper == null ? "Not Submitted" : "Submitted";
        const a = moment(new Date(item.written_exam_validity));
        const b = moment(new Date());
        let _showUpload = true;
        if( item.submission_status != "Evaluated" && a > b ){
            _showUpload = false;
        }
        let _showEvaluted = true;
        if( item.submission_status != "Evaluated" && !item.evaluated_paper ){
            _showEvaluted = false;
        }
        return <>
        <th scope="row" style={{textAlign : "left"}}>{item.written_exam_title}</th>
        <td>{item.written_exam_validity}</td>
        <td>{item.course_name} - {item.batch_name}</td>
        <td>{item.obtained_marks && item.written_exam_marks &&  `${item.obtained_marks}/${item.written_exam_marks}`}</td>
        <td>{status}</td>
        <td>{item.written_exam_question && <img src={Images.download} height={"30"} onClick={() => downloadFile(item.written_exam_question)} />}</td>
        <td>{_showUpload || <input id='fileid' type='file' onChange={(e) => fileChange(e, item)} hidden/>  &&  <img src={Images.upload} height={"18"} onClick={() => uploadAnswer(item)} /> }</td>
        <td>{_showEvaluted &&  <img src={Images.download} height={"30"} onClick={() => downloadFile(item.evaluated_paper)} />}</td>
    </>
    }
    return (
        <>
            <div className="container-fluid " >
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mt-3">
                            <table className="table table-sm">
                                <thead>
                                    <tr className="tbl-head">
                                        <th scope="col">Exam Title</th>
                                        <th scope="col">Exam Validity</th>
                                        <th scope="col">Batch - Course</th>
                                        <th scope="col">Marks</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Question Papers</th>
                                        <th scope="col">Answer Papers</th>
                                        <th scope="col">Evaluated Papers</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        writtenExams.map((item,i)=>{
                                            return  <tr key={item.id}> {tableRow(item)}</tr>
                                    })}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default WrittenExam;
