import React from 'react';
import './Batches.css';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import $ from 'jquery';
import Images from '../../assets/images';
import DateFormat from '../../utils/DateFormat';
import TimeFormat from '../../utils/TimeFormat';
import CurrencyFormat from '../../utils/CurrencyFormat';
import {ApiService, ApiPaths, BaseUrl} from '../../utils/ApiService';
import { getBatches } from '../../actions/Courses';
import AppConstants from '../../assets/constants/AppConstants';
import swal from 'sweetalert';
import {CalendarClockOutline} from 'material-design-icons'


const CourseType = {
	"1" : "online",
	"2" : "offline" 
}

class BatchDetails extends React.Component {

	constructor (props) {
		super(props);
		const {params} = props.match;
		this.state = {courseName: params.courseName, courseID: params.courseID, typeName: params.typeName, courseType: params.type};
	}

	componentWillMount () {
		if(!this.props.location.batchData){
			this.props.history.goBack()
		}
	}


	handleBatchRegister = (batchID, totalFees) => {
		const {is_auth, auth_user} = this.props;
		if(is_auth && auth_user) {

			var formData = new FormData();
            formData.append('total_amount', totalFees);
            //formData.append('total_amount', 1);

			swal({
			  title: "REGISTER",
			  text: "You need to pay Rs." + totalFees + " total fees to register to this batch",
			  buttons: ['CANCEL', 'REGSITER'],
			})
			.then(willDelete => {
				if (willDelete) {
					ApiService.post(ApiPaths.SAVE_CHECKOUT, formData)
					.then(response => {
						if(response.data.statuscode === 200)
						{
							// Initialize payment
							var options = {
								"key": AppConstants.RAZORPAY_API_KEY,
								// "amount": (totalFees * 100),
								"amount": 1,
								"name": 'Batch Registration',
								"description": 'Classic Education Dharwad',
								"image": Images.logo1024,
								"order_id" : response.data.rzpay_order_id,
								"currency": 'INR',
								"theme" : {
									"color": "#004053"
								},
								"handler": function (response){
									if(response && response.razorpay_payment_id !== '') {
										// Make payment success in server
										var paymentFormData = new FormData();
										paymentFormData.append('rzpay_order_id', response.razorpay_order_id);
										paymentFormData.append('rzpay_payment_id', response.razorpay_payment_id);
										paymentFormData.append('rzpay_signature', response.razorpay_signature);
										paymentFormData.append('id', batchID);
										paymentFormData.append('order_price', totalFees);
										paymentFormData.append('payment_type', 1); // Type 1 for batch
					
										ApiService.post(ApiPaths.PAYMENT_SUCCESS, paymentFormData)
										.then(response => {
											if(response.data.statuscode === 200) {
												swal({ title :'Thank you', text : 'Payment Success, Batch Registration completed', icon : 'success'});

												setTimeout(() => { window.location.reload(); }, 2000);
											}
										})
										.catch(error => {
											swal('OOPS!...', error.message);
										});
									}
								},
								"prefill" : {
									"contact" : auth_user.mobile_number,
									"email" : auth_user.email
								}
							}
					
							let rzp = new window.Razorpay(options); rzp.open();
						}
					})
					.catch(error => {
						swal('OOPS!...', error.message);
					});
			  	}
			});
		} else {
			this.handleLogin();
		}
	}
	

	render() {

		const {batches, course} = this.props;
		const {typeName, courseName, courseType,courseID} = this.state;

		const batchData = this.props.location.batchData ? this.props.location.batchData : null;
		const courseData = this.props.location.courseData ? this.props.location.courseData : null;
		const isPayment = this.props.location.isPayment ? this.props.location.isPayment : false
		return ( 
			
			<>{batchData && 
			<div className="container-fluid data_container mt-2 mb-4">
				          
				<div className="row sort_container">
					<div className="col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item"><Link to={"/courses/" + courseType + "/" + typeName}>{(typeName && typeName !== '') && typeName} Courses</Link> </li>
								<li className="breadcrumb-item active" area-current="page"><Link to={`/courses/${courseID}/batches/${courseType}/${typeName}`}>Batches</Link></li>
								<li className="breadcrumb-item active" area-current="page">Batch Details</li>
							</ol>
						</nav>
					</div>
				</div>
				
				{isPayment && 
				<div className="registration mb-3 ">
					<h4 className="mb-3">Registration For : {courseData.course_name}/(Batch : {batchData[0].batch_name})</h4>
					<div className="row d-flex justify-content-center">
						{/* <div className="col-12 col-md-5 ml-5 reg-card">
							<div className="card p-2">
								<div className="m-2"><input type="text" class="form-control" placeholder="First Name"  /></div>
								<div className="m-2"><input type="text" class="form-control" placeholder="Mobile Number"  /></div>
								<div className="m-2"><input type="text" class="form-control" placeholder="Email Id"  /></div>
								<div className="m-2"><textarea type="textarea"  rows="3" class="form-control" placeholder="Communication Address"  /></div>
							</div>
						</div> */}
						<div className="col-12 col-md-6 ml-3 reg-card">
							<div className=" p-2">

								<div className="Feelabel d-flex justify-content-between mt-0">
                                    <div>Fee for Online Batch</div>
                                    <div>{batchData[0].online_fees}</div>
                                </div>

								<div className="row ">
									<div className="col-6 regi-box">
										<div><label>Admission Fees</label></div>
									</div>
									<div className="col-6 regi-box text-right">
										<div><label>{parseFloat(batchData[0].admission_fees).toFixed(2)}</label></div>
									</div>
								</div>
								<div className="row ">
									<div className="col-6 regi-box">
										<div><label>Books Fees</label></div>
									</div>
									<div className="col-6 regi-box text-right">
										<div><label>{parseFloat(batchData[0].books_fees).toFixed(2)}</label></div>
									</div>
								</div>
								<div className="row ">
									<div className="col-6 regi-box">
										<div><label>Hostel Fees</label></div>
									</div>
									<div className="col-6 regi-box text-right">
										<div><label>{parseFloat(batchData[0].hostel_fees).toFixed(2)}</label></div>
									</div>
								</div>
								<div className="row ">
									<div className="col-6 regi-box">
										<div><label>Tution Fees</label></div>
									</div>
									<div className="col-6 regi-box text-right">
										<div><label>{parseFloat(batchData[0].tution_fees).toFixed(2)}</label></div>
									</div>
								</div>
								<div className="row ">
									<div className="col-6 regi-box">
										<div><label>Other Fees</label></div>
									</div>
									<div className="col-6 regi-box text-right">
										<div><label>{parseFloat(batchData[0].other_fees).toFixed(2)}</label></div>
									</div>
								</div>

								<div className="Feelabel TotalFees d-flex justify-content-between mt-2">
                                    <div>Total Fees</div>
                                    <div>{parseFloat(batchData[0].total_fees).toFixed(2)}</div>
                                </div>

							</div>
							{
								 <div className="d-flex justify-content-center">
									<button className="btn btn-danger" onClick={() => this.handleBatchRegister(batchData[0].id ,batchData[0].total_fees)}>Make Payment</button>
								</div>
							}
							
						</div>
					</div>
				</div>}

				{ batchData.length > 0 && batchData.map((batch,index) =>(
				<div key={index} className="batch-box">
                <div className="row">
                    <div className="col-12 col-md-4  ">
                        <div className="card pt-3 pb-2 px-3 pb-1 batch-info-card">
                            <div className="">
                                <div>
                                    <div className="mb-3">
                                        <p className="text-blue medium_text mb-0">Batch Name</p>
                                        <h6 className="text-uppercase">{batch.batch_name}</h6>
                                    </div>
                                    <div className="mb-3">
                                        <p className="text-blue medium_text mb-0">Batch Type</p>
                                        <h6 className="text-uppercase">{CourseType[batch.batch_type]}</h6>
                                    </div>
                                    <div className="mb-3">
                                        <p className="text-blue medium_text mb-0">Course Name</p>
                                        <h6 className="text-uppercase">{batch.course_name}</h6>
                                    </div>
									<div className="Feelabel d-flex justify-content-between">
                                    <div>Fee for Online Batch</div>
                                    <div>{batch.online_fees}</div>
                                </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        <div className="card pt-3 pb-2 px-3 pb-1 batch-info-card">
                            <div className="text-blue">Batch Details</div>
                            <div className="description">
                                <p>{courseData.course_discription}
                                </p>
                            </div>
                            <div className="mt-1 d-flex justify-content-center">
                               {!isPayment && 
							   	<Link className="text-blue" to={{pathname: `/courses/${courseID}/batches/${courseType}/${typeName}/details`, batchData: [batch], courseData : courseData, isPayment : true }}>
							  		<button className=" abroad_btn btn btn-danger ">Register Today</button>
						   		</Link>
							   }
                            </div>
                        </div>
                    </div>
                </div>
				
                <div className="row mt-3">
                    <div className="col-12 col-md-4 time-facaulty-card">
                        <div className="card">
                            <div className="py-5 px-2">
                                <div className="d-flex justify-content-between p-2">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="h1" style={{fontSize : "50px"}}> <i className="fa fa-calendar"></i></div>
                                        </div>
                                        <div className="pl-3 pt-2">
                                            <div className="text-blue medium_text mb-0">Start Date</div>
                                            <div className="text-black medium_text mb-0">{batch.batch_start_date && DateFormat(batch.batch_start_date, 2)}</div>
                                        </div>
                                    </div>
                                    <div>
                                       <div className="d-flex justify-content-between">
                                            <div>
                                                <div className="h1" style={{fontSize : "50px"}}> <i className="fa fa-calendar"></i></div>
                                            </div>
                                            <div className="pl-3 pt-2">
                                                <div className="text-blue medium_text mb-0">End Date</div>
                                                <div className="text-black medium_text mb-0">Life Time</div>
                                            </div>
                                       </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between p-2">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="h1" style={{fontSize : "50px"}}> <i className="fa fa-calendar"></i></div>
                                        </div>
                                        <div className="pl-3 pt-2">
                                            <div className="text-blue medium_text mb-0">Start Time</div>
                                            <div className="text-black medium_text mb-0">{batch.batch_start_time}</div>
                                        </div>
                                    </div>
                                    <div className="">
                                       <div className="d-flex justify-content-between">
                                            <div>
                                                <div className="h1" style={{fontSize : "50px"}}> <i className="fa fa-calendar"></i></div>
                                            </div>
                                            <div className="pl-3 pt-2">
                                                <div className="text-blue medium_text mb-0">Duration</div>
                                                <div className="text-black medium_text mb-0">{courseData.course_duration}</div>
                                            </div>
                                       </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-12 col-md-8 time-facaulty-card">
                        <div className="card p-2 ">
                            <div>
                                <h4 className="text-blue">Our Faculty</h4>
                                <div className="d-flex overflow-auto">
									{batch.faculty.map((faculty,i)=>{
										return <div className="d-flex">
												<div className="faculalty-image">
													<img src={Images.onlineClassImage} className="img-fluid" alt="" />
												</div>
												<div className="px-3 text-justify">
													<p className="mb-1 text-dark">{faculty.faculty_name}</p>
													<p className="mb-1 small text-muted d-flex align-items-baseline "><i class="fa fa-phone mr-2" aria-hidden="true"></i>{faculty.faculty_mobile}</p>
													{/*<p className="mb-1 small text-muted  d-flex align-items-baseline"><i class="fa fa-envelope mr-2" aria-hidden="true"></i> {faculty.faculty_email}</p>*/}
													<p className="text-black medium_text">
														{faculty.faculty_details}</p>
												</div>
											</div>
									}) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				</div>
				))}

			</div>
			}
			</>
		);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth: state.home.is_auth,
		auth_user: state.home.auth_user,
		main_loader: state.home.main_loader,
		batches: state.course.batches,
		course: state.course.course
	}
}

const mapActionsToProps = {
	onGetBatches: getBatches
}

export default connect(mapStatesToProps, mapActionsToProps) (BatchDetails);