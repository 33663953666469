import { GET_TESTS_SUCCESS, GET_QUESTIONS_SUCCESS, POST_QUESTIONS_SUCCESS, GET_EXAM_SUCCESS } from '../actions/Examination';

const initialState = {
    tests: [],
    questions : [],
    writtenExams : []
}

const Tests_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_TESTS_SUCCESS:
            return Object.assign({}, state, { tests: payload.tests });
        case GET_QUESTIONS_SUCCESS:
            return Object.assign({}, state, { questions : payload.questions });
        case POST_QUESTIONS_SUCCESS:
            return Object.assign({}, state, { testSubmitStatus : 200 });
        case GET_EXAM_SUCCESS:
            return Object.assign({}, state, { writtenExams : payload.writtenExams });
            
        default:
            return state;
    }
}

export default Tests_reducer;