import {ApiPaths, ApiService} from '../utils/ApiService';
import {mainLoadingTrue, mainLoadingFalse, displayError} from './Home';

export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_BATCHES_SUCCESS = 'GET_BATCHES_SUCCESS';
export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS';

export const getCourses = (type) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_COURSES + type)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getCoursesSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getBatches = (courseID, type) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_BATCHES + courseID + '/' + type)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getBatchesSuccess(data.data.batches));
                    dispatch(getCourseSuccess(data.data.course));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getOnlineClasses = (batchID) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_ONLINE_CLASSES + batchID)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getClassesSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getCoursesSuccess = (data) => {
    return {
        type : GET_COURSES_SUCCESS,
        payload : {
            courses : data,
        }
    }
}

export const getCourseSuccess = (data) => {
    return {
        type : GET_COURSE_SUCCESS,
        payload : {
            course : data,
        }
    }
}

export const getBatchesSuccess = (data) => {
    return {
        type : GET_BATCHES_SUCCESS,
        payload : {
            batches : data,
        }
    }
}

export const getClassesSuccess = (data) => {
    return {
        type : GET_CLASSES_SUCCESS,
        payload : {
            classes : data,
        }
    }
}
