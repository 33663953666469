import { GET_ACHIEVERS_SUCCESS, GET_IMAGE_SUCCESS } from '../actions/Achievers';

const initialState = {
    achievers : [],
    imageCollection : []
}

const Achiever_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_ACHIEVERS_SUCCESS :
            return Object.assign({}, state, { achievers : payload.achievers });
            
        case GET_IMAGE_SUCCESS :
            return Object.assign({}, state, { imageCollection : payload.images });

        default :  
            return state;
    }
}

export default Achiever_reducer;