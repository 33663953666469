import React from 'react';
import { Link } from 'react-router-dom';
import './BookCart.css';

class BookCart extends React.Component {

    render() {
        return (
            <div className="container-fluid data_container mt-2 mb-4">
                {/* Breadcrumbs and results counter container */}
                <div className="row sort_container">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
                        <nav area-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link> </li>
                                <li className="breadcrumb-item"><Link to="/Books">Books</Link> </li>
                                <li className="breadcrumb-item active" area-current="page">Cart</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col text-center">
                        <h4 className="header_title"> My Cart </h4>
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 cart-container" >
                        <div className="row py-3 pl-3">
                            <div className="col-xl-4 col-lg-4 ">
                                <img src="" className="img-bookCart" alt="" />
                                
                            </div>
                            <div className="col-xl-8 col-lg-8" style={{}}>
                                <div className="pt-3">
                                    Book:
                                </div>
                                <div className="pt-3">
                                    Type:
                                </div>
                                <div className="pt-3">
                                    Price:
                                </div>
                                <div className="pt-3">
                                    About:
                                </div>
                                <div className="pt-3 text-center">
                                    <button className="rounded-pill buy-btn">Buy Now</button>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 cart-container" >
                        <div className="row py-3 pl-3">
                            <div className="col-xl-4 col-lg-4 ">
                                <img src="" className="img-bookCart" alt="" />
                            </div>
                            <div className="col-xl-8 col-lg-8" style={{}}>
                                <div className="pt-3">
                                    Book:
                                </div>
                                <div className="pt-3">
                                    Type:
                                </div>
                                <div className="pt-3">
                                    Price:
                                </div>
                                <div className="pt-3">
                                    About:
                                </div>
                                <div className="pt-3 text-center">
                                    <button className="rounded-pill buy-btn">Buy Now</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>



            </div>
        )

    }


}

export default BookCart
