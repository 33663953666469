import React from 'react';
import './Layout.css';
import Routes from '../Routes';
import { connect } from 'react-redux';
import { NavLink, HashRouter as Router, Link } from 'react-router-dom';
import { getAutocompleteColleges, hideMessage, setAuthUser, userLogin, userLogout, getCartStatus, checkUserExists, userRegister } from '../../actions/Home';
import LoginIcon from '../../assets/images/icons/login.png';
import CloseButton from '../../assets/images/icons/close_button.png';
import { SetAuthToken, ApiStatus } from '../../utils/ApiService';
import MaleDoctor from '../../assets/images/icons/male_doctor.png';
import FemaleDoctor from '../../assets/images/icons/female_doctor.png';
import Footer from '../Footer';
import $ from 'jquery';
import { createTrue } from 'typescript';

class Layout extends React.Component {

	constructor(props) {
		super(props);
		this.state = { error: '', show_login: true, agreeTerms: false };
	}

	componentWillMount() {

		if (localStorage.getItem('auth_user') && localStorage.getItem('auth_token')) {
			SetAuthToken(localStorage.getItem('auth_token'));
			this.props.onSetAuthUser(JSON.parse(localStorage.getItem('auth_user')));
		}
	}

	componentDidMount() {

		// Show side login screen
		$('.show_login, .show_profile').on('click', function () {
			$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
		});

		// Hide side login screen
		$('.login_modal_container .menu_close_btn').on('click', function () {
			$('.login_container').removeClass('slideInRight').addClass('slideOutRight');
			// alert(1234);
		});

		// Hide side callback panel
		$('.main_callback_container .menu_close_btn').on('click', function () {
			$('.callback_container').removeClass('slideInLeft').addClass('slideOutLeft');
		});
	}

	handleUserLogin = (e) => {
		e.preventDefault();

		var mobile = document.getElementById('login_mobile').value;
		var pwd = document.getElementById('login_password').value;

		if (mobile === '' || mobile.length !== 10) {
			this.setState({ error: 'Mobile number should have 10 digits' });
		} else if (pwd === '' || pwd.length < 8) {
			this.setState({ error: 'Password should have atleast 8 characters' });
		} else {
			this.setState({ error: '' });
			var formData = new FormData(document.getElementById('login_form'));
			const data = document.getElementById('login_form');
			this.props.onUserLogin({login_mobile : mobile,login_password : pwd});
		}
	}

	handleCheckUserExists = () => {

		var mobile = document.getElementById('reg_mobile').value;
		var pwd = document.getElementById('reg_password').value;
		var confrmPwd = document.getElementById('reg_cnfrm_password').value;

		if (this.state.agreeTerms === false) {
			this.setState({ error: 'Accepts terms and conditions to continue' });
		} else if (mobile === '' || mobile.length !== 10) {
			this.setState({ error: 'Mobile number should have 10 digits' });
		} else if (pwd === '' || pwd.length < 8) {
			this.setState({ error: 'Password should have atleast 8 characters' });
		} else if (pwd !== confrmPwd) {
			this.setState({ error: 'Confirm password must be same as password' });
		} else {
			this.setState({ error: '' });
			var formData = new FormData();
			formData.append('mobile_number', mobile);

			this.props.onCheckUserExists(formData);
		}
	}

	handleUserRegister = (e) => {
		e.preventDefault();

		var mobile = document.getElementById('reg_mobile').value;
		var pwd = document.getElementById('reg_password').value;
		var confrmPwd = document.getElementById('reg_cnfrm_password').value;
		var otp = document.getElementById('reg_otp').value;

		if (mobile === '' || mobile.length !== 10) {
			this.setState({ error: 'Mobile number should have 10 digits' });
		} else if (pwd === '' || pwd.length < 8) {
			this.setState({ error: 'Password should have atleast 8 characters' });
		} else if (pwd !== confrmPwd) {
			this.setState({ error: 'Confirm password must be same as password' });
		} else if (otp.length !== 4) {
			this.setState({ error: 'Invalid OTP' });
		} else {
			this.setState({ error: '' });
			var formData = new FormData(document.getElementById('register_form'));

			this.props.onUserRegister(formData);
		}
	}

	handleLogOut = () => {
		this.props.onClickLogOut();
	}

	renderLoginRegister = () => {
		const { message, error, status } = this.props;
		const { show_login, agreeTerms } = this.state;
		const readStatus = status === ApiStatus.OTP_SENT;

		if (show_login) {
			return (
				<div>
					<h6> Hello Welcome back, </h6>

					<h2> SIGN IN HERE </h2>

					<img src={LoginIcon} alt="Login" height="160" className="login_icon" style={{ padding: '20px' }} />

					<form className="mt-3" id="login_form" onSubmit={this.handleUserLogin} autoComplete="off" >
						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-mobile"></i></span>
							</div>
							<input type="tel" name="mobile_number" id="login_mobile" className="form-control" placeholder="Mobile Number" />
						</div>

						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-lock"></i></span>
							</div>
							<input type="password" name="password" id="login_password" className="form-control" placeholder="Password" />
						</div>

						<div className="mt-1 text-center"><span className="small_text text-light"><b> {this.state.error + ' ' + error + ' ' + message} </b></span></div>

						<div className="form-group text-center mt-4">
							<button type="submit" className="login_btn py-2 px-4"> SIGN IN <i className="fa fa-sign-in ml-1"></i> </button>
							<br />
						</div>
					</form>

					<div className="form-group text-center my-4">
						<span>Forgot password? </span>
						<Router>
							<NavLink to="/forgot-password">
								<button type="button" className="login_btn ml-2" title="Register"> RESET <i className="fa fa-lock ml-1"></i></button>
							</NavLink>
						</Router>
					</div>

					<div className="form-group text-center my-4">
						<span>Not yet registered? </span>
						<button className="login_btn ml-2" title="Register" onClick={() => this.setState({ show_login: false })}> SIGN UP <i className="fa fa-user-plus ml-1"></i></button>
					</div>
				</div>
			)
		} else {
			return (
				<div>
					<h6> New to Classic ?, </h6>
					<h2> SIGN UP HERE </h2>

					{/* <img src={LoginIcon} alt="Login" height="160" className="login_icon" style={{padding:'20px'}} /> */}

					<form className="mt-4" id="register_form" onSubmit={this.handleUserRegister} autoComplete="off" >
						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-user"></i></span>
							</div>
							<input type="text" name="name" id="reg_name" className="form-control" placeholder="Full Name" readOnly={readStatus} required />
						</div>

						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-envelope"></i></span>
							</div>
							<input type="email" name="email" id="reg_email" className="form-control" placeholder="Email ID"  readOnly={readStatus} required />
						</div>

						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-mobile"></i></span>
							</div>
							<input type="tel" name="mobile_number" id="reg_mobile" className="form-control" placeholder="Mobile Number"  readOnly={readStatus} required />
						</div>

						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-lock"></i></span>
							</div>
							<select name="gender" className="form-control" readOnly={readStatus}>
								<option value="Male">Male</option>
								<option value="Female">Female</option>
							</select>
						</div>

						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-lock"></i></span>
							</div>
							<input type="password" name="password" id="reg_password" className="form-control" placeholder="Password"  readOnly={readStatus} required />
						</div>

						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-lock"></i></span>
							</div>
							<input type="password" name="c_password" id="reg_cnfrm_password" className="form-control" placeholder="Confirm Password"  readOnly={readStatus} required />
						</div>

						<div className="form-group input-group">
							<div className="input-group-prepend">
								<span className="input-group-text"> <i className="fa fa-lock"></i></span>
							</div>
							<input type="text" name="referral" id="reg_referral" className="form-control" placeholder="Referral Code" />
						</div>

						{
							status === ApiStatus.OTP_SENT &&
							<div className="form-group input-group">
								<div className="input-group-prepend">
									<span className="input-group-text"> <i className="fa fa-mobile"></i></span>
								</div>
								<input type="tel" name="otp" id="reg_otp" className="form-control" placeholder="OTP" />
							</div>
						}

						<div className="form-group">
							<input type="checkbox" onChange={() => this.setState({ agreeTerms: !agreeTerms })} />
							<label className="ml-2">I Agreed to
								<Router>
									<NavLink to="/terms-conditions">
										<span> Terms and Conditions</span>
									</NavLink>
								</Router>
							</label>
						</div>

						<input type="hidden" name="user_role" defaultValue={1} />
						<input type="hidden" name="terms" defaultValue={'Agree'} />
						<input type="hidden" name="register_platform" defaultValue={'Web'} />

						<div className="mt-1 text-center"><span className="small_text text-light"><b> {this.state.error + ' ' + error + ' ' + message} </b></span></div>

						<div className="form-group text-center mt-4">
							{
								status === ApiStatus.OTP_SENT ?
									<button type="submit" className="login_btn py-2 px-4"> SIGN UP <i className="fa fa-user-plus ml-1"></i> </button>
									:
									<button type="button" className="login_btn py-2 px-4" onClick={() => this.handleCheckUserExists()}> SEND OTP <i className="fa fa-user ml-1"></i> </button>
							}
							<br /><br />
						</div>
					</form>

					<div className="form-group text-center">
						<span>Already registered? </span>
						<button className="login_btn ml-2" title="Login" onClick={() => this.setState({ show_login: true })}> SIGN IN <i className="fa fa-sign-in ml-1"></i></button>
					</div>
				</div>
			)
		}
	}

	render() {

		const { message, error, is_auth, auth_user } = this.props;
		const path = this.props.location.hash;
		let showHeaderFooter = true;
		if(['/about-us-sm','/contact-sm'].includes(path.substring(1))){
			showHeaderFooter = false;
		}
		return (

			<div className="row m-0">
				<div className="col p-0">
					{/* All components in routing */}
					<Routes />

					{/* Footer component */}
					{showHeaderFooter && <Footer /> }
				</div>

				{/* Error or message display container */}
				<div className="message_error_container">
					{
						message && message !== '' ?
							<span className="message_text"> <i className="fa fa-check-circle"></i> {message} </span>
							: ''
					}
					{
						error && error !== '' ?
							<span className="error_text"> <i className="fa fa-exclamation-circle"></i> {error} </span>
							: ''
					}
				</div>

				{/* Login slide menu */}
				<div className="login_modal_container login_container animated slideInRight d-none">
					<img src={CloseButton} height="30" alt="Close" className="menu_close_btn mt-4 mr-1" />

					{/* Login and profile sub menu */}
					{
						is_auth && auth_user ?

							// Display Profile if logged in
							<div>
								<h6> Hello Welcome back, </h6>
								<h2><b> {auth_user.name} </b></h2>

								<img src={auth_user.gender === 'Male' ? MaleDoctor : FemaleDoctor} alt="Login" height="140" className="login_icon" />
								<br />

								<Router>
									{/* <NavLink to={{ pathname : '/college_filters', search : '?c_favourite=1' }} className="abroad_btn user_link" activeClassName="user_link_active"> <i className="fa fa-heart mr-1"></i> My favourites </NavLink> */}
									{/* <NavLink to="/change_stream_settings" className="abroad_btn user_link" activeClassName="user_link_active"> <i className="fa fa-cogs mr-1"></i> Stream settings </NavLink> */}
									<NavLink to="/edit-profile" className="abroad_btn user_link" activeClassName="user_link_active">  <i className="fa fa-user-md mr-1"></i> Edit profile </NavLink>
									<NavLink to="/MyOrders" className="abroad_btn user_link" activeClassName="user_link_active"> <i className="fa fa-lock mr-1"></i> My Orders </NavLink>
									<NavLink to="/change-password" className="abroad_btn user_link" activeClassName="user_link_active"> <i className="fa fa-lock mr-1"></i> Change password </NavLink>
									<button className="abroad_btn user_link bg-danger" onClick={this.handleLogOut}><b> LOGOUT <i className="fa fa-sign-out"></i></b></button>
								</Router>

							</div>

							:
							// Display login if not logged in
							this.renderLoginRegister()
					}
				</div>
			</div>

		);
	}

	componentDidUpdate() {

		// Hide the error or messages
		if (this.props.error !== '' || this.props.message !== '') {
			setTimeout(function () { this.props.onHideMessage() }.bind(this), 2400);
		}
	}
}

const mapStatesToProps = (state) => {
	return {
		main_loader: state.home.main_loader,
		is_auth: state.home.is_auth,
		auth_user: state.home.auth_user,
		is_login: state.home.is_login,
		error: state.home.error,
		message: state.home.message,
		status: state.home.status,
	}
}

const mapActionsToProps = {
	onGetAutocompleteColleges: getAutocompleteColleges,
	onHideMessage: hideMessage,
	onUserLogin: userLogin,
	onSetAuthUser: setAuthUser,
	onClickLogOut: userLogout,
	onGetCartStatus: getCartStatus,
	onCheckUserExists: checkUserExists,
	onUserRegister: userRegister,
}

export default connect(mapStatesToProps, mapActionsToProps)(Layout);