import {ApiPaths, ApiService} from '../utils/ApiService';
import {mainLoadingTrue, mainLoadingFalse, displayError, updateCartCount} from './Home';

export const GET_BOOKS_SUCCESS = 'GET_BOOKS_SUCCESS';

export const getBooks = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_BOOKS)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getBooksSuccess(data.data));
                    dispatch(updateCartCount(data.data.cart_count));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
        })
    }
}

export const getBooksSuccess = (data) => {
    return {
        type : GET_BOOKS_SUCCESS,
        payload : {
            books: data.books,
            publications: data.publications,
            subscriptions: data.subscriptions
        }
    }
}
