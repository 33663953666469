import React,{useEffect}from 'react';
import Navbar from './Navbar';
import { connect } from 'react-redux';
import TestDetails from './TestDetails';
import { GetTests } from '../../actions/Examination';
import {Link} from 'react-router-dom';
import Images from '../../assets/images';
import WrittenExam from './WrittenExam'

class Examination extends React.Component {
    constructor (props) {
		super(props);
        this.state = {
            type : "mock",
            testTypeData : [],
            onFileSelectError : "",
            onFileSelectSuccess : ""
        }
	}

    static getDerivedStateFromProps(props, state) {
        if(state.type == "mock"){
            return {
                type : "mock",
                testTypeData : props.tests.mock
            };
        }else if(state.type == "online"){
            return {
                type : "online",
                testTypeData : props.tests.online
            };
        }
        return null;
    }

    componentDidMount () {
		this.props.GetTests();
	}

    onTabChange = (tab) =>{
        this.setState({
            type : tab,
            testTypeData : this.props.tests[tab]
        })
    }

    uploadFile = (e) =>{
        const file = e.target.files[0];
       if(file.size < 1000000){
            this.setState({onFileSelectSuccess : "File can Upload", onFileSelectError : ""})
       }else{
            this.setState({onFileSelectError : "File size Excedes", onFileSelectSuccess : ""})
       }
    }

    render() {
		const {tests } = this.props;
        const {onFileSelectSuccess, onFileSelectError} = this.state;
        return (
            <div className="mt-2 mb-3">
                <nav area-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link to="/">Home</Link> </li>
                        <li className="breadcrumb-item active" area-current="page">Exams</li>
                    </ol>
                </nav>
           { this.props.is_auth ? 
               <>
               <Navbar onTabChange={this.onTabChange.bind(this)} tab={this.state.type}/>
                { this.state.type != "Written" ?
                <div className="row mb-3">
                    {
                        this.state.testTypeData && this.state.testTypeData.length > 0 &&  this.state.testTypeData.map((item,i) =>{
                            return <div key={i} className="col-lg-4 mt-4"><TestDetails TestData={item} type={this.state.type}/></div>
                        })
                    }
                    {
                        this.state.testTypeData && this.state.testTypeData.length <= 0 &&
                        <div className="row m-4 test-detail-container ">
                            <div className="p-5 col-12 d-flex justify-center align-items-center Q-num heading ">
                            <div><img src={Images.noResults} alt="No results" height="70" /> No Exams Available </div>
                            </div>
                        </div>
                    }
                </div>
                :
                <WrittenExam /> 
                }
                </>
                : 
                <div className="row m-4 test-detail-container ">
                    <div className="p-5 col-12 d-flex justify-center align-items-center Q-num heading ">
                    <div><img src={Images.noResults} alt="No results" height="70" />Please Login to attend the exams</div>
                    </div>
                </div>
           }

        </div>
        )
    }
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		main_loader: state.home.main_loader,
		tests : state.test.tests,
	}
}

const mapActionsToProps = {
	GetTests : GetTests
}
export default connect(mapStatesToProps, mapActionsToProps) (Examination);