import React, { useEffect, useCallback, useState } from 'react';
import './style.css'
import { connect,  useDispatch, useSelector  } from 'react-redux';
import {getAllGalleryImage} from '../../actions/Achievers';
import {BaseUrl} from '../../utils/ApiService';
import Modal from 'react-modal';
import $ from 'jquery';
import Images from '../../assets/images';


const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		width: '80%',
		// minHeight: '70vh',
		height: '80%',
		transform: 'translate(-50%, -45%)',
        overflow : 'hidden'
	},
  };

const ImageCollection = (props) => {

    const [imagePath, setImagePath] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const dispatch = useDispatch();
    const imageCollection = useSelector(state => state.achiever.imageCollection);
    const loadProducts = useCallback(() => {
        getAllGalleryImage()(dispatch);
    }, [dispatch, getAllGalleryImage]); 
    
    useEffect(()=>{
        loadProducts()
    },[])


    const openModal = (isModal, path) =>{
		if(isModal){
			$("body").css("overflow", "hidden")
		}else{
			$("body").css("overflow", "auto")
		}
        setIsModal(isModal);
        setImagePath(path);
	}

    return (
        <>
        <div className='image-collection'>
            {
                imageCollection.map(image =>{
                return <div key={image.category_id}>
                    <div className='mb-3'>
                    <h7 className=''>{image.category_name}</h7>
                        </div>
                    <div className='row d-flex justify-content-start'>
                    {
                        image.data.map(ele =>{
                            return <div className='col-lg-3 col-md-3 col-4  mb-4 text- imageCard' onClick={() =>openModal(true, BaseUrl+ele.gallery_image)}>
                                <div className='card'>
                                    <img src={`${BaseUrl+ele.gallery_image}`}/>
                                    <p className='title'>{ele.gallery_title}</p>
                                </div>
                                </div>
                        })
                    }
                    </div>
                </div>
                })
            }
        </div>
        <Modal
					isOpen={isModal}
					style={customStyles}
					contentLabel="Example Modal"
					ariaHideApp={false}
					size="lg"
				>
				<div className='d-flex justify-content-end'>
					<img src={Images.close}  height="15" onClick={() =>openModal(false, null)}/>
				</div>
                <div className='imagePath' style={{background : `url('${imagePath}') no-repeat center top `, backgroundSize : "contain"}}>
                    {/* <img src={imagePath} height={200} width={300} /> */}
                </div>
				
				</Modal>
        </>
    );
};

export default ImageCollection;