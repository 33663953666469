import React from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

class VideoPlayer extends React.Component {

	constructor (props) {
		super(props);
        const {params} = props.match;
		this.state = {videoID: params.videoID, activeIndex: 0};
	}

	render() {
        const {videoID} = this.state;

        const opts = {
			height: '500px',
			width: '100%',
			playerVars: {
				autoplay: 0
			}
		};

		return (
			<div className="container-fluid mt-3 mb-5">
				{/* Breadcrumbs and results counter container */}
				<div className="row">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Video Player</li>
							</ol>
						</nav>
					</div>
					<div className="col text-center">
						{/* <h4 className="header_title"> YouTube Video </h4> */}
					</div>
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						{/* <span className="small_text text-blue"> Showing {achievers.length} achievers.</span> */}
					</div>
				</div>

                <div className="row">
					<div className="col">
                        {(videoID && videoID !== '') &&
                            <YouTube
                                videoId={videoID}
                                opts={opts}
                            />
                        }
                    </div>
                </div>
			</div>
		);
	}
}

export default VideoPlayer;