import React from 'react';
import './NewsUpdates.css';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import {getNewsUpdates} from '../../actions/NewsUpdates';
import $ from 'jquery';
import Images from '../../assets/images';
import DateFormat from '../../utils/DateFormat';
import {BaseUrl} from '../../utils/ApiService';
import YouTube from 'react-youtube';

class NewsUpdates extends React.Component {

	constructor (props) {
		super(props);
		this.state = {activeNews: {}, activeIndex: 0};
		this.handleLoginClick = this.handleLoginClick.bind(this);
	}

	componentWillMount () {
		this.props.onGetNewsUpdates();
	}

	handleLoginClick = (e) => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}

	render() {

		const {sub_loader, news_updates } = this.props;
		var {activeNews, activeIndex} = this.state;

		if(activeIndex == 0 && news_updates.length > 0) {
			activeNews = news_updates[0];
		}

		const opts = {
			height: '250',
			width: window.innerWidth,
			playerVars: {
				autoplay: 0
			}
		};

		return (

			<div className="container-fluid data_container mt-2 mb-4">
				          
				{/* Breadcrumbs and results counter container */}
				<div className="row sort_container">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item active" area-current="page">News Updates</li>
							</ol>
						</nav>
					</div>
					<div className="col text-center">
						<h4 className="header_title"> News Updates </h4>
					</div>
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<span className="small_text text-blue"> Showing {news_updates.length} news updates.</span>
					</div>
				</div>

				{news_updates && news_updates.length > 0 ? 
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
							{
								sub_loader ? <div className="text-center"><img src={Images.subLoader} className="filter_loader" alt="Loader" /></div> :

								<div className="row item_container p-2 overflow-x-hidden">
									{
										news_updates.map((news, index) => (
											<div key={news.id} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 pb-0 d-flex" >
												<div className="card w-100">
													<div className="p-3">
														<h6 className="text-orange">{news.news_title} </h6>
														<div className='d-flex justify-content-between align-items-center'>
															<div><span className="small_text text-blue"> Posted : {DateFormat(news.created_at.slice(0,10))}</span></div>
															<div><button className='btn px-4 py-2 text-capitalize' onClick={() => this.setState({activeNews: news, activeIndex: index})}>View</button></div>
														</div>
													</div>
												</div>
											</div>
										))
									}
								</div>
							}
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-2">
							<div className="item_details_container card w-100 p-3 overflow-x-hidden">
								<h6 className="text-orange">{activeNews.news_title} </h6>
								<span className="small_text text-blue"> Posted : {DateFormat(activeNews.created_at.slice(0,10))}</span>
								<hr className="my-2" />

								{activeNews.file_type == '1' ?
									<YouTube
										videoId={activeNews.news_image_path}
										opts={opts}
										className='news_image'
									/>
								: <img src={BaseUrl + activeNews.news_image_path} className="img-thumbnail news_image" />
								}
								
								<p className="text-blue medium_text">{activeNews.news_description} </p>
							</div>
						</div>
					</div>
				:
					<div className="col text-center no_results_container">
						<img src={Images.noResults} alt="No results" height="100" /><br/><br/>
						<h6>No news available.</h6>
					</div>
				}
			</div>
		);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		sub_loader : state.home.sub_loader,
		news_updates : state.news.news_updates,
	}
}

const mapActionsToProps = {
	onGetNewsUpdates : getNewsUpdates
}

export default connect(mapStatesToProps, mapActionsToProps) (NewsUpdates);