import React, {useState} from 'react';
import './Contact.css';
import VisitIcon from '../../assets/images/icons/visit_us.png';
import ContactIcon from '../../assets/images/icons/contact_us.png';
import FollowIcon from '../../assets/images/icons/follow_us.png';
import {sendEnquiryEmail} from '../../actions/Cart'
import { connect,  useDispatch, useSelector  } from 'react-redux';

function validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

const ContactUs = () => {
    const [contactObject , setContactObject] = useState({
        fullName : "",
        emailId : "",
        mobileNumber : "",
        comments : "",
    })
    const [error, setError] = useState("")

    const dispatch = useDispatch();
    // const siteProgressImages = useSelector(state => state.site.siteProgressImages)

    const onchange = (e) =>{
        const { name, value } = e.target;
        setContactObject({...contactObject,
            [name] : value
       })
    }

    
    const onsubmit = async(e) =>{
        e.preventDefault()
        const {fullName, emailId, mobileNumber, comments} = contactObject;
        if(!fullName || fullName == "" || fullName.length < 5){
            setError("Full Name is required")
            return false;
        }else if(!emailId || emailId == "" || ! validateEmail(emailId)){
            setError("Email Id is required. Enter valid Email")
            return false;
        }else if(!mobileNumber || mobileNumber == "" || mobileNumber.length != 10){
            setError("Mobile Number is required, Mobile number should be 10 digit")
            return false;
        }else if(!comments || comments == "" || comments.length < 10){
            setError("Comments required. Enter at least 10 characters")
            return false;
        }else{
            setError("")
            const payload = {
                "contactName":fullName,
                "contactNumber":mobileNumber,
                "email": emailId,
                "comment": comments,
                "city_name":"",
                "state_name":"",
            }
            await sendEnquiryEmail(payload)(dispatch);
            setContactObject({
                fullName : "",
                emailId : "",
                mobileNumber : "",
                comments : "",
            })
        }
    }
    return (
        <div className="row mb-4" id="contact_container">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12">
                <div className="card mt-4 ml-3">
                    <div className="card-header p-0">
                        <h4 className="bg-blue text-light p-4 text-center mb-0">ANY QUERIES ?</h4>
                    </div>
                    <div className="card-body">
                        <form >
                            <div className="form-group">
                                <label className="small_text"> Full Name *</label>
                                <input type="text" name="fullName" value={contactObject.fullName} onChange={(e) => onchange(e)} className="form-control" placeholder="Full Name *" />
                            </div>

                            <div className="form-group">
                                <label className="small_text"> Email ID *</label>
                                <input type="email" name="emailId" value={contactObject.emailId} onChange={(e) => onchange(e)} className="form-control" placeholder="Email ID *" />
                            </div>

                            <div className="form-group">
                                <label className="small_text"> Mobile Number *</label>
                                <input type="number"  name="mobileNumber"   value={contactObject.mobileNumber} onChange={(e) => onchange(e)} className="form-control" placeholder="Mobile Number *" />
                            </div>

                            <div className="form-group">
                                <label className="small_text"> Comments *</label>
                                <textarea rows={3} name="comments" value={contactObject.comments} onChange={(e) => onchange(e)} className="form-control" placeholder="Comments *" />
                            </div>
                            <div className="pb-2">
                                <label className="small_text text-danger">{error}</label>
                            </div>
                            <div className="text-center">
                                <button type="button" className="login_btn py-2 px-4" onClick={(e) => onsubmit(e)}> SUBMIT <i className="fa fa-send ml-1"></i> </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="col-xl-8 col-lg-7 col-md-6 col-sm-6 col-12 text-center mt-2">
                <div className="my-4">
                    <h3 className="main_header"> CLASSIC KAS AND IAS STUDY CIRCLE </h3>
                    <h6 className="header"> Dharwad, Karnataka, India </h6>
                </div>
                <hr/>
            
                <div className="row">
                    {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"> */}
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  mt-2">
                        <div>
                            <img src={VisitIcon} alt="Visit Us" />
                            <h6 className="para text-blue" style={{lineHeight: 1.60}}> <i className="fa fa-map-marker mr-1"></i>  Classic Campus,<br/> 
                                Sharada School Road, Saptapur,<br/>
                                Dharwad, Karnataka 580001 <br/>
                            </h6>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  mt-2">
                        <div>
                            <img src={VisitIcon} alt="Visit Us" />
                            <h6 className="para text-blue" style={{lineHeight: 1.60}}> <i className="fa fa-map-marker mr-1"></i>  Unit B, Near Jayanagar, <br/> 
                                Dharwad, Karnataka 580001 <br/>
                            </h6>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  mt-2">
                        <div>
                            <img src={VisitIcon} alt="Visit Us" />
                            <h6 className="para text-blue" style={{lineHeight: 1.60}}> <i className="fa fa-map-marker mr-1"></i> Unit C, E- Square Building,<br/> 
                                Saptapur Bavi Circle,<br/>
                                Dharwad, Karnataka 580001 <br/>
                            </h6>
                        </div>
                    </div> */}

                    {/*<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  mt-2">
                        <div>
                            <img src={VisitIcon} alt="Visit Us" />
                            <h6 className="para text-blue" style={{lineHeight: 1.60}}> <i className="fa fa-map-marker mr-1"></i>R V Complex, Near Hosahalli Metro Station,<br/> 
                            4th Main, 3rd Cross Vijayanagar, <br/>
                            Bangalore - 560040 <br/>
                            </h6>
                        </div>
                    </div>*/}
                    {/*<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  mt-2">
                        <div>
                            <img src={VisitIcon} alt="Visit Us" />
                            <h6 className="para text-blue" style={{lineHeight: 1.60}}> <i className="fa fa-map-marker mr-1"></i>Kalaburagi,<br/> 
                            Plot No -6, Arihant Nagar, Sedam Road, University Road <br/>
                            Kalaburagi <br/>
                            </h6>
                        </div>
                    </div>*/}
                </div>
                <hr/>

                <div className="row mt-1">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div>
                            <img src={ContactIcon} alt="Contact Us" />
                            <h1 className="sub_header"> CONTACT US</h1>
                            <p className="para my-1" style={{fontSize: 16}}> <i className="fa fa-phone mr-1"></i> : 9980552080 / 9980552081 </p>
                            <p className="para my-1" style={{fontSize: 16}}><i className="fa fa-envelope mr-1"></i> : classicappdwd@gmail.com </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 mt-0 mt-sm-2">
                        <hr className="d-md-none" />
                        <div>
                            <img src={FollowIcon} alt="Follow Us" />
                            <h1 className="sub_header"> FOLLOW US</h1>
                            <ul className="social text-center">
                            <li> <a target="_blank" rel="noopener noreferrer" title="Facebook" href="https://www.facebook.com/classicedudwd"><i className="social_icon fa fa-facebook"></i></a> </li>
                            {/* <li> <a target="_blank" rel="noopener noreferrer" title="LinkedIn" href=""><i className="social_icon fa fa-linkedin"></i></a> </li> */}
                            <li> <a target="_blank" rel="noopener noreferrer" title="Instagram" href="https://www.instagram.com/classiceducationlive/"><i className="social_icon fa fa-instagram"></i></a> </li>
                            <li> <a target="_blank" rel="noopener noreferrer" title="Youtube" href="https://www.youtube.com/c/ClassicEducationDharwad/featured"><i className="social_icon fa fa-youtube"></i></a> </li>
                            <li> <a target="_blank" rel="noopener noreferrer" title="Telegram" href="https://t.me/classiceducationlive"><i className="social_icon fa fa-telegram"></i></a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr/>

                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"> */}
                <div className="row justify-content-center d-none" id="branch_offices">
                    <div className="col-md-12 col-12">
                        <h5 className="header"> We are located accross india </h5>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 branch_div">
                        <h5 className="sub_header"> Trivandrum, Kerala </h5>
                        <h6 className="header"> Mr. Rajesh - AIKYA </h6>
                        <p className="para"> <i className="fa fa-map-marker mr-1"></i> Menamthottam Chambers, First Floor, Opposite Pattom Royal Hotel, Pattom, Trivandrum, Kerala, India - 695004 <br/>
                            <strong><i className="fa fa-phone"></i> : 0471-4000630, 4000830, 9567556111 </strong>
                        </p>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 branch_div">
                        <h5 className="sub_header"> Guwahati, Assam </h5>
                        <h6 className="header"> Mr. Arshad, Mr. Hemant </h6>
                        <p className="para"> <i className="fa fa-map-marker mr-1"></i> No.21, SixMile, Panjabari Road, P.S. Dispur, P.O. Khanapara, Guwahati, Assam, India - 781022 <br/> Landmark : Near Darandha Maszid <br/>
                            <strong><i className="fa fa-phone"></i> : 0361-2332287, 7483800703 / 04 </strong>
                        </p>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 branch_div">
                        <h5 className="sub_header"> Secunderabad, Telangana </h5>
                        <h6 className="header"> Mr. John </h6>
                        <p className="para"> <i className="fa fa-map-marker mr-1"></i> Plot No.112, First Floor, Road No. 2, TMC Colony, Mahendra Hills, East Marredpally, Secundarabad, Telangana, India - 500026 <br/>
                            {/* <strong><i className="fa fa-phone"></i> : 0361-2332287, 7483800703 / 04 </strong> */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;