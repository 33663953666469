import React from 'react';
import './Cart.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import Images from '../../assets/images';
import {addRemovePublication,getCartPublication, getDeliveryAddress, addAddress, updateAddress} from '../../actions/Cart'
import {ApiService, ApiPaths, BaseUrl} from '../../utils/ApiService';
import swal from 'sweetalert';
import AppConstants from '../../assets/constants/AppConstants';
import Modal from 'react-modal';


const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		width: '80%',
		// minHeight: '70vh',
		height: '80%',
		transform: 'translate(-50%, -45%)',
	},
  };

const BookTypeText = {
	0 : "E Book",
	1 : "Publication",
	2 : "Subscription",
}
class Cart extends React.Component {

	constructor (props) {
		super(props);
		this.handleLoginClick = this.handleLoginClick.bind(this);
		this.state = {
			isDrawer : false,
			drawerType : "DETAILS",
			AddEdit : "ADD",
			EditId : "",
			selectedAddress : {},
			addressObject : {
				pincode : "",
				address : "",
				contact_person : "",
				contact_number: "",
				city_name : "",
				state_name : "",
				is_default : "YES"
			},
			error : "",
			selectedIndex : -1, 
			isModal : false
		}
	}

	onChange = (e) =>{
		const {name, value} = e.target;
		this.setState((Prev)=>({
			...Prev,
			addressObject : {...this.state.addressObject , [name] : value},
			error : ""
		})
		)
	}

	submitForm = async() =>{
		const {addressObject} =  this.state;
		if(addressObject.address && addressObject.city_name && addressObject.contact_number && addressObject.contact_person && addressObject.pincode){
			this.setState({ error : "", drawerType : "LIST"});
			if(addressObject.id){
				await this.props.updateAddress(addressObject);
			}else{
				await this.props.addAddress(addressObject);
			}
			this.props.getDeliveryAddress();
		}else{
			this.setState({ error : "Enter all the Fields"});
		}
	}

	close = (flag, type) =>{
		if(flag){
			$("html, body").animate({ scrollTop: 0 }, "slow");
			$("body").css("overflow", "hidden")
			$(".contents").addClass('overlays')
			this.setState({
				isDrawer : true,
				drawerType : type
			})
		}else if(!flag && type == "LIST"){
			$("html, body").animate({ scrollTop: 0 }, "slow");
			$("body").css("overflow", "hidden")
			$(".contents").removeClass('overlays')
			this.setState({
				isDrawer : true,
				drawerType : type
			})
		}else{
			$("html, body").animate({ scrollTop: 0 }, "slow");
			$("body").css("overflow", "auto")
			$(".contents").removeClass('overlays')
			this.setState({
				isDrawer : false,
				drawerType : type
			})
		}
	}
	componentWillMount () {
		this.props.getCartPublication();
		this.props.getDeliveryAddress();
	}

	componentWillUnmount(){
		$("body").css("overflow", "auto")
	}

	removingPublicationToCart = async(data) =>{
		const formData = new FormData();
		formData.append("book_id", data.book_id);
		await this.props.addRemovePublication(formData)
		await this.props.getCartPublication();
	}

	handleLoginClick = (e) => {
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}

	addressEdit = (address) =>{
		this.setState({
			addressObject : {
				pincode : address.pincode,
				address : address.address,
				contact_person : address.contact_person,
				contact_number: address.contact_number,
				city_name : address.city_name,
				state_name : address.state_name,
				is_default : address.is_default,
				id : address.id
			},
			AddEdit : "Edit",
			drawerType : "FORM"
		})
	}

	renderReadButton = (filePath) => {
		if (filePath && filePath !== '') {
			return (
				<Link to={'/pdf-reader/' + filePath.replace('/book_files/','')}>
					<button className="btn btn-primary btn-sm ml-0">READ</button>
				</Link>
			);
		} else {
			return <button className="btn btn-primary btn-sm ml-0" disabled={true}>READ</button>
		}
	};

	handleBatchRegister = () => {
		const {cartPublication,cartAmount } = this.props;
		const totalFees = cartAmount.TotalAmount + cartAmount.DeliveryCharge;
		const DeliveryCharge = cartAmount.DeliveryCharge;
		const {is_auth, auth_user} = this.props;
		const _selectedAddress = this.state.selectedAddress;

		const _cartPublication = cartPublication.map(_cart =>{
			_cart.order_delivery_address = JSON.stringify(_selectedAddress);
			_cart.order_quantity = _cart.book_quantity;
			_cart.order_delivery_charge = cartAmount.DeliveryCharge;
			_cart.order_amount = cartAmount.TotalAmount;
			return _cart;
		})

		if(is_auth && auth_user) {
			var formData = new FormData();
            formData.append('total_amount', totalFees);
			formData.append('order_delivery_charge', DeliveryCharge)
			swal({
			  title: "REGISTER",
			  text: "You need to pay Rs." + totalFees + " total amount",
			  buttons: ['CANCEL', 'Pay Now'],
			})
			.then(willDelete => {
				if (willDelete) {
					ApiService.post(ApiPaths.SAVE_CHECKOUT, formData)
					.then(response => {
						if(response.data.statuscode === 200)
						{
							// Initialize payment
							var options = {
								"key": AppConstants.RAZORPAY_API_KEY,
								// "amount": (totalFees * 100),
								"amount": totalFees,
								"name": 'Buying Books and Publication',
								"description": 'Classic Education Dharwad',
								"image": Images.logo1024,
								"order_id" : response.data.rzpay_order_id,
								"currency": 'INR',
								"theme" : {
									"color": "#004053"
								},
								"handler": function (response){
									if(response && response.razorpay_payment_id !== '') {
										// Make payment success in server
										var paymentFormData = new FormData();
										paymentFormData.append('rzpay_order_id', response.razorpay_order_id);
										paymentFormData.append('rzpay_payment_id', response.razorpay_payment_id);
										paymentFormData.append('rzpay_signature', response.razorpay_signature);
										paymentFormData.append('id', JSON.stringify(_cartPublication));
										// paymentFormData.append('order_quantity',  JSON.stringify(cartPublication));
										paymentFormData.append('order_delivery_address', JSON.stringify(_selectedAddress));
										paymentFormData.append('order_price', totalFees); //totalFees
										paymentFormData.append('order_delivery_charge', cartAmount.DeliveryCharge);
										paymentFormData.append('order_amount', cartAmount.TotalAmount);
										
										paymentFormData.append('payment_type', 2); // Type 2 for Cart
					
										ApiService.post(ApiPaths.PAYMENT_SUCCESS, paymentFormData)
										.then(response => {
											if(response.data.statuscode === 200) {
												swal({ title :'Thank you', text : 'Payment Success, Batch Registration completed', icon : 'success'});

												setTimeout(() => { window.location.reload(); }, 2000);
											}
										})
										.catch(error => {
											swal('OOPS!...', error.message);
										});
									}
								},
								"prefill" : {
									"contact" : auth_user.mobile_number,
									"email" : auth_user.email
								}
							}
							let rzp = new window.Razorpay(options); rzp.open();
						}
					})
					.catch(error => {
						swal('OOPS!...', error.message);
					});
			  	}
			});
		} else {
			this.handleLogin();
		}
	}
	renderPriceDetails = (book) =>{
		let _Price = 0;
		let Price_Text = "";
		if(book.book_type == 2){
			_Price = book.subscription_value;
			// Price_Text = "(" + book.subscription_value + " " + book.subscription_duration + " " + book.subscription_type+ ")"
			Price_Text = "(" +  parseFloat(book.subscription_value).toFixed(2) + " * "  + book.book_quantity +  ")" 
		}else{
			_Price = parseFloat(book.book_offer_price * book.book_quantity).toFixed(2)
			// Price_Text = "(" + parseFloat(book.book_offer_price).toFixed(2)  * book.book_quantity + ")"
			Price_Text = "(" +  parseFloat(book.book_offer_price).toFixed(2) + " * "  + book.book_quantity +  ")" 
		}
		return <div key={book.id} className='d-flex justify-content-between mb-3' style={{fontSize : "15px"}}>
		<div className='bookname' >{book.book_name}</div>
		{/* <div>( {parseFloat(book.book_offer_price).toFixed(2)} * {book.book_quantity}) {}</div> */}
		<div>{Price_Text}</div>
	</div>
	}
	renderEmptyView = () => {
		if (this.props.main_loader) {
			return null;
		} else {
			return (
				<div className="row">
					<div className="col text-center no_results_container">
						<img src={Images.noResults} alt="No results" height="100" /><br/><br/>
						<h6>No Cart Items .</h6>
					</div>
				</div>
			);
		}
	};

	
	addingPublicationToCart = async(data, add) =>{
		let qty = data.book_quantity;
		if(add){
			qty = qty + 1;
		}else{
			if(qty > 1)
			{
				qty = qty - 1;
			}else{
				qty = 0;
			}
		}
		const {is_auth, auth_user} = this.props;
		if(is_auth){
			const formData = new FormData();
			formData.append("book_id", data.book_id);
			formData.append("book_quantity", qty);
			formData.append('book_subscription_id', data.book_subscription_id);
			await this.props.addRemovePublication(formData);
			await this.props.getCartPublication();
		}else{
			swal('OOPS!... You need to login first');
			$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
		}
	}

	updateAddress = (type, e, address) =>{
		const {name, checked} = e.target;
		if(type == "is_default"){
			let _checked = "NO"
			if(checked == true){
				_checked = "YES"
			}
			this.props.updateAddress({...address, is_default : _checked, addressID : address.id })
			this.props.getDeliveryAddress();
		}
	}

	delivery = (selectedAddress) =>{
		this.setState({
			drawerType : "DETAILS",
			selectedAddress
		})
	}

	openModal = (type, isModal, index) =>{
		if(isModal){
			$("body").css("overflow", "hidden")
		}else{
			$("body").css("overflow", "auto")
		}
		this.setState({
			selectedType : type,
			selectedIndex : isModal ? index : -1,
			isModal : isModal
		})
	}

	render() {
		const {cartPublication, deliveryAddresses, cartAmount } = this.props;
		const {isDrawer, drawerType, error, AddEdit, addressObject, selectedAddress, selectedIndex, isModal} = this.state;
		const selectedType = "cartPublication"
		return (
			<>
			<div className="container-fluid data_container mt-2 mb-4 contents">          
				{/* Breadcrumbs and results counter container */}
				<div className="row sort_container">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Cart</li>
							</ol>
						</nav>
					</div>
				</div>
				
				<div class="tab-content" id="pills-tabContent">
					
					<div >
						{cartPublication && cartPublication.length > 0 ? 
							<div className="row d-flex justify-content-start p-2">
								{cartPublication.map((book, index) => (
									<div key={index} className="col-lg-3 col-md-4 col-6 px-3 mb-2 text-center" >
										<div className="py-1 px-4 imageDivv" onClick={() => this.openModal("books", true, index)}>
											<img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="img-fluid img-thumbnail" alt="" />
										</div>
										<p className="itemSubHeader my-1">{book.book_name}</p>
										<span className="itemDescription">{book.book_discription}</span>
										<span className="itemDescription">{BookTypeText[book.book_type]}</span>
										{
											book.book_type == "2" ?
											<>
											<span className="medium_text" style={{display : "block"}}>Price Rs {(book.subscription_value) +' for '+book.subscription_duration+' '+book.subscription_type}</span>
											<span className="medium_text">Total Rs {(book.subscription_value * book.book_quantity)}</span>
											</>
											:
											<>
											<span className="medium_text" style={{display : "block"}}>Price Rs {(book.book_offer_price !== '' && book.book_offer_price > 0) ? ('Rs. ' + book.book_offer_price) : 'FREE'}</span>
											<span className="medium_text">Total Rs {(book.book_offer_price * book.book_quantity)}</span>
											</>
										}
										
										<br/>
										{(book.isOrdered || book.book_offer_price <= 0) ?
											this.renderReadButton(book.book_file_path)
										:
											<div className="btn-sm ml-0 mx-5 d-flex justify-content-center align-self-center" >  
												{/* <img className="btn1" src={Images.minus} height="15" onClick={() => this.addingPublicationToCart(book)} />  */}
												<h3 className="btn1" onClick={() => this.addingPublicationToCart(book, false)}> - </h3>
												<h6 className='d-flex flex-column align-self-end font-weight-bold'>{book.book_quantity}</h6>
												{/* <img className="btn1" src={Images.add} height="15" onClick={() => this.addingPublicationToCart(book)} />  */}
												<h3 className="btn1" onClick={() => this.addingPublicationToCart(book, true)}> + </h3>
											</div>
											
												// <button className="btn btn-primary btn-sm ml-0" onClick={() => this.removingPublicationToCart(book)}>REMOVE</button>
											}
									</div>
									))
								}
							</div>
						:
							this.renderEmptyView()
						}
					</div>
					{
						cartPublication.length > 0 && 
					<div className="d-flex justify-content-center">
					{/* onClick={() => this.handleBatchRegister(1 ,1)} */}
						<button className="btn btn-danger" onClick={()=> this.close(true, "LIST")}>Make Payment</button>
					</div>
					}
				</div>

				
			</div>
			{
				isDrawer && <div className='drawer'>
					{drawerType == "FORM" && 
					<>
					<div className='header'>
						<div className='d-flex justify-content-between'>
							<div>
								<span>{AddEdit} Address</span>
							</div>
							<div>
								<img src={Images.close}  height="15" onClick={() => this.close(false, "LIST")}/>
							</div>
						</div>
					</div>
					<div className='body'>
						<div className='form'>
							<div>
								<input type="text" placeholder='Contact Person' className='form-control' value={addressObject.contact_person} name="contact_person" onChange={this.onChange} />
							</div>
							<div>
								<input type="text" placeholder='Contact Number' className='form-control' name="contact_number" value={addressObject.contact_number} onChange={this.onChange}/>
							</div>
							<div>
								<textarea type="textarea" rows={3} placeholder='Address' className='form-control' name="address" value={addressObject.address} onChange={this.onChange} />
							</div>
							<div>
								<input type="text" placeholder='City Name' className='form-control' name="city_name" value={addressObject.city_name} onChange={this.onChange}/>
							</div>
							<div>
								<input type="text" placeholder='State Name' className='form-control' name="state_name" value={addressObject.state_name} onChange={this.onChange} />
							</div>
							<div>
								<input type="text" placeholder='Pin code' className='form-control' name="pincode" value={addressObject.pincode} onChange={this.onChange}/>
							</div>
							{error && <div>
								<span className='p-3 text-danger'>{error}</span>
							</div>}
							<div className='d-flex justify-content-center'>
								<button className='btn btn-primary' onClick={this.submitForm}>SUBMIT</button>
							</div>
						</div>
					</div>
					</>
					}
					{
					drawerType == "LIST" && 					
					<>
					<div className='header'>
						<div className='d-flex justify-content-between'>
							<div>
								<span>SELECT ADDRESS</span>
							</div>
							<div>
								<img src={Images.close}  height="15" onClick={() => this.close(false, "")}/>
							</div>
						</div>
					</div>
					<div className='body'>
						<div className='new btn-primary d-flex justify-content-between' onClick={()=> this.setState({drawerType : "FORM"})}> 
						<div>New Address</div>
						<div> + </div>
						</div>

						<div className='content'>
							{
								deliveryAddresses.map(address =>{
									return <div key={address.id} className='d-flex justify-content-start mb-3'>
									<div><input type="radio" checked={address.is_default == "YES"} name="is_default" onChange={(e) => this.updateAddress("is_default", e, address)} /></div>
									<div>
										<p className='font-weight-bold'>{address.contact_person}</p>
										<p>{address.address}</p>
										<p>{address.city_name}</p>
										<p>{address.state_name} - {address.pincode}</p>
										<p>{address.contact_number}</p>
										<div className='d-flex justify-content-around'>
											<div><button className='btn btn-info' style={{padding: "10px 20px"}}  onClick={() =>this.delivery(address)}>Delivery Here</button></div>
											<div><button className='btn' onClick={() =>this.addressEdit(address)} style={{padding: "10px 20px"}}>Edit</button></div>
										</div>
									</div>
								</div>
								})
							}
							

						</div>
					</div>
					</>}
					{
					drawerType == "DETAILS" && 					
					<div className='details'>
					<div className='header'>
						<div className='d-flex justify-content-between'>
							<div>
								<span>ORDER SUMMERY</span>
							</div>
							<div>
								<img src={Images.close}  height="15" onClick={() => this.close(false, "")}/>
							</div>
						</div>
					</div>
					<div className='body'>
						<div className='content'>
							<div><span>Selected Delivery Address</span></div>
							<hr className='m-1' />
							<div key={selectedAddress.id} className='d-flex justify-content-start mb-3'>
								<div><input type="radio" checked={true} name="is_default" onChange={(e) => this.updateAddress("is_default", e, selectedAddress)} /></div>
								<div>
									<p className='font-weight-bold'>{selectedAddress.contact_person}</p>
									<p>{selectedAddress.address}</p>
									<p>{selectedAddress.city_name}</p>
									<p>{selectedAddress.state_name} - {selectedAddress.pincode}</p>
									<p>{selectedAddress.contact_number}</p>
								</div>
							</div>
						</div>
						<div className='content'>
							<div><span className='font-weight-bold'>Payment Details</span></div>
							<hr className='m-1' />
							{
								cartPublication.map(book =>{
									return this.renderPriceDetails(book)
								})
							}
							
							<div key={selectedAddress.id} className='d-flex justify-content-between mb-3'>
								<div >Delivery Charge</div>
								<div>{cartAmount.DeliveryCharge}</div>
							</div>

							<hr className='m-1' />
							<div key={selectedAddress.id} className='d-flex justify-content-between mb-3 font-weight-bold'>
								<div >Total Amount</div>
								<div>{parseFloat(cartAmount.TotalAmount + cartAmount.DeliveryCharge).toFixed(2)}</div>
							</div>
							 
						</div>
						<div className='d-flex justify-content-end mb-3'>
							<button className="btn btn-danger m-4" onClick={() => this.handleBatchRegister(1 ,1)} >Make Payment</button>
						</div>
					</div>
					</div>}
				</div>
			}

				<Modal
					isOpen={isModal}
					style={customStyles}
					contentLabel="Example Modal"
					ariaHideApp={false}
					size="lg"
				>
				<div className='d-flex justify-content-end'>
					<img src={Images.close}  height="15" onClick={() =>this.openModal("",false, -1)}/>
				</div>
				{ selectedIndex >= 0 &&  eval(selectedType).length > 0 && 
					<div  className='row bookModal'>
						<div className='col-6' style={{background : `url('${BaseUrl +  eval(selectedType)[selectedIndex].book_image_path}') no-repeat center top `, backgroundSize : "contain"}}>
							{/* <img src={eval(selectedType).length > 0 && BaseUrl +  eval(selectedType)[selectedIndex].book_image_path} className="img-fluid img-thumbnail" alt="" /> */}
						</div>
						<div className='col-6 d-flex flex-column justify-content-between'>
							<div>
								<p className="itemSubHeader my-1">{eval(selectedType)[selectedIndex].book_name}</p>
								<span className="medium_text font-weight-bold">{(eval(selectedType)[selectedIndex].book_price !== '' && eval(selectedType)[selectedIndex].book_price > 0) ? ('Rs. ' + eval(selectedType)[selectedIndex].book_price) : 'FREE'}</span>
								
							<div>
							<b>Description : </b>
								<pre className="itemDescription" style={{height : "300px"}}>{eval(selectedType)[selectedIndex].book_discription}</pre>
							</div>
							</div>
							
						</div>
					</div>
				}
				</Modal>
			</>

		);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth: state.home.is_auth,
		auth_user: state.home.auth_user,
		cartPublication : state.cart.cartPublication,
		cartAmount : state.cart.cartAmount,
		deliveryAddresses : state.cart.deliveryAddresses
	}
}

const mapActionsToProps = {
	getCartPublication : getCartPublication,
	addRemovePublication : addRemovePublication,
	getDeliveryAddress : getDeliveryAddress,
	addAddress : addAddress,
	updateAddress : updateAddress
}

export default connect(mapStatesToProps, mapActionsToProps) (Cart);




// <div>
// 								{(eval(selectedType)[selectedIndex].isOrdered || eval(selectedType)[selectedIndex].book_offer_price <= 0) ?
// 											this.renderReadButton(eval(selectedType)[selectedIndex].book_file_path)
// 										:
// 											<div className="btn-sm ml-0 mx-5 d-flex justify-content-center align-self-center" >  
// 												<h3 className="btn1" onClick={() => this.addingPublicationToCart(eval(selectedType)[selectedIndex], false)}> - </h3>
// 												<h6 className='d-flex flex-column align-self-end font-weight-bold'>{eval(selectedType)[selectedIndex].book_quantity}</h6>
// 												<h3 className="btn1" onClick={() => this.addingPublicationToCart(eval(selectedType)[selectedIndex], true)}> + </h3>
// 											</div>}</div> 