import {ApiPaths, ApiService, ApiService2} from '../utils/ApiService';
import {mainLoadingTrue, mainLoadingFalse, displayError,displayMessage, updateCartCount} from './Home';
import axios from 'axios';
export const ADD_PUBLICATION = 'ADD_PUBLICATION';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const CART_AMOUNT = 'CART_AMOUNT';
export const GET_DELIVERY_ADDRESS = 'GET_DELIVERY_ADDRESS';


export const addRemovePublication = (formData) => {
    return (dispatch) => {
        dispatch(mainLoadingTrue());

        return ApiService.post(ApiPaths.ADD_TO_CART, formData)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(updateCartCount(response.data.data));
                }else
                {
                    dispatch(displayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, Try again later'));
            });
    };
};

export const addAddress = (formData) => {
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService2.post(ApiPaths.ADD_ADDRESS, formData)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(displayMessage(response.data.message));
                }else
                {
                    dispatch(displayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, Try again later'));
            });
    };
};

export const updateAddress = (formData) => {
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService2.post(ApiPaths.UPDATE_ADDRESS, formData)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(displayMessage(response.data.message));
                }else
                {
                    dispatch(displayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, Try again later'));
            });
    };
};

export const getCartPublication = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_CART_DETAILS)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getCartPublicationSuccess(data.data.cart_data));
                    dispatch(updateCartCount(data.data.cart_data.length));
                    dispatch(cartAmount({
                        TotalAmount : data.data.cart_amount.total_amount,
                        DeliveryCharge : data.data.delivery_charge

                    }))
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
        })
    }
}

export const getDeliveryAddress = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.DELIVERY_ADDRESS)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getDeliveryAddressSuccess(data.data.deliveryAddress));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
        })
    }
}


export const sendEnquiryEmail = (formData) => {
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.SEND_ENQUIRY_EMAIL, formData)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(displayMessage(response.data.message));
                }else
                {
                    dispatch(displayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, Try again later'));
            });
    };
};

export const getCartPublicationSuccess = (data) => {
    return {
        type : GET_CART_SUCCESS,
        payload : data
    }
}
export const addPublication = (data) => {
    return {
        type : ADD_PUBLICATION,
        payload : {
            cartPublication: data
        }
    }
}

export const cartAmount = (data) => {
    return {
        type : CART_AMOUNT,
        payload : data
    }
}


export const getDeliveryAddressSuccess = (data) => {
    return {
        type : GET_DELIVERY_ADDRESS,
        payload : data
    }
}