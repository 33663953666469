import React, { useEffect, useState } from 'react';
import QuestionButton from './QuestionButton';
import './TestDetails.css';
import './QuestionButton.css';
import { useHistory } from 'react-router-dom';
import {PostQuestions} from '../../actions/Examination';
import { connect } from 'react-redux';
import $ from 'jquery';
import {BaseUrl} from '../../utils/ApiService'


const Questions = (props) => {
    const [questions, setQuestions] = useState([])
    const [activeButton, setActiveButton] = useState(1)
    const [activeIndex, setActiveIndex] = useState(0)
    const [selectedValue, setSelectedValue] = useState(0)
    const [startTime, setStartTime] = useState(new Date())
    
    const history = useHistory();

    useEffect(()=>{
        if(props.questionData && props.questionData.length >0){
            setQuestions(props.questionData)
        }
        
    },[props])

    const CheckScore = (data) =>{
        let wrong_answers = 0;
        let correct_answers = 0;
        let total_questions = 0;
        let answered_questions = 0;
        data.length > 0 && data.map((item, i)=>{
          if(item.answer == item.selected){
            correct_answers +=1;
          }else{
            wrong_answers +=1;
          }
          total_questions +=1;
          answered_questions +=1;
        })
        return{
          wrong_answers,correct_answers, total_questions, answered_questions
        }
    }
    const onChange = (event) =>{
        let _questions = questions;
        _questions[activeIndex]['selected'] = event.target.value;
        setQuestions([..._questions]);
        setSelectedValue(event.target.value)
    }

    const onQuestionClick = (index) =>{
        setActiveButton(index + 1)
        setActiveIndex(index)
    }

    const onClickNextPrev = (move, Id, e) =>{
        e.preventDefault()
        let temp = questions;
        temp.map((item,i)=>{
            if(item.id == Id){
                // item.selectedIndex = 
                item.selected = parseInt(selectedValue);
                return item
            }else{
                return item
            }
        })
        setQuestions(temp);
        setSelectedValue(0);
        if(move){
            setActiveButton(activeButton + 1)
            setActiveIndex(activeIndex + 1)
        }else{
            if(activeButton >1 ) 
                {setActiveButton(activeButton - 1)}
            if(activeIndex > 0 ) 
                {setActiveIndex(activeIndex - 1)}
        }
        if($("input:radio[name=group1]:checked")[0]){
            $("input:radio[name=group1]:checked")[0].checked = false;
        }
        return false;
    }

    const submitAnswere = (Id,e) =>{
        e.preventDefault()
       if(activeIndex < questions.length - 1 ){
        let temp = questions;
        temp.map((item,i)=>{
            if(item.id == Id){
                item.selected = selectedValue;
                return item
            }else{
                return item
            }
        })
        setQuestions(temp)
        setSelectedValue(0)
        setActiveButton(activeButton + 1)
        setActiveIndex(activeIndex + 1)
        $("input:radio[name=group1]:checked")[0].checked = false;
        return false;
       }else{
            let temp = questions;
            temp.map((item,i)=>{
            if(item.id == Id){
                item.selected = selectedValue;
                return item
            }else{
                return item
            }
        })
           const result = CheckScore(temp)
           debugger;
           let date1 = startTime
            let date2 = new Date()
            let time = (date2.getTime()/ 1000) - date1.getTime()/ 1000
            var hours = Math.floor(time / 3600);
            var minutes = Math.floor((time - hours * 3600) / 60);
            var seconds = (time - (hours * 3600) -  (minutes * 60)).toString().substring(0, 2)
            const timeTaken = `${hours}:${minutes}:${seconds}`
           const formData = new FormData()
            formData.append('test_id', props.testId);
            formData.append('test_completed_time', timeTaken);
            formData.append('wrong_answers', result.wrong_answers);
            formData.append('correct_answers', result.correct_answers);
            formData.append('total_questions', result.total_questions);
            formData.append('result',1)
           props.PostQuestions(formData)
           history.push({
               pathname : '/Result',
               state : {
                   testData : temp,
                    finalResult : result
               }
           })
           return false;
       }
    }
    return (
        <>
        {questions.length > 0 && 
            <div className="container-fluid">
                <div className="col-lg-12 test-detail-container mt-4 mb-4 ">

                    <div className="button-group">
                       
                        {questions.length > 0 && questions.map((item, i) => {
                        return  <div className="single-button"> <button onClick={() => onQuestionClick(i)} key={item.id} className={ i+1 == activeButton ? 'que-btn active': item['selected'] ? 'suc-btn' : 'que-btn' }>{i + 1}</button></div>
                        })}
                        
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-1 Q-num heading ">
                            Q.{activeButton}
                        </div>
                        <div className="col-lg-11 text-justify questions-div" >
                            {
                                questions[activeIndex].question && <pre className='question-para'>
                                {questions[activeIndex].question}   
                                </pre>
                            }
                            {
                                questions[activeIndex].question_image && <a target={'_blank'} href={BaseUrl + questions[activeIndex].question_image}>
                                    <img src={BaseUrl + questions[activeIndex].question_image}/>
                                </a>
                            }
                            
                            { questions[activeIndex].answer_type == "0" && <div onChange={onChange} className="pt-3">
                                <form>
                                    <div className="pb-3">
                                        <input type="radio" value={1} name="group1" checked={questions[activeIndex].selected == 1} />
                                        <label>
                                        &nbsp; A. {questions[activeIndex].option1}   
                                        </label>
                                    </div >
                                    <div className="pb-3">
                                        <input type="radio" value={2} name="group1" checked={questions[activeIndex].selected == 2}/>
                                        <label class="form-check-label">
                                        &nbsp; B. {questions[activeIndex].option2}   
                                        </label>
                                    </div>
                                    <div className="pb-3">
                                        <input type="radio" value={3} name="group1" checked={questions[activeIndex].selected == 3}/>
                                        <label class="form-check-label">
                                        &nbsp; C. {questions[activeIndex].option3}   
                                        </label>
                                    </div>
                                    <div className="pb-3">
                                        <input type="radio" value={4} name="group1" checked={questions[activeIndex].selected== 4}/>
                                        <label class="form-check-label">
                                        &nbsp; D. {questions[activeIndex].option4}   
                                        </label>
                                    </div>
                                   
                                   {questions[activeIndex].option5 && 
                                       <div className="pb-3">
                                       <input type="radio" value={5} name="group1" checked={questions[activeIndex].selected== 5}/>
                                       <label class="form-check-label">
                                       &nbsp; E. {questions[activeIndex].option5}   
                                       </label>
                                   </div>
                                   }
                                </form>
                            </div>}

                            { questions[activeIndex].answer_type == "1" && <div onChange={onChange} className="pt-3">
                                <form>
                                    <div className="pb-3">
                                        <input type="radio" value={1} name="group1" checked={questions[activeIndex].selected == 1} />
                                        <label>
                                        &nbsp; A. <img src={BaseUrl + questions[activeIndex].option1} />   
                                        </label>
                                    </div >
                                    <div className="pb-3">
                                        <input type="radio" value={2} name="group1" checked={questions[activeIndex].selected == 2}/>
                                        <label class="form-check-label">
                                        &nbsp; B. <img src={BaseUrl + questions[activeIndex].option2}  />
                                        </label>
                                    </div>
                                    <div className="pb-3">
                                        <input type="radio" value={3} name="group1" checked={questions[activeIndex].selected == 3}/>
                                        <label class="form-check-label">
                                        &nbsp; C. <img src={BaseUrl + questions[activeIndex].option3}  />  
                                        </label>
                                    </div>
                                    <div className="pb-3">
                                        <input type="radio" value={4} name="group1" checked={questions[activeIndex].selected== 4}/>
                                        <label class="form-check-label">
                                        &nbsp; D.  <img src={BaseUrl + questions[activeIndex].option4}  />    
                                        </label>
                                    </div>
                                   
                                   {questions[activeIndex].option5 && 
                                       <div className="pb-3">
                                       <input type="radio" value={5} name="group1" checked={questions[activeIndex].selected== 5}/>
                                       <label class="form-check-label">
                                       &nbsp; E. <img src={BaseUrl + questions[activeIndex].option5} height={"40"} />    
                                       </label>
                                   </div>
                                   }
                                </form>
                            </div>}

                        </div>
                                

                    </div>
                        <div className="pt-3 d-flex justify-content-between pb-3">
                            {<div> <button className="rounded-pill buy-btn" onClick={onClickNextPrev.bind(this,false,questions[activeIndex].id)} disabled={activeButton <= 1}>Prev</button></div>}
                           { activeIndex < questions.length - 1 && <div><button className="rounded-pill buy-btn" onClick={onClickNextPrev.bind(this,true,questions[activeIndex].id)}>Next</button></div>}
                           { activeIndex >= questions.length - 1 && <button className="rounded-pill buy-btn" onClick={submitAnswere.bind(this,questions[activeIndex].id)}>Submit</button>}
                        </div>
                </div>
            </div>
    }
        </>
    )
}

const mapActionsToProps = {
	PostQuestions : PostQuestions
}
export default connect(null, mapActionsToProps) (Questions);
