import { GET_COURSES_SUCCESS, GET_COURSE_SUCCESS, GET_BATCHES_SUCCESS, GET_CLASSES_SUCCESS } from '../actions/Courses';

const initialState = {
    courses: [],
    course: {},
    batches: [],
    classes: [],
}

const Course_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_COURSES_SUCCESS:
            return Object.assign({}, state, { courses: payload.courses });

        case GET_COURSE_SUCCESS:
            return Object.assign({}, state, { course: payload.course });

        case GET_BATCHES_SUCCESS:
            return Object.assign({}, state, { batches: payload.batches });

        case GET_CLASSES_SUCCESS:
            return Object.assign({}, state, { classes: payload.classes });

        default:  
            return state;
    }
}

export default Course_reducer;