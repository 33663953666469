import React from 'react';
import './Books.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import Images from '../../assets/images';
import {BaseUrl} from '../../utils/ApiService';
import { getBooks } from '../../actions/Books';
import {addRemovePublication} from '../../actions/Cart'
import Modal from 'react-modal';
import swal from 'sweetalert';

import {CalendarClockOutline} from 'material-design-icons'


const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		width: '80%',
		// minHeight: '70vh',
		height: '80%',
		transform: 'translate(-50%, -45%)',
	},
  };
  const SubcustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		width: '45%',
		// minHeight: '70vh',
		height: '80%',
		transform: 'translate(-50%, -45%)',
		background : "#efefef"
	},
  };

class Books extends React.Component {

	constructor (props) {
		super(props);
		this.state = {bookType: 1, selectedType : "books" , selectedIndex : -1, isModal : false, showPlans : false, selectedTariff : 0};
		this.handleLoginClick = this.handleLoginClick.bind(this);
	}

	componentWillMount () {
		this.props.onGetBooks();
	}

	addingPublicationToCart = async(data, qty) =>{
		const {is_auth, auth_user} = this.props;
		const _selectedTariff = data.book_type == 2 ? this.state.selectedTariff : 0;
		if(is_auth){
			const formData = new FormData();
			formData.append("book_id", data.id);
			formData.append("book_quantity", qty);
			formData.append("book_subscription_id", _selectedTariff);
			this.setState({
				selectedIndex : -1,
				isModal : false,
				showPlans : false,
				selectedTariff : 0
			})
			await this.props.addRemovePublication(formData)
			await this.props.onGetBooks();
			$("body").css("overflow", "auto")
		}else{
			swal('OOPS!... You need to login first');
			$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
		}
	}

	removingPublicationToCart = (data) =>{
		this.props.removePublication(data)
	}

	handleLoginClick = (e) => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}

	renderReadButton = (filePath) => {
		if (filePath && filePath !== '') {
			return (
				<Link to={'/pdf-reader/' + filePath.replace('/book_files/','')}>
					<button className="btn btn-primary btn-sm ml-0">READ</button>
				</Link>
			);
		} else {
			return <button className="btn btn-primary btn-sm ml-0" disabled={true}>READ</button>
		}
	};

	renderEmptyView = () => {
		if (this.props.main_loader) {
			return null;
		} else {
			return (
				<div className="row">
					<div className="col text-center no_results_container">
						<img src={Images.noResults} alt="No results" height="100" /><br/><br/>
						<h6>No books available.</h6>
					</div>
				</div>
			);
		}
	};

	onChangeValue = (e) =>{
		this.setState({selectedTariff : e.target.value})
	}

	openModal = (type, isModal, index) =>{
		if(isModal){
			$("body").css("overflow", "hidden")
		}else{
			$("body").css("overflow", "auto")
		}
		this.setState({
			selectedType : type,
			selectedIndex : isModal ? index : -1,
			isModal : isModal
		})
	}
	openSubscriptionModal = (type, isModal, index) =>{
		if(type == "subscriptions"){
			this.setState({
				selectedType : type,
				selectedIndex : isModal ? index : -1,
				selectedTariff : 0,
				showPlans : isModal
			})
		}
	}

	render() {
		const {books, publications, subscriptions } = this.props;
		const {bookType, selectedType, selectedIndex, isModal, showPlans} = this.state;

		var booksData = parseInt(bookType) === 1 ? books : publications;
		return (
			<div className="container-fluid data_container mt-2 mb-4">          
				{/* Breadcrumbs and results counter container */}
				<div className="row sort_container">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Books</li>
							</ol>
						</nav>

						{/* <div className="form-group">
							<select className="form-control" value={bookType} onChange={(e) => this.setState({bookType: e.target.value})}>
								<option value={1}>E-Books</option>
								<option value={2}>Publications</option>
							</select>
							
						</div> */}
					</div>
					{/* <div className="col text-center">
						<h4 className="header_title"> Books </h4>
					</div>
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<span className="small_text text-blue"> Showing {books.length} books.</span>
					</div> */}
				</div>

				<ul class="nav nav-pills mb-3 navbar-nav BP-nav" id="pills-tab" role="tablist">
					<li class="nav-item">
						<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">E-Books</a>
					</li>
					<li class="nav-item" >
						<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Publications</a>
					</li>
					<li class="nav-item" >
						<a class="nav-link" id="pills-subscription-tab" data-toggle="pill" href="#pills-subscription" role="tab" aria-controls="pills-subscription" aria-selected="false">Subscriptions</a>
					</li>
				</ul>
				
				<div class="tab-content" id="pills-tabContent">
					<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
						{books && books.length > 0 ? 
							<div className="row d-flex justify-content-start p-2">
								{books.map((book, index) => (
									<div key={index} className="col-lg-3 col-md-3 col-4 px-3 mb-4 text-center book-div" >
										<div onClick={() => this.openModal("books", true, index)}
										>
											<div className="py-1 px-4 imageDivv">
												<img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="img-fluid img-thumbnail" alt="" />
											</div>
											<div>
												<p className="itemSubHeader my-1">{book.book_name}</p>
												<span className="itemDescription font-weight-bold">{book.book_discription}</span>
												<span className="medium_text">{(book.book_offer_price !== '' && book.book_offer_price > 0) ? ('Rs. ' + book.book_offer_price) : 'FREE'}</span>
												<br/>
												{(book.book_price !== '' && book.book_price > 0) && <s className="medium_text">Rs {book.book_price}</s> }
												<br/>
											</div>
										</div>
										<div>
											{(book.isOrdered || book.book_price <= 0) ?
												this.renderReadButton(book.book_file_path)
											:
											book.isInCart ?
											<button className="btn btn-primary btn-sm ml-0" onClick={() => this.addingPublicationToCart(book, 0)}>REMOVE</button>
												:
											<button className="btn btn-primary btn-sm ml-0" onClick={() => this.addingPublicationToCart(book, 1)}>ADD TO CART</button>
											}
										</div>
										
									</div>
									))
								}
							</div>
						:
							this.renderEmptyView()
						}
					</div>
					<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
						{publications && publications.length > 0 ? 
							<div className="row d-flex justify-content-start p-2">
								{publications.map((book, index) => (
									<div key={index} className="col-lg-3 col-md-3 col-4 px-3 mb-2 text-center" >
										<div onClick={() => this.openModal("publications", true, index)}>
										<div className="py-1 px-4 imageDivv">
											<img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="img-fluid img-thumbnail" alt="" />
										</div>
										<p className="itemSubHeader my-1">{book.book_name}</p>
										<span className="itemDescription font-weight-bold">{book.book_discription}</span>
										<span className="medium_text">{(book.book_offer_price !== '' && book.book_offer_price > 0) ? ('Rs. ' + book.book_offer_price) : 'FREE'}</span>
										<br/>
										{(book.book_price !== '' && book.book_price > 0) && <s className="medium_text">Rs {book.book_price}</s> }
										<br/>
										</div>
										{(book.isOrdered || book.book_price <= 0) ?
											this.renderReadButton(book.book_file_path)
										:
											book.isInCart ?
												<button className="btn btn-primary btn-sm ml-0" onClick={() => this.addingPublicationToCart(book, 0)}>REMOVE</button>
											:
												<button className="btn btn-primary btn-sm ml-0" onClick={() => this.addingPublicationToCart(book, 1)}>ADD TO CART</button>
										}
									</div>
									))
								}
							</div>
						:
							this.renderEmptyView()
						}
					</div>
					<div class="tab-pane fade" id="pills-subscription" role="tabpanel" aria-labelledby="pills-subscription-tab">
						{subscriptions && subscriptions.length > 0 ? 
							<div className="row d-flex justify-content-start p-2">
								{subscriptions.map((book, index) => (
									<div key={index} className="col-lg-3 col-md-3 col-4 px-3 mb-2 text-center" >
										<div onClick={() => this.openModal("subscriptions", true, index)}>
										<div className="py-1 px-4 imageDivv">
											<img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="img-fluid img-thumbnail" alt="" />
										</div>
										<p className="itemSubHeader my-1">{book.book_name}</p>
										<span className="itemDescription font-weight-bold">{book.book_discription}</span>
										<span className="medium_text">{(book.book_offer_price !== '' && book.book_offer_price > 0) ? ('Rs. ' + book.book_offer_price) : 'FREE'}</span>
										<br/>
										{(book.book_price !== '' && book.book_price > 0) && <s className="medium_text">Rs {book.book_price}</s> }
										<br/>
										</div>
										{(book.isOrdered || book.book_price <= 0) ?
											this.renderReadButton(book.book_file_path)
										:
											book.isInCart ?
												<button className="btn btn-primary btn-sm ml-0" onClick={() => this.addingPublicationToCart(book, 0)}>REMOVE</button>
											:
												<button className="btn btn-primary btn-sm ml-0" onClick={() => this.openSubscriptionModal("subscriptions", true, index)}>ADD TO CART</button>
										}
									</div>
									))
								}
							</div>
						:
							this.renderEmptyView()
						}
					</div>
				</div>


				{isModal && <Modal
					isOpen={isModal}
					style={customStyles}
					contentLabel="Example Modal"
					ariaHideApp={false}
					size="lg"
				>
				<div className='d-flex justify-content-end'>
					<img src={Images.close}  height="15" onClick={() =>this.openModal("",false, -1)}/>
				</div>
				{ selectedIndex >= 0 &&  eval(selectedType).length > 0 && 
					<div  className='row bookModal'>
						<div className='col-6' style={{background : `url('${BaseUrl +  eval(selectedType)[selectedIndex].book_image_path}') no-repeat center top `, backgroundSize : "contain"}}>
							{/* <img src={eval(selectedType).length > 0 && BaseUrl +  eval(selectedType)[selectedIndex].book_image_path} className="img-fluid img-thumbnail" alt="" /> */}
						</div>
						<div className='col-6 d-flex flex-column justify-content-between'>
							<div>
								<p className="itemSubHeader my-1">{eval(selectedType)[selectedIndex].book_name}</p>
								<span className="medium_text font-weight-bold">{(eval(selectedType)[selectedIndex].book_price !== '' && eval(selectedType)[selectedIndex].book_price > 0) ? ('Rs. ' + eval(selectedType)[selectedIndex].book_price) : 'FREE'}</span>
							<div>
							{
								(eval(selectedType)[selectedIndex].book_price !== '' && eval(selectedType)[selectedIndex].book_price > 0) && (! eval(selectedType)[selectedIndex].isOrdered) &&
								<>
									{eval(selectedType)[selectedIndex].isInCart  ?
									<button className="btn btn-primary btn-sm ml-0" onClick={() => this.addingPublicationToCart(eval(selectedType)[selectedIndex], 0)}>REMOVE</button>
									:
									eval(selectedType)[selectedIndex].book_type == "2" ? 
									<button className="btn btn-primary btn-sm ml-0" onClick={() => this.openSubscriptionModal("subscriptions", true, selectedIndex)}>ADD TO CART</button>
									:
									<button className="btn btn-primary btn-sm ml-0" onClick={() => this.addingPublicationToCart(eval(selectedType)[selectedIndex], 1)}>ADD TO CART</button>
									}
								</>
							}
							</div> 
							<p className='itemSubHeader'>Description : </p>
								<pre className="itemDescription font-weight-bold" style={{height : "300px"}}>{eval(selectedType)[selectedIndex].book_discription}</pre>
							</div>
							
						</div>
					</div>
				}
				</Modal>}

				{
					showPlans && <Modal
					isOpen={showPlans}
					style={SubcustomStyles}
					contentLabel="Example Modal"
					ariaHideApp={false}
					size="md"
				>
					<div className='subscription-modal'>
						<div className='flex-between'>
							<div className='tex-header'>Subscription Details</div>
							<div ><img src={Images.close}  height="15" onClick={() =>this.openSubscriptionModal("subscriptions",false, -1)}/></div>
						</div>
						<div className='card-list'>
							{
								eval(selectedType)[selectedIndex].tariff.map(item =>{
									return <div className='modal-card'>
										{/* <div className='tex-header'>{item.subscription_duration + " - " + item.subscription_type} </div> */}
										<div className='flex' style={{flexGrow : "2"}}>
											<div className='modal-radio'>
												<input type="radio" value={item.id}  checked={this.state.selectedTariff == item.id} name="tariff" onChange={this.onChangeValue}/>
											</div>
											<div style={{flexGrow : "2"}}>
												<div className='card-price'>
													<div>Book Unit Price</div>
													<div>{eval(selectedType)[selectedIndex].book_price}</div>
												</div>
												<div className='card-price'>
													<div>Subscription Type</div>
													<div>{item.subscription_type}</div>
												</div>
												<div className='card-price'>
													<div>Subscription Duration</div>
													<div>{item.subscription_duration}</div>
												</div>
												<div className='card-price'>
													<div> Price</div>
													<div>
														<span className='actual'>₹ {item.subscription_value}</span>
														<strike className='offer'>{item.subscription_duration * eval(selectedType)[selectedIndex].book_price}</strike>
													</div>
												</div>
												{/* <div className='card-price'>
													<div>Offer Price</div>
													<div>{item.subscription_value}</div>
												</div> */}
											</div>
										</div>
									</div>
								})
							}
						</div>
						
						
						<div className='Proceed'>
							<button onClick={() => this.addingPublicationToCart(eval(selectedType)[selectedIndex], 1)} disabled={this.state.selectedTariff == 0}> Proceed </button>
						</div>
					</div>
				</Modal>
				}

			</div>

		);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		main_loader: state.home.main_loader,
		books : state.book.books,
		publications: state.book.publications, 
		subscriptions: state.book.subscriptions,
	}
}

const mapActionsToProps = {
	onGetBooks : getBooks,
	addRemovePublication : addRemovePublication
}

export default connect(mapStatesToProps, mapActionsToProps) (Books);


							// <div className="btn-sm ml-0 mx-5 d-flex justify-content-center align-self-center" >  
												// 	{/* <img className="btn1" src={Images.minus} height="15" onClick={() => this.addingPublicationToCart(book)} />  */}
												// 	<h3 className="btn1" onClick={() => this.addingPublicationToCart(book)}> - </h3>
												// 	<h6 className='d-flex flex-column align-self-end font-weight-bold'>1</h6>
												// 	{/* <img className="btn1" src={Images.add} height="15" onClick={() => this.addingPublicationToCart(book)} />  */}
												// 	<h3 className="btn1" onClick={() => this.addingPublicationToCart(book)}> + </h3>
												// </div>