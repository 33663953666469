import React from 'react';

const formatCurrency = (amount, currency) => {
    if(currency === "Rupees") {
        return amount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    } else {
        return amount.toString().replace(/(\d)(?=(\d\d\d)+$)/g, "$1,");
    }
};

const CurrencyFormat = (props) => {
    const currency = "Rupees";

    return (
        <span>
            <i className={"fa " + (currency === "Rupees" ? "fa-rupee" : "fa-dollar") + " mr-1"}></i>
            {formatCurrency(props.amount, currency)}
        </span>
    )
}

export default CurrencyFormat;
