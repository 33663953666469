import React from 'react';
import './Navbar.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { NavLink, Link} from 'react-router-dom';

const Navbar = (props) => {
    const {onTabChange, tab} = props;
    return (
        <div className="col-lg-12 col-md-12 col-sm-12 aboutpage-nav2">
            <nav className="p-0 mt-3 navbar navbar-expand-lg navbar-light d-flex">

                {/* <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button> */}
                {/* <div className="collapse navbar-collapse" id="navbarSupportedContent2"> */}
                    <ul className="navbar-nav  py-0 pr-5 navbar-light nav-ul d-flex justify-content-around flex-row">
        
                            <li className="nav-item">
                                <a className={tab == "mock" ? "nav-link active pt-0" : "nav-link pt-0"} to="#mock"  onClick={()=> onTabChange("mock")}>Mock Exam</a>
                            </li>
                    
                            <li className="nav-item">
                                <a className={tab == "online" ? "nav-link active pt-0" : "nav-link pt-0"} to="#Online"  onClick={()=>  onTabChange("online")}>Online Exam</a>
                            </li>
                            <li className="nav-item">
                                <a className={tab == "offline" ? "nav-link active pt-0" : "nav-link pt-0"} to="#Offline"  onClick={()=>  onTabChange("offline")}>Offline Exam</a>
                            </li> 
                            <li className="nav-item">
                                <a className={tab == "Written" ? "nav-link active pt-0" : "nav-link pt-0"} to="#Written"  onClick={()=>  onTabChange("Written")}>Written Exam</a>
                            </li>
                    </ul>
                {/* </div> */}
            </nav>
        </div>

        

    )
}

export default Navbar
