const TimeFormat = (time) => {
    if (time !== ' ' && time !== undefined) {

        var timeFormats = time.split(':')
        var hour = timeFormats[0] % 12;
        var minutes = timeFormats[1];
        var type = (timeFormats[0] >= 0 && timeFormats[0] < 12) ? 'am' : 'pm';

        return (
            hour +
            ':' +
            minutes +
            ' ' +
            type
        );
    } else {
        return null;
    }
};
  
export default TimeFormat;
