import React from 'react';
import './EditProfile.css';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { displayError, sendForgotPasswordOTP, updateNewPassword } from '../../actions/Home';

class ForgotPassword extends React.Component {

    constructor (props) {
        super(props);
        this.state = { mobile : '', otp : '', pwd : '', conf_pwd : '', showPwd: false};
    }
    
    handleSendOTP = (e) => {
		e.preventDefault();

        var formData = new FormData(document.getElementById('send_otp_form'));
        this.props.onSendOTP(formData);
    }
    
	handleUpdatePassword = (e) => {
        e.preventDefault();
        
        const { otp, pwd, conf_pwd } = this.state;

        if(otp.length !== 4)
		{
		  this.props.onDisplayError('OTP should have 4 numbers.');
		}
		else if(pwd.length < 8)
		{
		  this.props.onDisplayError('Password must have atleast 8 characters');
		}
		else if(pwd !== conf_pwd)
		{
		  this.props.onDisplayError('Confirm password must be same as password');
		}
		else
		{
			swal({
				title: "Are you sure?",
				text: "To update your new password?",
				buttons: ['Cancel', 'Update'],
			})
			.then(willRequest => {
				if (willRequest) {
					var formData = new FormData(document.getElementById('update_password_form'));
					this.props.onUpdatePassword(formData);
				}
			});
		}
	}

	render () {
        
        const { status } = this.props;
        const { mobile, otp, pwd, conf_pwd, showPwd } = this.state;

		return (

			<div className="row mb-4">
				<div className="col">
				
                    <div className="edit_profile_container text-center mt-5">
						<h2 className="sub_header mb-4"><i className="fa fa-lock mr-1"></i> FORGOT PASSWORD </h2>

                        <div className="row">
                            <div className="col"></div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="change_password">

                                   {
                                       status === 'OTP sent' ?

                                            <form id="update_password_form" onSubmit={this.handleUpdatePassword} autoComplete="off">

                                                <div className="form-group">
                                                    <h5 className="text-blue"><i className="fa fa-check-circle text-success"></i> {mobile} </h5>
                                                    <input type="hidden" name="mobile_number" defaultValue={mobile} />
                                                </div>

                                                <div className="form-group">
                                                    <label className="small_text"> Enter OTP * : </label>
                                                    <input type="number" name="otp" className="form-control" placeholder="Enter OTP *" value={otp} onChange={(e) => this.setState({ otp : e.target.value})} required />
                                                </div>

                                                <i style={{cursor: 'pointer'}} className={"show_hide_pwd fa " + (showPwd ? "fa-eye" : "fa-eye-slash")} onClick={() => this.setState({showPwd: !showPwd})}></i>

                                                <div className="form-group">
                                                    <label className="small_text"> New password * : </label>
                                                    <input type={showPwd ? "text" : "password"} name="password" id="edit_new_pwd" className="form-control" placeholder="New Password *" value={pwd} onChange={(e) => this.setState({ pwd : e.target.value})} required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="small_text"> Confirm password * : </label>
                                                    <input type={showPwd ? "text" : "password"} name="confirm_password" id="edit_cfrm_pwd" className="form-control" placeholder="Confirm Password *" value={conf_pwd} onChange={(e) => this.setState({ conf_pwd : e.target.value})} required />
                                                </div>
                                                <div className="form-group mt-4">
                                                    <button type="submit" className="abroad_btn"> <i className="fa fa-check"></i> UPDATE PASSWORD </button>
                                                </div>
                                            </form>
                                        :
                                            <form id="send_otp_form" onSubmit={this.handleSendOTP} autoComplete="off">
                                                <div className="form-group">
                                                    <label className="small_text"> Registered mobile number * : </label>
                                                    <input type="number" name="mobile_number" className="form-control" value={mobile} onChange={(e) => this.setState({ mobile : e.target.value})} placeholder="Registered mobile number *" required />
                                                </div>
                                                <div className="form-group mt-4">
                                                    <button type="submit" className="abroad_btn"> <i className="fa fa-check"></i> SEND OTP </button>
                                                </div>
                                            </form>
                                   }
                                </div>
                            </div>

                            <div className="col"></div>
                        </div>
                    </div>

				</div>
			</div>

		)
    }
    
    componentDidUpdate = () => {
        if(this.props.status === 'Password updated')
        {
            swal({
				title: "Password updated",
				text: "Now you can login with your new updated password",
            });

            // Redirect to home page after 2 seconds
            setTimeout(function () { window.location.hash = "#/"; }, 2000);
        }
    }
}

const mapStatesToProps = (state) => {
	return {
        status : state.home.status,
	}
}

const mapActionsToProps = {
      onDisplayError : displayError,
      onSendOTP : sendForgotPasswordOTP,
	  onUpdatePassword : updateNewPassword
}

export default connect(mapStatesToProps, mapActionsToProps) (ForgotPassword);