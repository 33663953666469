import React from 'react';
import './NewsUpdates';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DateFormat from '../../utils/DateFormat';
import Images from '../../assets/images';
import RenderHTML from 'react-render-html';

class NewsDetails extends React.Component {

	constructor (props) {
		super(props);
		const {news} = props.location.state;
        this.state = { news : news };
    }

	render() {
		const {news} = this.state;

		return (

			<div className="container-fluid data_container mt-2 mb-4">

				{/* Breadcrumbs and results counter container */}
				<div className="row sort_container">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
                                <li className="breadcrumb-item"><Link to="/news-updates">News Updates</Link> </li>
								<li className="breadcrumb-item active" area-current="page">News Details</li>
							</ol>
						</nav>
					</div>
					<div className="col text-center">
						<h3 className="header text-orange text-uppercase"> News Details </h3>
					</div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12"></div>
				</div>

				{/*  News feed container */}
				<div className="row px-1 pt-3">

					{/*  Filter results display and loader */}
					<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
						<div className="card">
							{
								news && news !== '' ? 
									<div className="p-3">
										<h5 className="filter_header text-capitalize pl-0 text-orange">{news.news_title}</h5>

										<div>
											{ news.created_at ? <span className="small_text"> Posted : {DateFormat(news.created_at.slice(0,10))}</span> : null }
										</div>
										<hr className="my-2" />
										<p className="text-blue medium_text">{RenderHTML(news.news_description)} </p>
									</div>
								: 
									<div className="col text-center no_results_container">
										<img src={Images.noResults} alt="No results" height="100" /><br/><br/>
										<h6>No matching results found.</h6>
									</div>
							}
						</div>
					</div>
				</div>
			</div>

		);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		sub_loader : state.home.sub_loader,
	}
}

export default connect(mapStatesToProps, null) (NewsDetails);