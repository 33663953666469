import { createStore, combineReducers, applyMiddleware , compose} from 'redux';
import thunk from 'redux-thunk'

import Home_reducer from '../reducers/Home';
import News_reducer from '../reducers/NewsUpdates';
import Achiever_reducer from '../reducers/Achievers';
import Course_reducer from '../reducers/Courses';
import Book_reducer from '../reducers/Books';
import Tests_reducer from '../reducers/Examination'
import Cart_reducer from '../reducers/Cart';


const All_reducers = combineReducers({
    home: Home_reducer,
    news: News_reducer,
    achiever: Achiever_reducer,
    course: Course_reducer,
    book: Book_reducer,
    test:Tests_reducer,
    cart: Cart_reducer
});

const store = createStore(All_reducers, 
    compose(
        applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : f => f
      )
    //   applyMiddleware(thunk)
      );

export default store;