import React from 'react';
import './Register.css';
import { connect } from 'react-redux';
import { HashRouter as Router, Link } from 'react-router-dom';
import MaleDoctor from '../../assets/images/icons/male_doctor.png';
import FemaleDoctor from '../../assets/images/icons/female_doctor.png';
import JoinImage from '../../assets/images/icons/join_us.png';
import swal from 'sweetalert';
import { userRegister } from '../../actions/Home';
import $ from 'jquery';

class Register extends React.Component {
	
	constructor (props) {
		super(props);
		this.state = { error : '', phase : 1, checked : false, gender : 'Male', fname : '', lname : '', email : '', mobile : '', state : '', city : '', pwd : '', conf_pwd : '', stream : '', course_level : '', int_states : [] };
		this.handlePhaseChange = this.handlePhaseChange.bind(this);
		this.handleLoginClick = this.handleLoginClick.bind(this);
	}

	componentDidMount () {
		if(this.props.is_auth)
		{
			window.location.hash = "#/";
		}
	}
    
    handleAddState = state => {
        let { int_states } = this.state;

        if(int_states.includes(state.toString())) {
            const state_index = int_states.indexOf(state.toString());
            int_states.splice(state_index, 1);
        }
        else
            int_states.push(state.toString());

        this.setState({ int_states : int_states });
    }

	handlePhaseChange = () => {
		var email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		var mobile_reg = /^[0-9]{10}$/;

		const {fname, lname, email, mobile, state, pwd, conf_pwd} = this.state;

		if(fname === '' || lname === '' || email === '' || mobile === '' || state === '' || pwd === '')
		{
			this.setState({ error : 'Fill all required fields.' });
		}
		else if(!email_reg.test(email))
		{
			this.setState({ error : 'Enter valid email id.' });
		}
		else if(!mobile_reg.test(mobile))
		{
			this.setState({ error : 'Enter valid mobile numebr.' });
		}
		else if(pwd.length < 8 || pwd !== conf_pwd)
		{
			this.setState({ error : 'Both passwords should be same and have atleast 8 characters.' });
		}
		else
		{
			this.setState({ error : '', phase : 2});
		}
	}

	handleRegisterSubmit = (e) => {
		e.preventDefault();

		const {gender, fname, lname, email, mobile, state, city, int_states, stream, course_level, pwd, conf_pwd} = this.state;

		swal({
			title: "Are you sure?",
			text: "The information you have provided will be saved in your user profile, You can change these information later.",
			buttons: ['Cancel', 'Register'],
		})    
		.then(willRegister => {
			if (willRegister) {
				var formData = new FormData(document.getElementById('register_form'));
				formData.append('fname', fname);
				formData.append('lname', lname);
				formData.append('gender', gender);
				formData.append('email', email);
				formData.append('mobile', mobile);
				formData.append('state', state);
				formData.append('city', city);
				formData.append('password', pwd);
				formData.append('password_confirmation', conf_pwd);
				formData.append('int_stream', stream);
				formData.append('int_course_level', course_level);
				formData.append('int_states', int_states.toString());
				this.props.onRegisterUser(formData);
			}
		});
	}

	handleLoginClick = (e) => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}	

	render() {

		const { states, streams, levels } = this.props;
		const { error, phase, checked, gender, fname, lname, email, mobile, state, city, pwd, conf_pwd, stream, course_level, int_states } = this.state;
		
		return (

			<div className="row register_container mt-4 pt-5 mb-1">
				<div className="col">

					<h3 className="text-center"><b> REGISTER FOR FREE</b></h3>
										
					<div className="row mt-3">
								
						<div className="col"></div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 register_details text-center">

							<img src={JoinImage} height="123" alt="Welcome" />
							<br/>
							<h2> W E L C O M E . . . </h2>
							<span> Your just need few seconds to register with us. </span>
							<br/>

							<h4 className="mt-4"><b> Why to register? </b></h4>
							<div className="text-left">
								<span> <i className="fa fa-check-circle text-success"></i> Find the most optimal college for you. </span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Identify best college to study.</span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Search for the colleges you are interested in.  </span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Get notifications straigh to your phone. </span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> View all the events throught event calendar. </span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Get course cutoffs and fees details. </span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Get 1-to-1 personalised guidance.</span><br/>
							</div>

							<div className="form-group text-center mt-5">
								<h6>Already registered?</h6>
								<button className="login_btn mt-2" title="Login" onClick={this.handleLoginClick}> LOGIN <i className="fa fa-sign-in ml-1"></i></button>
							</div>

						</div>
						<div className="col"></div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 register_details text-center p-2">

							<form id="register_form" onSubmit={this.handleRegisterSubmit} autoComplete="off">

								{
									// First phase form
									phase === 1 ?
										<div>
											<div className="form-group">
												<label>
													<input type="radio" name="gender" className="form-control" defaultValue="Male" defaultChecked={gender === 'Male' ? true : false} required /> <img src={MaleDoctor} alt="Doctor" height="100"/>
												</label>
												<span className="mx-3">or</span>
												<label>
													<input type="radio" name="gender" className="form-control" defaultValue="Female" defaultChecked={gender === 'Female' ? true : false} /> <img src={FemaleDoctor} alt="Doctor" height="100" required/>
												</label>
											</div>

											<div className="form-group input-group">
												<div className="input-group-prepend">
													<span className="input-group-text"> <i className="fa fa-user"></i></span>
												</div>
												<input type="text" name="fname" className="form-control text-capitalize" placeholder="First name *" value={fname} onChange={(e) => this.setState({fname : e.target.value})} required autocomplete={'off'}/>
												<input type="text" name="lname" className="form-control text-capitalize" placeholder="Last name *" value={lname} onChange={(e) => this.setState({lname : e.target.value})} required autocomplete={'off'}/>
											</div>

											<div className="form-group input-group">
												<div className="input-group-prepend">
													<span className="input-group-text"> <i className="fa fa-envelope"></i></span>
												</div>
												<input type="email" name="email" className="form-control" placeholder="Email ID *" value={email} onChange={(e) => this.setState({email : e.target.value})} required/>
											</div>
										
											<div className="form-group input-group">
												<div className="input-group-prepend">
													<span className="input-group-text"> <i className="fa fa-phone"></i></span>
												</div>
												<input type="number" min={10} max={10} name="mobile" className="form-control" placeholder="Mobile Number *" value={mobile} onChange={(e) => this.setState({mobile : e.target.value})} required/>
											</div>


											<div className="form-group input-group">
												<div className="input-group-prepend">
													<span className="input-group-text"> <i className="fa fa-lock"></i></span>
												</div>
												<input type="password" name="password" id="reg_pwd" className="form-control" placeholder="Password *" value={pwd} onChange={(e) => this.setState({pwd : e.target.value})} required/>
												<input type="password" name="password_confirmation" id="reg_cfrm_pwd" className="form-control" placeholder="Re-type Password *" value={conf_pwd} onChange={(e) => this.setState({conf_pwd : e.target.value})} required/>
											</div>

											<span className="small_text"> {error} </span>

											<div className="form-group text-center mt-2">
												<button type="button" className="login_btn" onClick={this.handlePhaseChange}> NEXT <i className="fa fa-chevron-right ml-1"></i> </button>
											</div>

										</div>
									:
										// Second phase form
										<div>
											<div className="form-group text-center mt-4">
												<button type="button" className="login_btn" onClick={() => this.setState({phase : 1})}>  <i className="fa fa-chevron-left ml-1"></i> BACK </button>
											</div>

											<input type="checkbox" defaultChecked={checked} value={true} onChange={() => this.setState({checked : !checked})} /><span className="small_text ml-2 terms_check"> I agreed to all the <Router> <Link to="/terms-conditions"> Terms & conditions </Link> </Router> of Classic Education. </span>
											<span className="small_text"> {error} </span>
													
											<div className="form-group text-center mt-4">
												<button type="submit" className="login_btn" disabled={stream !== '' && course_level !== '' && checked ? false : true}> REGISTER <i className="fa fa-user-plus ml-1"></i> </button>
											</div>
										</div>
								}
							</form>
						</div>
						<div className="col"></div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStatesToProps = (state) => {
	return {
		main_loader : state.home.main_loader,
		is_auth : state.home.is_auth,
		states : state.state.college_states,
	}
}

const mapActionsToProps = {
	onRegisterUser : userRegister,
}

export default connect(mapStatesToProps, mapActionsToProps) (Register);