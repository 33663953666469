import React from 'react';
import '../AboutUs/AboutUs.css';
import { connect } from 'react-redux';
import $ from 'jquery';

class TermsConditions extends React.Component {

  constructor (props) {
    super (props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }
  
  handleLoginClick = (e) => {
    // Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
  }

  render () {

    const { is_auth } = this.props;

    return (

      <div className="row">
        <div className="col about_us_container mt-3">
          <h3 className="text-center text-orange text-uppercase"> Institute Instruction </h3>

          <div className="row description_card">
            <div className="col">
              <p className="para">The candidate should follow the rules and regulations of the institute.</p>
              <p className="para">The candidate must pay full fees at the time of admission.</p>
              <p className="para">Fees will not be refunded for any reasons like discontinuity of classes, inconvenience of class timing and your individual problems.</p>
              <p className="para">After taking admission you are allowed to complete the course in only 12 months.</p>
              <p className="para">Admissions can not be transferred.</p>

              <p className="para">Candidates should be sincere, punctual and obedient in the class room Fees will not refunded if he will be expelled from the Institute due to misbehaviour.</p>

              <p className="para">Attendance is compulsory.</p>


<p className="para">If the candidate faces any problems regarding our concern then inform the

authorities of the institute in writing.</p>


<p className="para">It is compulsory to switch off the Mobile phones when you

are in the class room.</p>
<p className="para">Any good suggestions can be accepted or rejected for the improvement of the

institute.</p>

<p className="para">The Institute has reserved all the rights to change the rules. It can accept or

the admission.</p>

<p className="para">Board of Directors'/ Managing Director's decision is final in any matter.</p>

<p className="para">Thank you for reading all the instructions & accepting them.</p>
<p className="para">Subject to Dharwad Jurisdicton only.</p>

            </div>
          </div>

          

    
          
        </div>
      </div>

    )
  }
}

const mapStatesToProps = (state) => {
  return {
    is_auth : state.home.is_auth
  }
}

export default connect(mapStatesToProps, null) (TermsConditions);