import { ADD_PUBLICATION, GET_CART_SUCCESS , CART_AMOUNT, GET_DELIVERY_ADDRESS} from '../actions/Cart';

const initialState = {
    books : [],
    cartPublication : [],
    cartAmount : {
        TotalAmount : 0,
        DeliveryCharge : 0
    },
    deliveryAddresses : []
}

const Cart_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case ADD_PUBLICATION :
            return Object.assign({}, state, { cartPublication : payload });

        case GET_CART_SUCCESS : 
            return Object.assign({}, state, { cartPublication : payload });

        case CART_AMOUNT : 
            return Object.assign({}, state, { cartAmount : payload });

        case GET_DELIVERY_ADDRESS :
            return Object.assign({}, state, { deliveryAddresses : payload });

        default :
            return state;
    }
}

export default Cart_reducer;