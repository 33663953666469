const Colors = {
    white: '#ffff',
    black: '#000000',
    whiteSmoke: 'whitesmoke',
    orange: '#F5811E',
    darkGreen: 'green',
    green: '#004053',
    lightGreen: '#1f6980',
    red: 'red',
    lightRed: '#DB7093',
    lightBlack: '#323232',
    lightGrey: 'lightgrey',
    lighterGrey: '#FAFAFA',
    lightestGrey: 'rgba(0,0,0,0.02)',
    grey: '#787E8C',
    darkGrey: 'darkgrey',
    blue: '#0E85FF',
    darkBlue: '#233f78',
    lightBlue: '#20BBFF',
    darkOrange: '#FFA136',
    transparent: 'transparent',
    labelColor: '#949CA5',
    inputBorder: '#D7D7D7',
    inputBackGround: '#F5F7FB',
    overlayBlack: 'rgba(0,0,0,0.6)',
    overlayLightBlack: 'rgba(0,0,0,0.2)',
    buttonColor: '#535353',
    tomato: 'tomato',
    purple: 'purple',
    lighterWhite: 'rgba(255, 255, 255, 0.4)',
    admiRed: '#ED0E0E',
    admiGreen: '#15F643',
    admiOrange: '#EBAF24',
    admiBlue: '#241BCF',
    admiBlack: '#AAD4E9',
};

export default Colors;
