
import {ApiPaths, ApiService} from '../utils/ApiService';
import {mainLoadingTrue, mainLoadingFalse, displayError} from './Home';

export const GET_TESTS_SUCCESS = 'GET_TESTS_SUCCESS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const POST_QUESTIONS_SUCCESS = 'POST_QUESTIONS_SUCCESS';
export const GET_EXAM_SUCCESS = 'GET_EXAM_SUCCESS';
// export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS';

export const GetTests = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_TESTS)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getTestsSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const GetQuestions = (id) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(`${ApiPaths.GET_QUESTIONS}${id}`)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getQuesionSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const PostQuestions = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.SUBMIT_RESULT, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getQuesionSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}


export const addWrittenExamSubmission = (id, data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.WRITTEN_EXAM_SUBMISSION + "/"+id, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    // dispatch(getQuesionSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}


export const getMyWrittenExam = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_WRITTEN_EXAM)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getMyWrittenExamSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getTestsSuccess = (data) => {
    return {
        type : GET_TESTS_SUCCESS,
        payload : {
            tests : data,
        }
    }
}
export const getQuesionSuccess = (data) => {
    return {
        type : GET_QUESTIONS_SUCCESS,
        payload : {
            questions : data,
        }
    }
}

export const postQuesionSuccess = (data) => {
    return {
        type : POST_QUESTIONS_SUCCESS
    }
}



export const getMyWrittenExamSuccess = (data) => {
    return {
        type : GET_EXAM_SUCCESS,
        payload : {
            writtenExams : data,
        }
    }
}
