import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../utils/ApiService';
import {mainLoadingTrue, mainLoadingFalse, displayError} from './Home';

export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';

export const getNewsUpdates = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.get(ApiPaths.GET_NEWS)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statuscode === 200) {
                    dispatch(getNewsSuccess(data.data));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getNewsSuccess = (data) => {
    return {
        type : GET_NEWS_SUCCESS,
        payload : {
            news_updates : data,
        }
    }
}
