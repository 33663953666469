import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';
import MainLoader from './assets/images/icons/logo.png';
import ApiLoader from './assets/images/icons/loading_icon.gif';
import { connect } from 'react-redux'; 
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Disable right click and F12 buttons from inspecting elements
// document.addEventListener('contextmenu', event => event.preventDefault());

// $(document).keydown(function (event) {
//     if (event.keyCode === 123) { // Prevent F12
//         return false;
//     } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) { // Prevent Ctrl+Shift+I        
//         return false;
//     }
// });

class App extends React.Component {

  constructor (props) {
    super(props);
    this.updateLoader = this.updateLoader.bind(this);
  }


  componentWillUnmount() {
    window.removeEventListener("load", this.updateLoader );
  }

  componentDidMount() {
    
    window.addEventListener("load", this.updateLoader );

    // or simply just AOS.init();
    AOS.init({
      // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'load', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
        

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 400, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    
      });
    AOS.refresh();
  }

  updateLoader = () => {
    $('.loader_container').addClass('d-none');
    $('#main_container').removeClass('d-none');
  }

  render() {


    const { main_loader } = this.props;

    // Display loader when api is loading
    let loader = '';

    if(main_loader)
    {
      loader =  <div className="api_loader_container">
        <img src={ApiLoader} height="100" className="img-fluid loader_image" alt="Loading . . ."/>
      </div>;
    }
    else
    {
      loader = '';
    }

    return (
      <div className="">

          {loader}

          {/* Display loader when page is not loaded */}
          <div className="loader_container">
            <img src={MainLoader} height="180" className="loader_image" alt="Loading . . ."/>
          </div>

          <div className="container-fluid d-none" id="main_container">
            <Router>
              <Route exact path="/" component={Layout}/>
            </Router>
          </div>

      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    main_loader : state.home.main_loader
  }
}

export default connect(mapStatesToProps) (App);