import React from 'react'

import Timer from './Timer'

const QueHeading = (props) => {
    return (
        <div className="container mt-4" style={{fontSize : "14px"}}>
            { props.propsData && 
            <div className="d-flex justify-content-between">
                <div className="">
                    <div className="col-lg-12 mt-3 ml-2">
                        Exam Name
                    </div>
                    <div className="col-lg-12 ml-2 txt-bold">
                       {props.propsData.test_name}
                    </div>
                </div>
                <div className="">
                    <div className="col-lg-12 mt-3 ml-2">
                        Exam Duration
                    </div>
                    <div className="col-lg-12 ml-2 txt-bold">
                        {/* {props.propsData.test_duration} Min */}
						 <Timer minutes={props.propsData.test_duration}/>
                    </div>
                </div>
                <div className="">
                    <div className="col-lg-12 mt-3 ml-2">
                        Number of Question
                    </div>
                    <div className="col-lg-12 ml-2 txt-bold">
                    {props.propsData.number_of_questions} Questions
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default QueHeading
