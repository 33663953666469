import React, { useEffect, useState } from 'react';
import './TestDetails.css';

const Result = (props) => {
    const [resultData, setResultData] = useState([])
    const [finalResult, setFinalResult] = useState([])
    const [resultPer, setResultPer] = useState([])

    useEffect(()=>{
        if(props.location.state && props.location.state.testData && props.location.state.finalResult){
            setResultData(props.location.state.testData)
            setFinalResult(props.location.state.finalResult)
            setResultPer()
        }else{
            props.history.push('/Examination')
        }
    }, [props.location.state])

    return (
        <>
            <div className="container-fluid">
                <div className="col-lg-12 test-detail-container mt-4 mb-4 ">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mt-5">
                            <table className="table table-sm">
                                <thead>
                                    <tr className="tbl-head" style={{ 'bg-color': "#004C73" }}>
                                        <th scope="col">Q.Numb</th>
                                        <th scope="col">RESULT</th>
                                        <th scope="col">RIGHT ANSWERS</th>
                                        <th scope="col">YOUR ANSWERS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        resultData.length > 0 && resultData.map((item,i)=>{
                                            return  <tr className={ item["selected"] == undefined ? "bg-warning" : item.answer == item.selected ? "bg-success" : "bg-danger"}>
                                            <th scope="row">{i + 1}</th>
                                            <td>{item.answer == item.selected ? "Correct" : "Wrong"}</td>
                                            <td>{item.answer}</td>
                                            <td>{item.selected}</td>
                                        </tr>
                                    })}
                                   
                                    {/* // <tr className="bg-danger">
                                    //     <th scope="row">1</th>
                                    //     <td>Mark</td>
                                    //     <td>Otto</td>
                                    //     <td>@mdo</td>
                                    // </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-6 col-md-6 mt-3">
                            <div className="text-center score-head mx-auto">
                                <span>Your Score</span>
                            </div>
                            <div className="text-center score-box mx-auto">
                            <span>Percentage</span>
                                <div className="perc">{parseFloat((finalResult.correct_answers /finalResult.total_questions)*100).toFixed(2)}</div>
                            <span>Marks</span>
                            <div className="perc">{finalResult.correct_answers}/{finalResult.total_questions}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Result
