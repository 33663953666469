const Images = {
    logo1024: require('./logo/logo_1024.png'),
    logo512: require('./logo/logo_512.png'),
    logo180: require('./logo/logo_180.png'),
    logo120: require('./logo/logo_120.png'),
    logo87: require('./logo/logo_87.png'),
    logo80: require('./logo/logo_80.png'),
    check: require('./icons/check.png'),
    settings: require('./icons/settings.png'),
    home: require('./icons/home.png'),
    news: require('./icons/news.png'),
    user: require('./icons/user.png'),
    close: require('./icons/close.png'),
    notification: require('./icons/notification.png'),
    back: require('./icons/back.png'),
    slider1: require('./sliderImage1.jpeg'),
    slider2: require('./sliderImage2.jpg'),
    onlineClass: require('./icons/online_class.png'),
    offlineClass: require('./icons/offline_class.png'),
    ebooks: require('./icons/ebook.png'),
    publications: require('./icons/publications.png'),
    exams: require('./icons/exam.png'),
    achieve: require('./icons/achive.png'),
    achieve2: require('./icons/achive2.png'),
    achiever: require('./icons/achiever.png'),
    men: require('./icons/men.png'),
    subLoader: require('./icons/loader.gif'),
    noResults: require('./icons/no_results.png'),
    homeBg: require('./home_bg.png'),
    aboutUs: require('./about_us.png'),
    office: require('./office.jpg'),
    newsImage: require('./news.JPG'),
    examsImage: require('./exams.png'),
    offlineClassImage: require('./offline_class.png'),
    onlineClassImage: require('./online_class.png'),
    booksImage: require('./books.png'),
    publicationsImage: require('./publications.png'),
    calendar: require('./icons/calendar.png'),
    hurryUp: require('./icons/hurryUp.png'),
    duration: require('./icons/duration.png'),
    add: require('./icons/add.png'),
    minus: require('./icons/minus.png'),
    download: require('./download.jpg'),
    upload: require('./upload.jpg'),
};

export default Images;
