import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAutocompleteColleges, getHomeDetails, addRemoveFavourite } from '../../actions/Home';
import $ from 'jquery';
import {BaseUrl, ApiService} from '../../utils/ApiService';
import Images from '../../assets/images/';
import YouTube from 'react-youtube';
import DateFormat from '../../utils/DateFormat';
import {isMobile} from 'react-device-detect';
import swal from 'sweetalert';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


class Home extends React.Component {

	constructor (props) {
		super(props);
		this.state = { activeSlide: 0 };
	}

	componentWillMount = async() => {
		await this.props.onGetHomeDetails();
	}

	componentDidMount = () =>{
		if(isMobile){
			swal({
				title: "Android App available",
				text: "For better experiance, use Mobile app. Click Ok to Download",
				type: "info",
				buttons: ["Skip", true],
				buttons: true
			}).then(isConfirm =>{
				if (isConfirm){
					window.open('https://play.google.com/store/apps')
				}
			})
		}
	}


    // handleUpdateCart = (book_id) => {
    //     const formData = new FormData();
    //     formData.append('book_id', book_id);

    //     this.setState({isLoading: true}, () => {
    //         ApiService.get(ApiPaths.GET_COURSES + type)
    //         .then(response => {
    //             ApiPaths.ADD_TO_CART,
    //             ApiMethods.POST,
    //             formData,
    //             this.onSuccess,
    //             this.onFailure,
    //         );
    //     });
    // }

    // onSuccess = (response: any) => {
    //     ToastMessage(response.data.message);
    //     this.setState({isLoading: false});

    //     if (response.data.statuscode === 200) {
    //         this.setState({cartCount: response.data.data});
    //         this.props.onReload();
    //     }
    // };


	handleSlideChange = (activeSlide, type) => {
		if(type === 0 && activeSlide > 0) {
			this.setState({activeSlide: activeSlide - 1});
		} else if(type === 1 && activeSlide < (this.props.home_data.length - 1)) {
			this.setState({activeSlide: activeSlide + 1});
		}
	}

	renderSlideContent = (image, index, activeSlide) => {
		const opts = {
			height: '700',
			width: window.innerWidth,
			playerVars: {
				autoplay: 0
			}
		};

		if(activeSlide === index) {
			if(image.file_type == '1') {
				return (
					<YouTube
						videoId={image.file_path}
						opts={opts}
						className='home_slider_image'
					/>
				);
			} else {
				return (
					<img src={BaseUrl + image.file_path} className="home_slider_image" style={{width: window.innerWidth}} alt="" />
				);
			}
		}
	}

	renderReadButton = (filePath) => {
		if (filePath && filePath !== '') {
			return (
				<Link to={'/pdf-reader/' + filePath.replace('/book_files/','')}>
					<button className="btn btn-primary btn-sm ml-0">READ</button>
				</Link>
			);
		} else {
			return <button className="btn btn-primary btn-sm ml-0" disabled={true}>READ</button>
		}
	}

	render() {

		const {is_auth, auth_user, home_data} = this.props;
		return (
			<>
			<div className="p-2">
			<marquee width="100%" direction="left" height="20px">{home_data.scrollingNews && home_data.scrollingNews }</marquee>
			</div>
			<div className="home_container" id="home_container" data-aos='fade-up'>
				<div className="row">
					<div className="col p-0">
						<div className="home_image_container" style={{height: '70vh'}}>
						<Carousel autoPlay>
							{ 
								home_data['rollingImage'] && home_data['rollingImage'].length > 0 ? home_data['rollingImage'].map((ele, i)=>{
									return <>
										<img key={ele.id} style={{
										height: '70vh',
										background: `url(${BaseUrl+ele.file_path})`
									}} className="image d-flex align-items-center" />
									</>
								})
								:
								<h1>Loading </h1>
							}

						</Carousel>
                    
						</div>
					</div>
				</div>

				<div className="container-fluid">
					<div className="row mt-5 aboutUs">
						<div className="col imageDiv" style={{height: '340px !important'}}>
							<img src={Images.office} className="img-thumbnail"  alt="about us" />
						</div>
						<div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
							<h4 className="sectionHeader">ABOUT US</h4>
							<hr className="headerSeperator"/>
							<p className="sectionPara">The institute has hostel facility for over 1000 students, and providing vehicle and round the clock library facility. Models tests for all the competitive exams are being conducted in Dharwad and Bengaluru Centres of the institute on every Sunday. Hundreds of candidates are selected for various services every year. He started Spardha Spoorti Publication in 2005, which is publishing magazines and books in Kannada and English medium for all the competitive examinations.</p>
							<p className="sectionPara">In Classic education more than 2000 students are studying presently. The Classic PU College and the Classic Degree College, which are imparting education in Science, Commerce and Arts streams, are new additions to the Classic Group.</p>
						</div>
					</div>
					<hr className="card-border"/>
						
					{/* Online courses */}
					<div className="row mt-5" data-aos='fade-up'>
						<div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 ">
							<div className="row">
								<div className="col">
									<h4 className="sectionHeader">ONLINE COURSES</h4>
								</div>
								<div className="col">
									<Link className="sectionViewButton" exact to="/courses/1/online">View All Courses</Link>
								</div>
							</div>
							<hr className="headerSeperator"/>

							<div className="row course">

								{(home_data.online_courses && home_data.online_courses.length > 0) &&
									home_data.online_courses.slice(0, 4).map((course, index) => (
										
										<div key={index} className="col-md-6 col-12 my-3">
											<Link to={{pathname : '/courses/' + course.id + '/batches/1/online' , params : {hasBatch : false }}}>
											<div className="row">
												<div className="col">
													<img src={(course.course_icon_path && course.course_icon_path !== '') ? (BaseUrl + course.course_icon_path) : Images.onlineClassImage} className="img-fluid" alt="" />
												</div>
												<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
													<p className="itemSubHeader my-1">{course.course_name}</p>
													<span className="itemDescription">{course.course_discription}</span>
													<span className="itemDescription">{course.course_duration}</span><br/>
												</div>
											</div>
										</Link>
										</div>
									))
								}
							</div>
						</div>
						<div className="col imageDiv">
							<img src={Images.onlineClassImage} className="img-fluid" alt="" />
						</div>
					</div>
					<hr className="card-border"/>
								
					{/* Offline courses */}
					<div className="row mt-5" data-aos='fade-up'>
						<div className="col imageDiv">
							<img src={Images.offlineClassImage} className="img-fluid" alt="" />
						</div>
						<div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
							<div className="row">
								<div className="col">
									<h4 className="sectionHeader">OFFLINE COURSES</h4>
								</div>
								<div className="col">
									<Link className="sectionViewButton" exact to="/courses/0/offline">View All Courses</Link>
								</div>
							</div>
							<hr className="headerSeperator"/>
							
							<div className="row course">
								{(home_data.offline_courses && home_data.offline_courses.length > 0) &&
									home_data.offline_courses.slice(0, 4).map((course, index) => (
										<div key={index} className="col-md-6 col-12 my-3">
											<Link to={{pathname : '/courses/' + course.id + '/batches/0/offline' , params : {hasBatch : false }}}>
											<div className="row">
												<div className="col">
													<img src={(course.course_icon_path && course.course_icon_path !== '') ? (BaseUrl + course.course_icon_path) : Images.onlineClassImage} className="img-fluid" alt="" />
												</div>
												<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
													<p className="itemSubHeader my-1">{course.course_name}</p>
													<span className="itemDescription">{course.course_discription}</span>
													<span className="itemDescription">{course.course_duration}</span><br/>
												</div>
											</div>
											</Link>
										</div>
									))
								}
							</div>
						</div>
					</div>
					<hr className="card-border"/>

					{/* E books */}
					<div className="row mt-5" data-aos='fade-up'>
						<div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 ">
							<div className="row">
								<div className="col">
									<h4 className="sectionHeader">E - BOOKS</h4>
								</div>
								<div className="col">
									<Link className="sectionViewButton" exact to="/books">View All E-Books</Link>
								</div>
							</div>
							<hr className="headerSeperator"/>

							<div className="row books">
								{(home_data.books && home_data.books.length > 0) &&
									home_data.books.slice(0, 4).map((book, index) => (
										<div key={index} className="col-md-6 col-12 my-3">
											<div className="row">
												<div className="col side-imageDiv">
													<img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="img-fluid img-thumbnail" alt="" style={{height : '80px'}} />
												</div>
												<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
													<p className="itemSubHeader my-0">{book.book_name}</p>
													<span className="itemDescription">{book.book_discription}</span>
													<span className="medium_text">{(book.book_price !== '' && book.book_price > 0) ? ('Rs. ' + book.book_price) : 'FREE'}</span>
													<br/>
													{(book.isOrdered || book.book_price <= 0) ?
														this.renderReadButton(book.book_file_path)
													:
														book.isInCart ?
															<button className="btn btn-primary btn-sm ml-0">REMOVE</button>
														:
															<button className="btn btn-primary btn-sm ml-0">ADD TO CART</button>
													}
												</div>
											</div>
										</div>
									))
								}
							</div>
						</div>
						<div className="col imageDiv">
							<img src={Images.booksImage} className="img-fluid" alt="" />
						</div>
					</div>
					<hr className="card-border"/>
						
					{/* Publications */}
					<div className="row mt-5 books" data-aos='fade-up'>
						<div className="col imageDiv">
							<img src={Images.publicationsImage} className="img-fluid" alt="" />
						</div>
						<div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 ">
							<div className="row">
								<div className="col">
									<h4 className="sectionHeader">PUBLICATIONS</h4>
								</div>
								<div className="col">
									<Link className="sectionViewButton" exact to="/books">View All  Publications</Link>
								</div>
							</div>
							<hr className="headerSeperator"/>
							
							<div className="row">
								{(home_data.publications && home_data.publications.length > 0) &&
									home_data.publications.slice(0, 4).map((book, index) => (
										<div key={index} className="col-md-6 col-12 my-3">
											<div className="row">
												<div className="col side-imageDiv">
													<img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="img-fluid img-thumbnail" alt="" style={{height : '100px'}} />
												</div>
												<div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
													<p className="itemSubHeader my-0">{book.book_name}</p>
													{/* {(book.book_discription && book.book_discription.length > 0 ) && (<span className="small_text">{book.book_discription.substring(0, 100)}</span>)} */}
													<span className="itemDescription">{book.book_discription}</span>
													<span className="medium_text">{(book.book_price !== '' && book.book_price > 0) ? ('Rs. ' + book.book_price) : 'FREE'}</span>
													<br/>
													{(book.isOrdered || book.book_price <= 0) ?
														<button className="btn btn-primary btn-sm ml-0">READ</button>
													:
														book.isInCart ?
															<button className="btn btn-primary btn-sm ml-0">REMOVE</button>
														:
															<button className="btn btn-primary btn-sm ml-0">ADD TO CART</button>
													}
												</div>
											</div>
										</div>
									))
								}
							</div>
						</div>
					</div>
					<hr className="card-border"/>

					{/* E-Books */}
					{/* <div className="row mb-0">
						<div className="col pt-5 text-center">
							<h4 className="sectionHeader">E-BOOKS</h4>
							
							<div className="row">
								{(home_data.books && home_data.books.length > 0) &&
									home_data.books.map((book, index) => (
										<div key={index} className="col my-3 p-4">
											<img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="book_image" alt="" />
											<h6 className="my-2">{book.book_name}</h6>
											<span>{(book.book_price !== '' && book.book_price > 0) ? ('Rs. ' + book.book_price) : 'Free'}</span>
											<br/>
											{book.isOrdered ?
												<button className="btn btn-primary btn-sm">READ</button>
											:
											 	book.isInCart ?
													<button className="btn btn-primary btn-sm">REMOVE</button>
												:
													<button className="btn btn-primary btn-sm">ADD TO CART</button>
											}
										</div>
									))
								}
							</div>

							<div className="mt-3">
								<Link className="sectionViewButton" exact to="/0/books">View All E-Books</Link>
							</div>
						</div>
					</div>
					<hr className="mt-0"/> */}

					{/* Publications */}
					{/* <div className="row mb-0">
						<div className="col pt-5 text-center">
							<h4 className="sectionHeader">PUBLICATIONS</h4>

							<div className="row">
								{(home_data.publications && home_data.publications.length > 0) &&
									home_data.publications.map((book, index) => (
										<div key={index} className="col my-3 p-4">
											<img src={(book.book_image_path && book.book_image_path !== '') ? (BaseUrl + book.book_image_path) : Images.bookImage} className="book_image" alt="" />
											<h6 className="my-2">{book.book_name}</h6>
											<span>{(book.book_price !== '' && book.book_price > 0) ? ('Rs. ' + book.book_price) : 'Free'}</span>
											<br/>
											{book.isOrdered ?
												<button className="btn btn-primary btn-sm">READ</button>
											:
											 	book.isInCart ?
													<button className="btn btn-primary btn-sm">REMOVE</button>
												:
													<button className="btn btn-primary btn-sm">ADD TO CART</button>
											}
										</div>
									))
								}
							</div>

							<div>
								<Link className="sectionViewButton" exact to="/1/books">VIEW ALL PUBLICATIONS</Link>
							</div>
						</div>
					</div>
					<hr className="mt-0"/> */}


					{/* Exams */}
					<div className="row mt-5 exams" data-aos='fade-up'>
						
						<div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 ">
							<div className="row">
								<div className="col">
									<h4 className="sectionHeader">EXAMS</h4>
								</div>
								<div className="col">
									<Link className="sectionViewButton" exact to="/Examination">View  All Exams</Link>
								</div>
							</div>
							<hr className="headerSeperator"/>
							
							<div className="d-flex justify-space-between align-items-baseline">
								<div><h6 className="sectionHeader sub">ONLINE EXAMS</h6></div>
								<div><Link to="/Examination" className="btn btn-primary btn-sm ml-0">Take Test</Link></div>
							</div>
							<p className="sectionPara"> Models tests for all the competitive exams are being conducted in Dharwad and Bengaluru Centres of the institute on every Sunday. Hundreds of candidates are selected for various services every year.</p>
							<hr/>

							<div className="d-flex justify-space-between align-items-baseline">
								<div><h6 className="sectionHeader sub">OFFLINE EXAMS</h6></div>
								<div><Link to="/Examination" className="btn btn-primary btn-sm ml-0">Take Test</Link></div>
							</div>
							<p className="sectionPara"> Models tests for all the competitive exams are being conducted in Dharwad and Bengaluru Centres of the institute on every Sunday. Hundreds of candidates are selected for various services every year.</p>
							<hr/>

							<div className="d-flex justify-space-between align-items-baseline">
								<div><h6 className="sectionHeader sub">MOCK EXAMS</h6></div>
								<div><Link to="/Examination" className="btn btn-primary btn-sm ml-0">Take Test</Link></div>
							</div>
							<p className="sectionPara"> Models tests for all the competitive exams are being conducted in Dharwad and Bengaluru Centres of the institute on every Sunday. Hundreds of candidates are selected for various services every year.</p>
						</div>
						<div className="col imageDiv">
							<img src={Images.examsImage} className="img-fluid" alt="" />
						</div>
					</div>
					<hr className="card-border"/>

					{/* News updates */}
					<div className="row pt-5" data-aos='fade-up'>
						<div className="imageDiv d-none col-xl-5 d-xl-block d-xxl-block">
							<img src={Images.newsImage} className="img-fluid" alt="" style={{height : "340px"}} />
						</div>
						<div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 ">
							<div className="row">
								<div className="col">
									<h4 className="sectionHeader">NEWS UPDATES</h4>
								</div>
								<div className="col">
									<Link className="sectionViewButton" exact to="/news-updates">View All News</Link>
								</div>
							</div>
							<hr className="headerSeperator"/>

							{(home_data.news && home_data.news.length > 0) &&
								home_data.news.slice(0, 2).map((news, index) => (
									<React.Fragment key={index}>
										<div key={index} className="row">
											<div className="col-sm-3 col-12">
												{news.news_image_path && 
												<YouTube 
												opts = {{
													height: '80px',
													width: '-webkit-fill-available',
													playerVars: {
														autoplay: 0,
													},
												}}
												videoId={news.news_image_path}
												/>}
												{/* <img src={(news.news_image_path && news.news_image_path !== '') ? (BaseUrl + news.news_image_path) : Images.newsImage} className="img-fluid" alt="" /> */}
											</div>
											<div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
												<h6 className="news-title "><b>{news.news_title}</b></h6>
												<p className="sectionPara">{news.news_description}</p>
												<div className="news-time"><span>{DateFormat(news.created_at)}</span><br/></div>
											</div>
										</div>
										{ index < home_data.news.length -2 &&  <hr className="m-4"/>}
									</React.Fragment>
								))
							}
						</div>
					</div>
				<hr className="card-border"/>
				</div>
				{/* <div className="home_slider_container">
					<div>
						{(home_data && home_data.length > 0) &&
							home_data.map((image, index) => (
								this.renderSlideContent(image, index, activeSlide)
							))
						}
					</div>

					<i className="fa fa-chevron-circle-left slider_button left_button" onClick={() => this.handleSlideChange(activeSlide, 0)}></i>
					<i className="fa fa-chevron-circle-right slider_button right_button" onClick={() => this.handleSlideChange(activeSlide, 1)}></i>
				</div> */}
			</div>
			</>
		);
	}

	componentWillUnmount () {
		// this.props.onGetAutocompleteColleges('');
		// clearInterval(this.displayState);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth: state.home.is_auth,
		auth_user: state.home.auth_user,
		img_url: state.home.img_url,
		home_data: state.home.home_data,
		scrollingText : state.home.scrollingText
	}
}

const mapActionsToProps = {
	onGetHomeDetails : getHomeDetails,
	onGetAutocompleteColleges : getAutocompleteColleges,
	onAddRemoveFavourite : addRemoveFavourite
}

export default connect(mapStatesToProps, mapActionsToProps) (Home);