import React from 'react';
import './TestDetails.css';
import { useHistory } from 'react-router-dom';
import Images from '../../assets/images';
import { GetTests } from '../../actions/Examination';
import {ApiService, ApiPaths, BaseUrl} from '../../utils/ApiService';
import swal from 'sweetalert';
import AppConstants from '../../assets/constants/AppConstants';
import { connect } from 'react-redux';

const TestDetails = (props) => {

    const history = useHistory();

    const redirect = (data) => {
        history.push({
            pathname: '/Examination1',
            state: data,
        })
    }

    const redirectToExamination = () => {
		props.GetTests();
        history.push({
            pathname: '/Examination'
        })
    }

    const handleBatchRegister = (Id, totalFees) => {
		const {is_auth, auth_user} = props;
		if(is_auth && auth_user) {
			var formData = new FormData();
            // formData.append('total_amount', totalFees);
            formData.append('total_amount', totalFees);

			swal({
			  title: "REGISTER",
			  text: "You need to pay Rs." + totalFees + " total fees to register to this batch",
			  buttons: ['CANCEL', 'REGSITER'],
			})
			.then(willDelete => {
				if (willDelete) {
					ApiService.post(ApiPaths.SAVE_CHECKOUT, formData)
					.then(response => {
						if(response.data.statuscode === 200)
						{
							// Initialize payment
							var options = {
								"key": AppConstants.RAZORPAY_API_KEY,
								// "amount": (totalFees * 100),
								"amount": 1,
								"name": 'Exam Registration',
								"description": 'Classic Education Dharwad',
								"image": Images.logo1024,
								"order_id" : response.data.rzpay_order_id,
								"currency": 'INR',
								"theme" : {
									"color": "#004053"
								},
								"handler": function (response){
									if(response && response.razorpay_payment_id !== '') {
										// Make payment success in server
										var paymentFormData = new FormData();
										paymentFormData.append('rzpay_order_id', response.razorpay_order_id);
										paymentFormData.append('rzpay_payment_id', response.razorpay_payment_id);
										paymentFormData.append('rzpay_signature', response.razorpay_signature);
										paymentFormData.append('id', Id);
										paymentFormData.append('order_price', totalFees);
										paymentFormData.append('payment_type', 3); // Type 2 for Exam
					
										ApiService.post(ApiPaths.PAYMENT_SUCCESS, paymentFormData)
										.then(response => {
											if(response.data.statuscode === 200) {
												swal({ title :'Thank you', text : 'Payment Success, Batch Registration completed', icon : 'success'});
                                                redirectToExamination();
											}
										})
										.catch(error => {
											swal('OOPS!...', error.message);
                                            redirectToExamination()
										});
									}
								},
								"prefill" : {
									"contact" : auth_user.mobile_number,
									"email" : auth_user.email
								}
							}
					
							let rzp = new window.Razorpay(options); rzp.open();
						}
					})
					.catch(error => {
						swal('OOPS!...', error.message);
					});
			  	}
			});
		} else {
			this.handleLogin();
		}
	}

    return (
      
        <div className="container-fluid">
            <div className="test-detail-container">
                <div className="row">
                    <div className="col-lg-12 text-center mt-3 examheading">
                        {props.TestData.test_name}
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12">
                            Exam Duration
                        </div>
                        <div className="col-lg-12 txt-bold">
                            30 Mins
                        </div>
                    </div> */}
                    <div className="container">
                        <div className="col-lg-12 mt-3 ml-2">
                            Exam Duration
                        </div>
                        <div className="col-lg-12 ml-2 txt-bold14">
                        {props.TestData.test_duration} Min
                        </div>

                        <div className="col-lg-12 mt-3 ml-2">
                            Number of Question
                        </div>

                        <div className="col-lg-12 ml-2 txt-bold14">
                        {props.TestData.number_of_questions} Questions
                        </div>
                        <div className="col-lg-12 mt-3 ml-2">
                            Exam Instructions
                        </div>
                        <div className="col-lg-12 ml-0 pl-4 txt-bold14 text-justify">
                            <p>
                                All questions are objective in nature. Candidates are required to choose any one of the 4 answer choices given for each question.
                            </p>
                        </div>

                        {
                           props.type == "online" &&  ! props.TestData.is_registered ? <div className="pt-3 text-center mb-3">
                            <button className="rounded-pill buy-btn" onClick={handleBatchRegister.bind(this,props.TestData.id, props.TestData.test_price)} disabled={props.type == "offline"}>Register</button>
                        </div>
                        :   
                        <div className="pt-3 text-center mb-3">
							{
								props.type != "offline" &&  <button className="rounded-pill buy-btn" onClick={redirect.bind(this, props.TestData)} >Take Test</button>
							}
                           
                        </div>
                        }
                        

                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStatesToProps = (state) => {
	return {
		is_auth: state.home.is_auth,
		auth_user: state.home.auth_user
	}
}

const mapActionsToProps = {
	GetTests : GetTests
}

export default connect(mapStatesToProps, mapActionsToProps) (TestDetails);
