import React from 'react';
import './OnlineClasses.css';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import $ from 'jquery';
import Images from '../../assets/images';
import DateFormat from '../../utils/DateFormat';
import TimeFormat from '../../utils/TimeFormat';
import CurrencyFormat from '../../utils/CurrencyFormat';
import { BaseUrl } from '../../utils/ApiService';
import { getOnlineClasses } from '../../actions/Courses';
import moment from 'moment';

class OnlineClasses extends React.Component {

	constructor(props) {
		super(props);
		const { params } = props.match;
		this.state = { activeItem: {}, activeIndex: 0, batchName: params.batchName, batchID: params.batchID, courseName: params.courseName, courseID: params.courseID, typeName: params.typeName, courseType: params.type };
		this.handleLoginClick = this.handleLoginClick.bind(this);
	}

	componentWillMount() {
		if (this.props.location.params) {
			const { type, typeName, courseID } = this.props.location.params;
			this.setState({
				typeName: typeName,
				courseType: type,
				courseID: courseID
			})
		}
		this.props.onGetOnlineClasses(this.state.batchID, this.state.classType);
	}

	openTeam = (url) => {
		window.open(url, "_blank");
	}

	handleLoginClick = (e) => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}

	componentDidUpdate() {
		// Call api when params changes
		const { params } = this.props.match;
		if (params.type !== this.state.classType) {
			this.setState({ batchName: params.batchName, batchID: params.batchID, activeItem: {}, activeIndex: 0 }, () => {
				this.props.onGetOnlineClasses(this.state.batchID, params.type);
			})
		}
	}

	handleJoinOnlineClass = (classUrl) => {
		window.location.href = classUrl;
	}

	render() {

		const { sub_loader, classes } = this.props;
		var { activeItem, activeIndex, batchName, courseType, typeName, courseID } = this.state;
		var currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
		var currentDate = moment().format("YYYY-MM-DD");
		if (activeIndex == 0 && classes.length > 0) {
			activeItem = classes[0];
		}

		classes.map(item => {
			const start_date = item.start_date;
			const startTime = item.start_time;
			const endTime = item.end_time;
			// const start_date = item.start_date;

			const start = moment(currentDate + " " + startTime);
			const end = moment(currentDate + " " + endTime);


			const duration = moment.duration(end.diff(start))
			const classDuration = duration.asMinutes()

			const start1 = moment(currentDateTime);
			const end1 = moment(currentDate + " " + endTime);
			const duration1 = moment.duration(end1.diff(start1))
			const classStartDuration = duration1.asMinutes()


			const start2 = moment(currentDateTime);
			const end2 = moment(currentDate + " " + endTime);


			const duration2 = moment.duration(end2.diff(start2))
			const classEndDuration = duration2.asMinutes()


			var disabled = true;
			var buttonColor = "grey";
			if ((classStartDuration < 5) && (classEndDuration > 0)) {

				disabled = false;
				buttonColor = "green";
			}
			item.disabled = disabled;
			item.buttonColor = buttonColor;
			item.duration = classDuration
			return item;
		})
		const classRepeatTypes = ['', 'Daily (All 7 Days)', 'Monday to Saturday', 'Monday to Friday', 'Weekly Once', 'Monthly Once'];

		return (

			<div className="container-fluid data_container mt-2 mb-4">

				{/* Breadcrumbs and results counter container */}
				<div className="row sort_container">
					<div className=" col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item"><Link to={"/courses/" + courseType + "/" + typeName}>{(typeName && typeName !== '') && typeName} Courses</Link> </li>
								<li className="breadcrumb-item"><Link to={"/courses/" + courseType + "/batches/" + courseID + "/" + typeName}>Batches</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Online Classes</li>
							</ol>
						</nav>
					</div>
				</div>

				<div className="row">
					<div className="col text-center">
						<h4 className="header_title"> {(batchName && batchName !== '') && batchName} online classes </h4>
					</div>
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<span className="small_text text-blue"> Showing {classes.length} classes.</span>
					</div>
				</div>

				{classes && classes.length > 0 ?
					<div className="row">
						<div className="col-12">
							{/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"> */}
							{
								sub_loader ? <div className="text-center"><img src={Images.subLoader} className="filter_loader" alt="Loader" /></div> :

									<div className=" p-2">
										{classes.map((classe, index) => (
											// <div key={classe.id} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-4 pb-0 d-flex" onClick={() => this.setState({activeItem: classe, activeIndex: index})}>
											<div key={classe.id} className="row" onClick={() => this.setState({ activeItem: classe, activeIndex: index })}>
												<div className="col">
													{/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"> */}
													<div className="row p-2">
														<div key={1} className="col-xl-4 col-md-6 col-12 mt-2 mb-4 pb-0 d-flex" >
															<div className="card w-100 p-2 d-inline">
																<div className=" p-2">
																	<h4 className="text-info text-uppercase"><b>{classe.class_title}</b></h4>

																	<div className="p-2">
																		<div className="row my-1">
																			<div className="col-6 text-blue">Course Name :</div>
																			<div className="col-6 text-blue text-capitalize">{classe.course_name}</div>
																		</div>
																		<div className="row my-1">
																			<div className="col-6 text-blue">Batch Name :</div>
																			<div className="col-6 text-blue text-capitalize">{classe.batch_name}</div>
																		</div>
																		<div className="row my-1">
																			<div className="col-6 text-blue">Presenter Name :</div>
																			<div className="col-6 text-blue text-capitalize">{classe.faculty_name}</div>
																		</div>

																	</div>
																	<div className="date d-flex justify-content-between">
																		<div className="text-orange d-flex justify-content">
																			<div className="dateIcon"><i className="fa fa-calendar"></i></div>
																			<div>
																				<div className="dlabel">{(classe.start_date && DateFormat(classe.start_date, 2))}</div>
																				<div className="dlabel">{(classe.end_date && DateFormat(classe.end_date, 2))}</div>
																			</div>
																		</div>
																		<div className="text-orange d-flex justify-content">
																			<div className="dateIcon"><i className="fa fa-clock-o"></i></div>
																			<div>
																				<div className="dlabel">{(classe.start_time && TimeFormat(classe.start_time.slice(0, 6)))}</div>
																				<div className="dlabel">{(classe.end_time && TimeFormat(classe.end_time.slice(0, 6)))}</div>
																			</div>
																		</div>
																		<div className="text-orange d-flex justify-content">
																			<div className="dateIcon"><i className="fa fa-hourglass-half"></i></div>
																			<div>
																				<div className="dlabel">Duration</div>
																				<div className="dlabel">{parseInt(classe.duration) + ' minutes'}</div>
																			</div>
																		</div>
																	</div>
																	<div><button type="button" className={`abroad_btn mt-3 mr-2 w-100 p-2 `} style={{ backgroundColor: classe.buttonColor }} disabled={classe.disabled} onClick={() => this.openTeam(classe.attendee_url)}>{'JOIN CLASS'}</button></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										))
										}
									</div>
							}
						</div>
						{/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-2">
							<div className="item_details_container card w-100 p-3">
								<h4 className="text-orange text-uppercase">{activeItem.class_title} </h4>
								<h6 className="text-blue">{activeItem.duration + ' minutes'} </h6>
								<h6 className="text-blue">{'Starts from: ' + (activeItem.class_start_date && DateFormat(activeItem.class_start_date, 2))}</h6>
								<h6 className="text-blue">{'Time: ' + (activeItem.class_start_time && TimeFormat(activeItem.class_start_time))}</h6> */}


						{/* {activeItem.class_count > 0 ? <h6 className="text-blue mb-0">{activeItem.class_count + (activeItem.class_count === 1 ? ' class' : 'classes') + ' available'} </h6> : <span className="small_text text-blue">{'No classes available'}</span> } */}
						{/* <hr className="my-2" />
								
								<h5 className="text-orange mt-2 mb-1">{'FACULTY'} </h5>
								<hr className="mt-1 mb-2" />

								{activeItem.total_fees > 0 ?
									this.renderFees('Total', activeItem.total_fees)
								:   <span className="small_text text-blue my-2">{'No fees available, We will update it soon.'}</span>
								}

								<span className="small_text text-blue ml-3"> {'* Fees are subject to terms and conditions'}</span>
								
								<hr className="mt-2" /> */}
						{/* \
								
								{(classType == 0 && activeItem.isRegistered) && (
									<button type="button" disabled={true} className="abroad_btn w-100 p-2 mt-2">{'ALREADY REGISTERED'}</button>
								)}

				s				{(activeItem.isRegistered === false) && (
									// <Link to="/classes/0/1">
										<button type="button" className="abroad_btn w-100 p-2 mt-2">{'REGISTER'}</button>
									// </Link>
								)} */}
						{/* </div>
						</div> */}
					</div>
					:
					<div className="col text-center no_results_container">
						<img src={Images.noResults} alt="No results" height="100" /><br /><br />
						<h6>No classes available.</h6>
					</div>
				}

			</div>

		);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth: state.home.is_auth,
		sub_loader: state.home.sub_loader,
		classes: state.course.classes,
	}
}

const mapActionsToProps = {
	onGetOnlineClasses: getOnlineClasses
}

export default connect(mapStatesToProps, mapActionsToProps)(OnlineClasses);