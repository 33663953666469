import React from 'react';
import { Link } from 'react-router-dom';
import {BooksUrl} from '../../utils/ApiService';
import Iframe from 'react-iframe'

class PDFReader extends React.Component {

	constructor (props) {
		super(props);
        const {params} = props.match;
		this.state = {filePath: params.filePath, activeIndex: 0};
	}

	render() {
        const {filePath} = this.state;

		return (
			<div className="container-fluid mt-3 mb-5">
				{/* Breadcrumbs and results counter container */}
				<div className="row">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item active" area-current="page">PDF Reader</li>
							</ol>
						</nav>
					</div>
					<div className="col text-center">
						{/* <h4 className="header_title"> YouTube Video </h4> */}
					</div>
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						{/* <span className="small_text text-blue"> Showing {achievers.length} achievers.</span> */}
					</div>
				</div>

                <div className="row">
					<div className="col">
                        {(filePath && filePath !== '') &&
							<Iframe url={BooksUrl + filePath}
								width="100%"
								height="800px"
								id="myId"
								className="myClassname"
								display="initial"
								position="relative"
								backgroundColor="rgb(50, 54, 57)"
							/>
                        }

						<div style={{position: 'absolute', right: 30, top: 5, height:50, width: 200, zIndex: 999, backgroundColor: 'rgb(50, 54, 57)'}} />
                    </div>
                </div>
			</div>

		);
	}
}

export default PDFReader;