import React from 'react';
import './Achievers.css';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import $ from 'jquery';
import Images from '../../assets/images';
import DateFormat from '../../utils/DateFormat';
import {BaseUrl} from '../../utils/ApiService';
import YouTube from 'react-youtube';
import { getAchievers } from '../../actions/Achievers';
import './Achievers.css'

class Achievers extends React.Component {

	constructor (props) {
		super(props);
		this.state = {activeItem: {}, activeIndex: 0};
		this.handleLoginClick = this.handleLoginClick.bind(this);
	}

	componentWillMount () {
		this.props.onGetAchievers();
	}

	handleLoginClick = (e) => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}

	renderEmptyView = () => {
		if (this.props.main_loader) {
			return null;
		} else {
			return (
				<div className="row">
					<div className="col text-center no_results_container">
						<img src={Images.noResults} alt="No results" height="100" /><br/><br/>
						<h6>No achievers data available.</h6>
					</div>
				</div>
			);
		}
	};

	render() {
		const { achievers } = this.props;

		return (

			<div className="container-fluid data_container mt-2 mb-4">
				          
				{/* Breadcrumbs and results counter container */}
				<div className="row sort_container">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						<nav area-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Achievers</li>
							</ol>
						</nav>
					</div>
					<div className="col text-center">
						{/* <h4 className="header_title"> Achievers </h4> */}
					</div>
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
						{/* <span className="small_text text-blue"> Showing {achievers.length} achievers.</span> */}
					</div>
				</div>

				{achievers && achievers.length > 0 ? 
					<div className="row justify-content-start">
						{achievers.map((achiever, index) => (
							<div key={achiever.id} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-4 mt-2 d-flex">
								<div className="card itemCard w-100 p-3">
									<div className="row p-2">
										<div className="col-6 side-imageDiv">
											{achiever.achiever_image_path !== '' &&
												<img src={BaseUrl + achiever.achiever_image_path} className="img-thumbnail achiever_pic" alt="" />
											}
										</div>
										<div className="col-6 pl-3 pt-2">
											<h6 className="itemSubHeader text-orange"><b>{achiever.achiever_name}</b></h6>
											<p className="medium_text text-blue mb-0">{achiever.batch_number} </p>
											<br/>
										</div>
									</div>
									<div className="text-left mt-2">
										<p className="medium_text text-blue">Achiever Message: <br/>{achiever.achiever_message} </p>
									</div>

									<div className="mt-3 text-center">
										{achiever.achiever_video_path !== '' &&
											<Link to={'/video-player/' + achiever.achiever_video_path}>
												<button className="btn btn-primary btn-md">Watch Video</button>
											</Link>
										}
									</div>
								</div>
							</div>))
						}
					</div>
				:
					this.renderEmptyView()
				}
			</div>

		);
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		main_loader : state.home.main_loader,
		achievers : state.achiever.achievers,
	}
}

const mapActionsToProps = {
	onGetAchievers : getAchievers
}

export default connect(mapStatesToProps, mapActionsToProps) (Achievers);